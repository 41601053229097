import React from "react";

import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from "@mui/material";

import { D0205FlowsResponse } from "../../models/talos/d0205-flows";
import { formatJavaStyleDateString } from "../../utilities";

interface IProps {
	results: D0205FlowsResponse[];
}

export const D0205FlowsTable: React.FC<IProps> = (props: IProps) => {
	return (
		<TableContainer>
			<Table size="small" data-cy="d0205-flows-table">
				<TableHead>
					<TableRow sx={{ background: "#efefef" }}>
						<TableCell>ID</TableCell>
						<TableCell>Flow ID</TableCell>
						<TableCell>User ID</TableCell>
						<TableCell>MPAN</TableCell>
						<TableCell>Status</TableCell>
						<TableCell>Flow Type</TableCell>
						<TableCell>Created At</TableCell>
						<TableCell>Updated At</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{props.results.map((result) => (
						<TableRow key={result.id}>
							<TableCell>{result.id}</TableCell>
							<TableCell>{result.flow_id}</TableCell>
							<TableCell>{result.user_id}</TableCell>
							<TableCell>{result.mpan}</TableCell>
							<TableCell>{result.status}</TableCell>
							<TableCell>{result.flow_type}</TableCell>
							<TableCell>
								{formatJavaStyleDateString(result.created_at)}
							</TableCell>
							<TableCell>
								{formatJavaStyleDateString(result.updated_at)}
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
};
