import React from "react";

import { Box, Tab, Tabs, Typography, useTheme } from "@mui/material";

import {
	DaysBetweenDates,
	FinalReconciliationCalculator,
	ReadsCalculator,
	ReadsCalculatorBasedOnEACorAA,
	ReadSplitter,
} from "../../components";

interface TabPanelProps {
	children?: React.ReactNode;
	index: number;
	value: number;
}

function TabPanel(props: TabPanelProps) {
	const { children, value, index, ...other } = props;

	return (
		<Box
			role="tabpanel"
			hidden={value !== index}
			id={`vertical-tabpanel-${index}`}
			aria-labelledby={`vertical-tab-${index}`}
			{...other}
			sx={{ border: "1px solid #4c4c4c" }}
		>
			{value === index && <Box sx={{ p: 3 }}>{children}</Box>}
		</Box>
	);
}

function a11yProps(index: number) {
	return {
		id: `vertical-tab-${index}`,
		"aria-controls": `vertical-tabpanel-${index}`,
	};
}

export const ReadsCalculations: React.FC = () => {
	const [value, setValue] = React.useState(0);

	const theme = useTheme();

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setValue(newValue);
	};

	return (
		<>
			<Box>
				<Typography variant="h1">Utilities Proof of Concept</Typography>
				<Typography variant="body1" sx={{ padding: theme.spacing(3, 0) }}>
					This page contains some useful tools for calculating estimates. Please
					note that this is a Proof of Concept and may require a bit more
					testing before using in production.
				</Typography>
			</Box>
			<Box>
				<Tabs
					value={value}
					onChange={handleChange}
					variant="scrollable"
					scrollButtons="auto"
					sx={{ borderRight: 1, borderColor: "divider" }}
				>
					<Tab label="Reads Calculator" {...a11yProps(0)} />
					<Tab label="Reads Calculator Based on EAC or AA" {...a11yProps(1)} />
					<Tab label="Days Between Dates" {...a11yProps(2)} />
					<Tab label="Reads Splitter" {...a11yProps(3)} />
					<Tab label="RF Calculator" {...a11yProps(4)} />
				</Tabs>
				<TabPanel value={value} index={0}>
					<ReadsCalculator />
				</TabPanel>
				<TabPanel value={value} index={1}>
					<ReadsCalculatorBasedOnEACorAA />
				</TabPanel>
				<TabPanel value={value} index={2}>
					<DaysBetweenDates />
				</TabPanel>
				<TabPanel value={value} index={3}>
					<ReadSplitter />
				</TabPanel>
				<TabPanel value={value} index={4}>
					<FinalReconciliationCalculator />
				</TabPanel>
			</Box>
		</>
	);
};
