import { Typography, List, ListItem, ListItemText } from "@mui/material";

export const BookingRequestsInstructions: React.FC = () => {
	return (
		<>
			<Typography variant="h2">Booking Requests</Typography>
			<br />
			<Typography variant="body1">
				This page gives you a list of booking requests that have been sent to
				MDS.
			</Typography>
			<Typography variant="body1">
				<strong>
					Please note that we only know that these have been sent to MDS we
					don&apos;t, right now, know if MDS has accepted them.
				</strong>
			</Typography>
			<Typography variant="body1">
				Agents are advised to email MDS as usual.
			</Typography>
			<Typography variant="body1">
				<strong>
					The files generated from this process will be generate overnight and
					sent to MDS the next day.
				</strong>
			</Typography>
			<Typography variant="body1">
				<em>Note:</em> new requests can take a few minutes to appear. If not
				immediately visible try refreshing the page after a few minutes.
			</Typography>
			<List>
				<ListItem sx={{ padding: 0, margin: 0 }}>
					<ListItemText>
						<strong>SENT</strong> - The appointment has been sent to MDS.
					</ListItemText>
				</ListItem>
				<ListItem sx={{ padding: 0, margin: 0 }}>
					<ListItemText>
						<strong>PENDING</strong> - The request is in the queue and will be
						sent to MDS in the next batch.
					</ListItemText>
				</ListItem>
				<ListItem sx={{ padding: 0, margin: 0 }}>
					<ListItemText>
						<strong>FAILED</strong> - Something went wrong please see the
						associated error message and re-submit.
					</ListItemText>
				</ListItem>
			</List>
		</>
	);
};
