import { FC } from "react";

import { Typography, useTheme } from "@mui/material";

interface IProps {
	field: string | null;
	message: string;
	type: WarningType;
	rangeMin?: number;
	rangeMax?: number;
}

export enum WarningType {
	LESS,
	BETWEEN,
	MORE,
}

export const TalosValidationWarning: FC<IProps> = (props) => {
	const theme = useTheme();

	return (
		<>
			{isFieldWarningMessageVisible(props) ? (
				<Typography
					variant="caption"
					sx={{
						color: theme.palette.warning.dark,
					}}
				>
					{props.message}
				</Typography>
			) : null}
		</>
	);
};

export function isFieldWarningMessageVisible(props: IProps): boolean {
	if (props.field == null) return false;

	switch (props.type) {
		case WarningType.BETWEEN:
			if (props.rangeMin && props.rangeMax)
				return (
					props.field.length >= props.rangeMin &&
					props.field.length < props.rangeMax
				);
			else return false;

		case WarningType.LESS:
			return props.rangeMax != undefined && props.field.length < props.rangeMax;

		case WarningType.MORE:
			return props.rangeMin != undefined && props.field.length > props.rangeMin;
	}
	return false;
}
