import React, { ChangeEvent, useContext, useState } from "react";

import { useMutation } from "react-query";
import { Box, Typography, Button } from "@mui/material";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import SaveIcon from "@mui/icons-material/Save";
import { AxiosError } from "axios";
import { useNavigate } from "react-router-dom";
import { GridRowSelectionModel } from "@mui/x-data-grid";

import { AlertContext, handleError } from "../../utilities";
import { AuthContext } from "../../auth";
import {
	addRegistrationsToList,
	generateEcoesPlan,
} from "../../api/themis/themis-api";
import { EcoesPlanTable } from "../../components/registrations/ecoes-plan-table";
import {
	EcoesRegistration,
	EcoesRegistrationImportPlan,
} from "../../models/themis";

export const EcoesPlan: React.FC = () => {
	const initialEcoesPlan = {
		registrationsAbleToStart: [],
		registrationsNotAbleToStart: [],
	};

	const [ecoesPlan, setEcoesPlan] =
		useState<EcoesRegistrationImportPlan>(initialEcoesPlan);

	const [rowSelectionModel, setRowSelectionModel] =
		useState<GridRowSelectionModel>([]);

	const { setTalosAlert } = useContext(AlertContext);
	const authContext = useContext(AuthContext);
	const navigate = useNavigate();

	const selectedRows = ecoesPlan.registrationsAbleToStart.filter((row) =>
		rowSelectionModel.includes(row.referenceNumber)
	);

	const generateEcoesPlanMutation = useMutation(
		(csvFile: File) => {
			return generateEcoesPlan(authContext, csvFile);
		},
		{
			onSuccess: (data: EcoesRegistrationImportPlan) => {
				setTalosAlert({
					message: `Successfully created plan. ${
						data.registrationsNotAbleToStart.length === 0
							? ""
							: `${data.registrationsNotAbleToStart.length} not imported.`
					}`,
					severity: "success",
				});
				setEcoesPlan(data);
			},
			onError: (e: AxiosError) => {
				const errorId = handleError(e);

				let message = "";
				if (e.response?.status === 400)
					message = `Failed to read file: ${e.response.data}. Please check that sheet contains all columns with appropriate values.`;
				else
					message = `Something went wrong uploading file. Please contact IOPS Support, Ticket ID: ${errorId}`;

				setTalosAlert({
					message,
					severity: "error",
				});
				// Scroll to the top of the page, so the user can see error message
				scrollTo(0, 0);
			},
		}
	);

	const addRegistrationsMutation = useMutation(
		(ecoesRegistrations: EcoesRegistration[]) => {
			return addRegistrationsToList(authContext, ecoesRegistrations);
		},
		{
			onSuccess: (data: number) => {
				setTalosAlert({
					message: `Successfully added ${data} registrations to list`,
					severity: "success",
				});

				navigate("/export/registrations");
			},
			onError: (e: AxiosError) => {
				const errorId = handleError(e);

				setTalosAlert({
					message: `Failed to add registrations to list. Please try again. If problem persists, please contact IOPs support with ticket ID ${errorId}`,
					severity: "error",
				});
				// Scroll to the top of the page, so the user can see error message
				scrollTo(0, 0);
			},
		}
	);

	const handleFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
		if (!e.target.files || e.target.files.length === 0) return;
		setEcoesPlan(initialEcoesPlan);

		const file = e.target.files[0];
		generateEcoesPlanMutation.mutate(file);

		e.target.value = "";
	};

	return (
		<>
			<Box maxWidth={1430}>
				<Typography variant="h1">Upload from ECOES</Typography>
				<Box display={"flex"} gap={2} margin={"1rem 0"}>
					<Button
						component="label"
						variant="outlined"
						startIcon={<UploadFileIcon />}
						disabled={
							generateEcoesPlanMutation.isLoading ||
							addRegistrationsMutation.isLoading
						}
					>
						Upload CSV and Create Plan
						<input
							type="file"
							accept=".csv"
							hidden
							onChange={handleFileUpload}
						/>
					</Button>
					<Button
						component="label"
						variant="outlined"
						startIcon={<SaveIcon />}
						sx={{ marginRight: "1rem" }}
						disabled={
							selectedRows.length === 0 ||
							generateEcoesPlanMutation.isLoading ||
							addRegistrationsMutation.isLoading
						}
						onClick={() => addRegistrationsMutation.mutate(selectedRows)}
					>
						Add selected rows to registrations list
					</Button>
				</Box>
				<Box marginBottom={"1rem"}>
					<Typography variant="body1">
						Note:{" "}
						<span style={{ fontWeight: "bold" }}>
							Registrations which are not able to be started are not displayed
							in the table.
						</span>
					</Typography>
				</Box>
				<EcoesPlanTable
					results={ecoesPlan.registrationsAbleToStart}
					isLoading={generateEcoesPlanMutation.isLoading}
					rowSelectionModel={rowSelectionModel}
					setRowSelectionModel={setRowSelectionModel}
				/>
			</Box>
		</>
	);
};
