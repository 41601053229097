import { EnergisationStatus } from "./common";

export interface ISupplyDisconnection {
	mpan: number | "";
	disconnectionType: string;
	additionalInformation: string;
	appointmentDate: string;
	energisationStatus: string;
	contactName: string;
	customerPreferredContactMethod: string;
	customerPreferredContactDetails: string;
}

export enum DisconnectionType {
	Logical = "A",
	Physical = "P",
}

export enum CustomerPreferredContactMethod {
	Telephone = "H",
	Letter = "L",
	Email = "E",
}

export const initialSupplyDisconnectionFormValues: ISupplyDisconnection = {
	mpan: "",
	disconnectionType: "",
	additionalInformation: "",
	appointmentDate: "",
	energisationStatus: EnergisationStatus["De-Energised"],
	contactName: "",
	customerPreferredContactMethod: "",
	customerPreferredContactDetails: "",
};
