import {
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from "@mui/material";

import { theme } from "../../styles/theme";

interface ColumnModel {
	label: string;
	key: string;
	formatter?: (columnContent: any) => string;
}

interface TableModel<TResponse> {
	dataKey: string;
	columns: ColumnModel[];
	getColumnContent: (
		columnKey: string,
		response: TResponse,
		columnModel: ColumnModel
	) => unknown;
	getId: (response: TResponse) => string | number;
}

interface Props<TResponse> {
	rowData: TResponse[];
}

const defaultFormatter = (input: any) => (input ? input.toString() : null);

export const flowsTable = <TResponse,>({
	columns,
	dataKey,
	getColumnContent,
	getId,
}: TableModel<TResponse>) => {
	const renderRowFn = (rowData: TResponse) => {
		const tableRowFns = columns.map((column) => {
			const tableColumnKey = `${dataKey}-cell-${column.key}`;
			// If a custom column formatter is specified then use that, otherwise use
			// the default which applies toString to any value.
			const formatter = column.formatter ?? defaultFormatter;

			return function renderCell(rowData: TResponse) {
				const cellKey = `${tableColumnKey}-${getId(rowData)}`;
				return (
					<TableCell data-cy={cellKey} key={cellKey}>
						{formatter(getColumnContent(column.key, rowData, column))}
					</TableCell>
				);
			};
		});
		const rowId = getId(rowData);
		return (
			<TableRow key={rowId} data-cy={`flow-row-${rowId}`}>
				{tableRowFns.map((fn) => fn(rowData))}
			</TableRow>
		);
	};

	return function FlowsTable({ rowData }: Props<TResponse>) {
		return (
			<TableContainer
				component={Paper}
				sx={{ marginTop: theme.spacing(2), marginBottom: theme.spacing(3) }}
			>
				<Table size="small" data-cy={`${dataKey}-flows-table`}>
					<TableHead>
						<TableRow sx={{ background: "#efefef" }}>
							{columns.map(({ label, key }) => (
								<TableCell key={key} data-cy={`${dataKey}-cell-${key}`}>
									{label}
								</TableCell>
							))}
						</TableRow>
					</TableHead>
					<TableBody>{rowData.map(renderRowFn)}</TableBody>
				</Table>
			</TableContainer>
		);
	};
};
