import { Box, MenuItem } from "@mui/material";
import { Form, Formik } from "formik";
import { UseMutationResult } from "react-query";

import {
	TalosButton,
	TalosDateTimeField,
	TalosDropDown,
	TalosRadioGroup,
	TalosTextBox,
	TalosValidationWarning,
} from "../forms";
import { formValidationSchema } from "./add-s33-flow-form.validation-schema";
import {
	D0010_TYPES,
	IFlowFormValues,
	READING_METHOD_TYPES,
} from "../../models/talos/s33-flows";
import {
	longMSNValidationMessage,
	shortMSNValidationMessage,
} from "../../utilities/mhhs-validation-messages";
import { WarningType } from "../forms/talos-validation-warning";

interface IProps {
	formSubmitMutation: UseMutationResult<Boolean, Error, IFlowFormValues>;
	formData: IFlowFormValues;
}

export const AddS33FlowForm: React.FC<IProps> = ({
	formSubmitMutation,
	formData,
}) => {
	const handleOnSubmit = (values: IFlowFormValues) => {
		formSubmitMutation.mutate(values);
	};

	return (
		<Formik
			enableReinitialize
			onSubmit={handleOnSubmit}
			initialValues={formData}
			validationSchema={formValidationSchema}
			validateOnChange={true}
		>
			{(form) => (
				<Form>
					<Box className="form-wrapper">
						<Box className="form-column">
							<TalosTextBox fieldName="mpan" label="Mpan*" form={form} />
							<TalosDropDown
								fieldName="flowAndDestinationTypes"
								label="Flow and Destination Types*"
								form={form}
								menuItems={D0010_TYPES.map((wl, idx) => (
									<MenuItem
										key={`flow_and_destination_types_${idx}`}
										value={wl.value}
									>
										{wl.display}
									</MenuItem>
								))}
							/>
							<TalosTextBox
								fieldName="meterIdSerialNumber"
								label="Meter Id Serial Number*"
								form={form}
							/>
							<TalosValidationWarning
								field={form.values.meterIdSerialNumber}
								message={shortMSNValidationMessage}
								type={WarningType.BETWEEN}
								rangeMin={3}
								rangeMax={9}
							/>
							<TalosValidationWarning
								field={form.values.meterIdSerialNumber}
								message={longMSNValidationMessage}
								type={WarningType.BETWEEN}
								rangeMin={15}
								rangeMax={18}
							/>
							<TalosTextBox
								fieldName="readingType"
								label="Reading Type*"
								form={form}
							/>
							<Box className="form-column">
								<h3>Site Visit Check Code</h3>
								<TalosTextBox
									fieldName="siteVisitCheckCode"
									label="Site Visit Check Code*"
									form={form}
								/>
								<TalosTextBox
									fieldName="additionalInformation"
									label="Additional Information"
									form={form}
								/>
							</Box>
							<Box className="form-column">
								<h3>Meter Register Details</h3>
								<TalosTextBox
									fieldName="meterRegisterIdOne"
									label="Register 1 ID*"
									form={form}
								/>
								<TalosTextBox
									fieldName="timePatternRegimeOne"
									label="Time Pattern Regime"
									form={form}
								/>
								<TalosDateTimeField
									fieldName="readingDateTimeOne"
									label="Reading Date Time*"
									form={form}
								/>
								<TalosTextBox
									fieldName="registerReadingOne"
									label="Register Reading*"
									form={form}
								/>
								<TalosDropDown
									fieldName="readingMethodOne"
									label="Reading Method"
									form={form}
									menuItems={READING_METHOD_TYPES.map((wl, idx) => (
										<MenuItem
											key={`reading_method_one_${idx}`}
											value={wl.value}
										>
											{wl.display}
										</MenuItem>
									))}
								/>
								<h4>Does the meter have a second register?</h4>
								<TalosRadioGroup
									form={form}
									fieldName="doesHaveASecondRegister"
									options={[
										{
											value: "Y",
											label: "Yes",
										},
										{
											value: "N",
											label: "No",
										},
									]}
									postOnChange={(evt, value) => {
										if (value === "N") {
											form.setFieldValue("meterRegisterIdTwo", "");
											form.setFieldValue("timePatternRegimeTwo", "");
											form.setFieldValue("readingDateTimeTwo", "");
											form.setFieldValue("registerReadingTwo", "");
											form.setFieldValue("readingMethodTwo", "");
											form.setFieldValue("doesHaveASecondRegister", "N");
										}
									}}
								/>
								{form.values.doesHaveASecondRegister === "Y" && (
									<>
										<TalosTextBox
											fieldName="meterRegisterIdTwo"
											label="Register 2 ID*"
											form={form}
										/>
										<TalosTextBox
											fieldName="timePatternRegimeTwo"
											label="Time Pattern Regime"
											form={form}
										/>
										<TalosDateTimeField
											fieldName="readingDateTimeTwo"
											label="Reading Date Time*"
											form={form}
										/>
										<TalosTextBox
											fieldName="registerReadingTwo"
											label="Register Reading*"
											form={form}
										/>
										<TalosDropDown
											fieldName="readingMethodTwo"
											label="Reading Method"
											form={form}
											menuItems={READING_METHOD_TYPES.map((wl, idx) => (
												<MenuItem
													key={`reading_method_two_${idx}`}
													value={wl.value}
												>
													{wl.display}
												</MenuItem>
											))}
										/>
									</>
								)}
							</Box>
							<TalosButton
								fieldName="form_submit"
								form={form}
								buttonText="Submit"
								loading={formSubmitMutation.isLoading}
							/>
						</Box>
					</Box>
				</Form>
			)}
		</Formik>
	);
};
