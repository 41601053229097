import Keycloak from "keycloak-js";

import { ROLES, talosConfig } from "../utilities";
import { IAuthContext } from "./context";

export function hasTalosPermissions(authContext: IAuthContext): boolean {
	if (authContext.hasAnyRole()) {
		const userRoles = authContext.provider?.realmAccess?.roles ?? [];
		return Object.values(ROLES).some((role) => userRoles.includes(role));
	} else {
		return false;
	}
}

export const initializeAuthContext = async (): Promise<IAuthContext> => {
	const talosKeycloak = new Keycloak(talosConfig.keycloak.config);

	const authenticated = await talosKeycloak.init(
		talosConfig.keycloak.initOptions
	);

	if (authenticated) {
		const profile = await talosKeycloak.loadUserProfile();

		const authContext = {
			userName: `${profile.firstName} ${profile.lastName}`,
			email: `${profile.email}`,
			provider: talosKeycloak,
			hasRole: (roleName: string) => talosKeycloak.hasRealmRole(roleName),
			hasAnyRole: () => talosKeycloak.realmAccess != undefined,
			hasTalosPermissions: () => hasTalosPermissions(authContext),
		};
		return authContext;
	}

	throw new Error("Keycloak failed to authenticate");
};
