import { useTheme } from "@mui/material";
import { DataGrid, GridColDef, GridRow, GridRowProps } from "@mui/x-data-grid";
import { Link } from "react-router-dom";

import { RegistrationSimple } from "../../models/themis";

const COLUMN_MIN_WIDTH = 130;
const COLUMN_MAX_WIDTH = 180;

const columns: GridColDef[] = [
	{
		field: "customerName",
		headerName: "Customer Name",
		minWidth: COLUMN_MIN_WIDTH,
		maxWidth: COLUMN_MAX_WIDTH,
		flex: 1,
	},
	{
		field: "meterSerialNumber",
		headerName: "MSN",
		minWidth: COLUMN_MIN_WIDTH,
		maxWidth: COLUMN_MAX_WIDTH,
		flex: 1,
	},
	{
		field: "type",
		headerName: "Type",
		minWidth: COLUMN_MIN_WIDTH,
		maxWidth: COLUMN_MAX_WIDTH,
	},
	{
		field: "readingType",
		headerName: "Reading Type",
		minWidth: COLUMN_MIN_WIDTH,
		maxWidth: COLUMN_MAX_WIDTH,
	},
	{
		field: "importMpan",
		headerName: "Import MPAN",
		minWidth: COLUMN_MIN_WIDTH,
		maxWidth: COLUMN_MAX_WIDTH,
		flex: 1,
	},
	{
		field: "exportMpan",
		headerName: "Export MPAN",
		minWidth: COLUMN_MIN_WIDTH,
		maxWidth: COLUMN_MAX_WIDTH,
		flex: 1,
	},
	{
		field: "aregiSubmitted",
		headerName: "AREGI Submitted",
		minWidth: COLUMN_MIN_WIDTH,
		maxWidth: COLUMN_MAX_WIDTH,
		valueGetter: ({ value }: { value: boolean }) => (value ? "YES" : "NO"),
	},
	{
		field: "registrationPhase",
		headerName: "Reg. Phase",
		minWidth: COLUMN_MIN_WIDTH,
		maxWidth: COLUMN_MAX_WIDTH,
	},
	{
		field: "registrationState",
		headerName: "Reg. State",
		minWidth: COLUMN_MIN_WIDTH,
		maxWidth: COLUMN_MAX_WIDTH,
	},
	{
		field: "additionalNotes",
		headerName: "Additional Notes",
		minWidth: COLUMN_MIN_WIDTH,
		maxWidth: COLUMN_MAX_WIDTH,
	},
	{
		field: "createdDate",
		headerName: "Created Date",
		minWidth: COLUMN_MIN_WIDTH,
		maxWidth: COLUMN_MAX_WIDTH,
	},
];

interface IProps {
	results: RegistrationSimple[];
	isLoading: boolean;
}

export const RegistrationsTable: React.FC<IProps> = (props: IProps) => {
	const theme = useTheme();

	return (
		<DataGrid
			sx={{
				fontSize: "body1",
				"& .MuiDataGrid-columnHeaderTitle": {
					whiteSpace: "break-spaces",
					lineHeight: "normal",
				},
				"& .MuiSvgIcon-fontSizeSmall": {
					fontSize: "1rem",
				},
			}}
			autoHeight
			rows={props.results}
			loading={props.isLoading}
			columns={columns}
			slots={{
				row: (props: GridRowProps) => (
					<Link
						to={props.row?.id}
						style={{
							textDecoration: "none",
							color: theme.palette.common.black,
						}}
					>
						<GridRow {...props} />
					</Link>
				),
			}}
		/>
	);
};
