import { DropDownItem } from "./change-read";

// TODO: Uncomment when the endpoint handles filters
// export interface IUT003FlowsFilter {
// 	mpan: string | undefined;
// 	start: Date;
// 	end: Date;
// }

export interface UT003FlowsResponse {
	id: number;
	flow_id: number;
	user_id: string;
	mpan: number;
	status: string;
	flow_type: string;
	created_at: string;
	updated_at: string;
}

export interface UT003FlowFormValues {
	read: number;
	read_date: string;
	read_source: string;
	read_reason: string;
	msn: string;
	rtc: string;
	y_flag: null | string;
}
export interface UT003Request {
	read: number;
	read_date: string;
	read_source: string;
	read_reason: string;
	msn: string;
	rtc: number;
	y_flag: boolean;
}

export const READ_SOURCE: DropDownItem[] = [
	{ display: "A", value: "A" },
	{ display: "E", value: "E" },
];

export const READ_REASON: DropDownItem[] = [
	{ display: "R", value: "R" },
	{ display: "N", value: "N" },
];

export const RTC: DropDownItem[] = [
	{ display: "ZERO", value: "0" },
	{ display: "ONE", value: "1" },
];

export const YFlag: DropDownItem[] = [{ display: "Y", value: "Y" }];
