import React from "react";

import {
	Box,
	Card,
	CardActionArea,
	CardContent,
	Typography,
} from "@mui/material";
import { Link } from "react-router-dom";

import { theme } from "../../styles/theme";

interface ActionCardProps {
	path: string;
	icon: JSX.Element;
	title: string;
	text: string;
	backgroundColor: string;
}

export const ActionCard: React.FC<ActionCardProps> = (
	props: ActionCardProps
) => {
	return (
		<Card
			className="action-card"
			variant="outlined"
			sx={{ width: "300px", sm: { width: "100%" } }}
			data-cy="action-card"
		>
			<CardActionArea component={Link} to={props.path}>
				<CardContent>
					<Box
						sx={{
							backgroundColor: props.backgroundColor,
							color: "#fff",
							justifyContent: "center",
							alignItems: "center",
							flexDirection: "column",
							display: "flex",
							padding: theme.spacing(2),
							marginBottom: theme.spacing(1),
						}}
					>
						<Box>{props.icon}</Box>
						<Typography variant="h2">{props.title}</Typography>
					</Box>

					<Typography variant="body2" color="text.primary">
						{props.text}
					</Typography>
				</CardContent>
			</CardActionArea>
		</Card>
	);
};
