import { FC } from "react";

import { Button, InputLabel, MenuItem, Stack } from "@mui/material";
import { Form, Formik } from "formik";
import { AxiosResponse } from "axios";

import {
	IMOP04v10,
	JobType,
	initialMOP04FormValues,
} from "../../../models/outbound";
import { IHDStatusNullable } from "../../../models/metering/common";
import {
	OutboundTimeField,
	TalosDateField,
	TalosDropDown,
	TalosRadioGroup,
	TalosTextBox,
} from "../..";
import { ExchangeForm, SubmitMOP04formValidationSchema } from "./index";
import { InstallForm, RemoveForm } from "./exchange-form";
import { AdditionFormProps } from "../../../form-generator";
import { TalosMpanField } from "../../forms/talos-mpan-field";

const jobTypeSubForms: Record<
	string,
	typeof ExchangeForm | typeof InstallForm | typeof RemoveForm
> = {
	[JobType.EXCHANGE]: ExchangeForm,
	[JobType.INSTALL]: InstallForm,
	[JobType.REMOVE]: RemoveForm,
};

const yesNoSubForm = [
	{ value: true, label: "Yes" },
	{ value: false, label: "No" },
];

export const AddMop04Form: FC<
	AdditionFormProps<IMOP04v10, AxiosResponse<any>>
> = ({ formSubmitMutation, formData }) => (
	<Formik<IMOP04v10>
		onSubmit={(values, { resetForm }) => {
			formSubmitMutation.mutate(values, {
				onSuccess: () => resetForm(),
			});
		}}
		initialValues={formData}
		validationSchema={SubmitMOP04formValidationSchema}
		isInitialValid={false}
		validateOnChange
		enableReinitialize
	>
		{(form) => (
			<Form>
				<Stack
					gap={4}
					flexDirection={"row"}
					justifyContent={"space-between"}
					alignItems={"stretch"}
				>
					<Stack gap={2} flexDirection={"column"} flex={1}>
						<TalosMpanField
							fieldName="mpanCore"
							label="MPAN Core*"
							form={form}
						/>
						<TalosDateField
							fieldName="dateOfAction"
							label="Date of Action*"
							form={form}
						/>
						<OutboundTimeField
							value={form.values.timeOfAction}
							name="timeOfAction"
							label="Time of Action*"
							helperText={form.errors.timeOfAction}
							error={!!form.errors.timeOfAction}
							form={form}
						/>
						<TalosDropDown
							fieldName="jobType"
							label="Job Type*"
							form={form}
							menuItems={Object.entries(JobType).map((val, index) => {
								return (
									<MenuItem
										key={`job_type_${index}`}
										value={val[0]}
										onChange={() => form.setValues(initialMOP04FormValues)}
									>
										{`${val[1]}`}
									</MenuItem>
								);
							})}
						/>
						<TalosDropDown
							label="Successful Job*"
							fieldName="successfulJob"
							form={form}
							menuItems={yesNoSubForm.map((val, index) => {
								return (
									<MenuItem
										key={`successful_job_${index}`}
										value={String(val.value)}
										onChange={() => form.setValues(initialMOP04FormValues)}
									>
										{`${val.label}`}
									</MenuItem>
								);
							})}
						/>
						<TalosTextBox
							fieldName="supplierId"
							label="Supplier Id*"
							form={form}
						/>
						<TalosDateField
							fieldName="originalIncorrectDateOfAction"
							label="Original Incorrect Date Of Action"
							form={form}
						/>
						<TalosRadioGroup
							label="Correction Flow"
							fieldName="correctionFlow"
							form={form}
							row
							options={[
								{ value: "true", label: "Yes" },
								{ value: "false", label: "No" },
								{ value: "", label: "None" },
							]}
						/>
						<TalosRadioGroup
							label="DCC Connected"
							fieldName="dccConnected"
							form={form}
							row
							options={[
								{ value: "true", label: "Yes" },
								{ value: "false", label: "No" },
								{ value: "", label: "None" },
							]}
						/>
						<TalosTextBox
							fieldName="fieldForceName"
							label="Field Force Name"
							form={form}
						/>
						<TalosTextBox
							fieldName="standardSettlementConfigurationId"
							label="SSC Id"
							form={form}
						/>
						<TalosTextBox
							fieldName="meteringSystemNonSettlementFunctionalityCode"
							label="Metering System Non Settlement Functionality Code"
							form={form}
						/>
						<TalosTextBox
							fieldName="siteVisitCheckCode"
							label="Site Visit Check Code"
							form={form}
						/>
						<TalosRadioGroup
							label="Complex Site Indicator"
							fieldName="complexSiteIndicator"
							form={form}
							row
							options={[
								{ value: "true", label: "Yes" },
								{ value: "false", label: "No" },
								{ value: "", label: "None" },
							]}
						/>
						<TalosTextBox
							fieldName="meterEquipmentServiceLocation"
							label="Meter Equipment Service Location"
							multiline
							minRows={4}
							maxRows={4}
							form={form}
						/>
					</Stack>
					<Stack gap={2} flexDirection={"column"} flex={1}>
						<TalosTextBox
							fieldName="measurementClassId"
							label="Measurement Class Id"
							form={form}
						/>
						<TalosTextBox fieldName="meterCOP" label="Meter COP" form={form} />
						<TalosTextBox
							fieldName="meterCOPIssueNumber"
							label="Meter COP Issue Number"
							form={form}
						/>
						<TalosTextBox
							fieldName="eventIndicator"
							label="Event Indicator"
							form={form}
						/>
						<TalosTextBox
							fieldName="numberOfPhases"
							label="Number of phases"
							form={form}
						/>
						<TalosTextBox
							fieldName="systemVoltage"
							label="System Voltage"
							form={form}
						/>
						<TalosTextBox
							fieldName="additionalInformation"
							label="Additional Information"
							multiline
							minRows={4}
							maxRows={4}
							form={form}
						/>
						<TalosRadioGroup
							label="Remote Disable Enable Capability"
							fieldName="remoteDisableEnableCapability"
							form={form}
							row
							options={[
								{ value: "true", label: "Yes" },
								{ value: "false", label: "No" },
								{ value: "", label: "None" },
							]}
						/>
						<TalosRadioGroup
							label="Measurement Transformer Commissioning Completed"
							fieldName="measurementTransformerCommissioningCompleted"
							form={form}
							row
							options={[
								{ value: "true", label: "Yes" },
								{ value: "false", label: "No" },
								{ value: "", label: "None" },
							]}
						/>
						<TalosRadioGroup
							label="MOA Commissioning Completed"
							fieldName="moaCommissioningCompleted"
							form={form}
							row
							options={[
								{ value: "true", label: "Yes" },
								{ value: "false", label: "No" },
								{ value: "", label: "None" },
							]}
						/>
						<TalosRadioGroup
							fieldName="overallAccuracyOfMeteringSystem"
							label="Overall Accuracy Of Metering System"
							form={form}
							row
							options={[
								{ value: "true", label: "Yes" },
								{ value: "false", label: "No" },
								{ value: "", label: "None" },
							]}
						/>
						<TalosDateField
							fieldName="dateOfInstallationRemovalOfTimingDevice"
							label="Date Of Installation Removal Of Timing Device"
							form={form}
						/>
						<TalosTextBox
							fieldName="natureOfMaintenance"
							label="Nature Of Maintenance"
							multiline
							minRows={4}
							maxRows={4}
							form={form}
						/>
						<InputLabel
							id={"ihd_install_status_label"}
							error={!!form.errors.ihdInstallStatus}
						>
							{"IHD Install Status"}
						</InputLabel>
						<TalosDropDown
							fieldName="ihdInstallStatus"
							label="IHD Install Status"
							form={form}
							menuItems={Object.entries(IHDStatusNullable).map((val, index) => {
								return (
									<MenuItem
										key={`ihd_install_status_label_${index}`}
										value={val[1].value}
									>
										{val[1].view}
									</MenuItem>
								);
							})}
						/>
					</Stack>
				</Stack>
				{form.values.jobType in jobTypeSubForms && (
					<Stack gap={2} mt={2}>
						{jobTypeSubForms[form.values.jobType]({ form })}
					</Stack>
				)}

				<Stack direction={"row"} gap={2} marginTop={2}>
					<Button
						data-cy="form_submit"
						variant="contained"
						type="submit"
						disabled={!form.isValid || formSubmitMutation.isLoading}
					>
						Submit
					</Button>
					<Button
						color="error"
						variant="contained"
						onClick={() => form.setValues(initialMOP04FormValues)}
					>
						Reset
					</Button>
				</Stack>
			</Form>
		)}
	</Formik>
);
