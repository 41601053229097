import { FC, Fragment } from "react";

import { Field, FieldArray, FormikProps, getIn } from "formik";
import {
	Button,
	Container,
	FormControl,
	FormControlLabel,
	FormHelperText,
	FormLabel,
	Radio,
	RadioGroup,
	Stack,
	TextField,
	Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";

import {
	IMOP04v10,
	initialInstalledMeterRegistersM26FormValues,
} from "../../../models/outbound";
import { InstalledMeterRegistersForm } from "./installed-meter-registers-form";
import { talosColours } from "../../../styles/talos-colors";

interface IInstalledCorrectingInstalledMetersFormProps {
	form: FormikProps<IMOP04v10>;
	m24Index: number;
	removeM24Group: () => void;
}

export const InstalledCorrectingInstalledMetersForm: FC<
	IInstalledCorrectingInstalledMetersFormProps
> = ({ form, m24Index, removeM24Group }) => {
	return (
		<>
			<Stack flexDirection={"row"} gap={2}>
				<Stack flexDirection="column" gap={2} flex={1}>
					<FormControl>
						<Field
							name={`installedCorrectingInstalledMeters[${m24Index}].meterConfigCode`}
							label={`Meter Config Code ${m24Index + 1}*`}
							as={TextField}
							multiline
							minRows={4}
							error={
								!!getIn(
									form.errors.installedCorrectingInstalledMeters?.[m24Index],
									"meterConfigCode"
								)
							}
							form={form}
						/>
						<FormHelperText
							error={
								!!getIn(
									form.errors.installedCorrectingInstalledMeters?.[m24Index],
									"meterConfigCode"
								)
							}
						>
							{getIn(
								form.errors.installedCorrectingInstalledMeters?.[m24Index],
								"meterConfigCode"
							)}
						</FormHelperText>
					</FormControl>
					<FormControl>
						<Field
							name={`installedCorrectingInstalledMeters[${m24Index}].installedMeterId`}
							label={`Installed Meter Id ${m24Index + 1}*`}
							as={TextField}
							form={form}
							error={
								!!getIn(
									form.errors.installedCorrectingInstalledMeters?.[m24Index],
									"installedMeterId"
								)
							}
						/>
						<FormHelperText
							error={
								!!getIn(
									form.errors.installedCorrectingInstalledMeters?.[m24Index],
									"installedMeterId"
								)
							}
						>
							{getIn(
								form.errors.installedCorrectingInstalledMeters?.[m24Index],
								"installedMeterId"
							)}
						</FormHelperText>
					</FormControl>
					<FormControl>
						<Field
							name={`installedCorrectingInstalledMeters[${m24Index}].originalIncorrectMeterId`}
							label={`Original Incorrect Meter Id ${m24Index + 1}`}
							as={TextField}
							error={
								!!getIn(
									form.errors.installedCorrectingInstalledMeters?.[m24Index],
									"originalIncorrectMeterId"
								)
							}
							form={form}
						/>
						<FormHelperText
							error={
								!!getIn(
									form.errors.installedCorrectingInstalledMeters?.[m24Index],
									"originalIncorrectMeterId"
								)
							}
						>
							{getIn(
								form.errors.installedCorrectingInstalledMeters?.[m24Index],
								"originalIncorrectMeterId"
							)}
						</FormHelperText>
					</FormControl>
					<FormControl>
						<Field
							name={`installedCorrectingInstalledMeters[${m24Index}].meterLocation`}
							label={`Meter Location ${m24Index + 1}*`}
							as={TextField}
							form={form}
							error={
								!!getIn(
									form.errors.installedCorrectingInstalledMeters?.[m24Index],
									"meterLocation"
								)
							}
						/>
						<FormHelperText
							error={
								!!getIn(
									form.errors.installedCorrectingInstalledMeters?.[m24Index],
									"meterLocation"
								)
							}
						>
							{getIn(
								form.errors.installedCorrectingInstalledMeters?.[m24Index],
								"meterLocation"
							)}
						</FormHelperText>
					</FormControl>
					<FormControl>
						<Field
							name={`installedCorrectingInstalledMeters[${m24Index}].phaseWire`}
							label={`Phase/Wire ${m24Index + 1}`}
							as={TextField}
							form={form}
							error={
								!!getIn(
									form.errors.installedCorrectingInstalledMeters?.[m24Index],
									"phaseWire"
								)
							}
						/>
						<FormHelperText
							error={
								!!getIn(
									form.errors.installedCorrectingInstalledMeters?.[m24Index],
									"phaseWire"
								)
							}
						>
							{getIn(
								form.errors.installedCorrectingInstalledMeters?.[m24Index],
								"phaseWire"
							)}
						</FormHelperText>
					</FormControl>
					<FormControl>
						<Field
							name={`installedCorrectingInstalledMeters[${m24Index}].feederStatus`}
							label={`Feeder Status ${m24Index + 1}`}
							as={TextField}
							form={form}
							error={
								!!getIn(
									form.errors.installedCorrectingInstalledMeters?.[m24Index],
									"feederStatus"
								)
							}
						/>
						<FormHelperText
							error={
								!!getIn(
									form.errors.installedCorrectingInstalledMeters?.[m24Index],
									"feederStatus"
								)
							}
						>
							{getIn(
								form.errors.installedCorrectingInstalledMeters?.[m24Index],
								"feederStatus"
							)}
						</FormHelperText>
					</FormControl>
					<FormControl>
						<Field
							name={`installedCorrectingInstalledMeters[${m24Index}].ctRatio`}
							label={`CT Ratio ${m24Index + 1}`}
							as={TextField}
							form={form}
							error={
								!!getIn(
									form.errors.installedCorrectingInstalledMeters?.[m24Index],
									"ctRatio"
								)
							}
						/>
						<FormHelperText
							error={
								!!getIn(
									form.errors.installedCorrectingInstalledMeters?.[m24Index],
									"ctRatio"
								)
							}
						>
							{getIn(
								form.errors.installedCorrectingInstalledMeters?.[m24Index],
								"ctRatio"
							)}
						</FormHelperText>
					</FormControl>
					<FormControl>
						<Field
							name={`installedCorrectingInstalledMeters[${m24Index}].vtRatio`}
							label={`VT Ratio ${m24Index + 1}`}
							as={TextField}
							form={form}
							error={
								!!getIn(
									form.errors.installedCorrectingInstalledMeters?.[m24Index],
									"vtRatio"
								)
							}
						/>
						<FormHelperText
							error={
								!!getIn(
									form.errors.installedCorrectingInstalledMeters?.[m24Index],
									"vtRatio"
								)
							}
						>
							{getIn(
								form.errors.installedCorrectingInstalledMeters?.[m24Index],
								"vtRatio"
							)}
						</FormHelperText>
					</FormControl>
				</Stack>
				<Stack flexDirection="column" gap={2} flex={1}>
					<FormControl>
						<Field
							name={`installedCorrectingInstalledMeters[${m24Index}].totalDebt`}
							label={`Total Debt ${m24Index + 1}`}
							as={TextField}
							form={form}
							error={
								!!getIn(
									form.errors.installedCorrectingInstalledMeters?.[m24Index],
									"totalDebt"
								)
							}
						/>
						<FormHelperText
							error={
								!!getIn(
									form.errors.installedCorrectingInstalledMeters?.[m24Index],
									"totalDebt"
								)
							}
						>
							{getIn(
								form.errors.installedCorrectingInstalledMeters?.[m24Index],
								"totalDebt"
							)}
						</FormHelperText>
					</FormControl>
					<FormControl>
						<Field
							name={`installedCorrectingInstalledMeters[${m24Index}].debtRecoveryRate`}
							label={`Debt Recovery Rate ${m24Index + 1}`}
							as={TextField}
							form={form}
							error={
								!!getIn(
									form.errors.installedCorrectingInstalledMeters?.[m24Index],
									"debtRecoveryRate"
								)
							}
						/>
						<FormHelperText
							error={
								!!getIn(
									form.errors.installedCorrectingInstalledMeters?.[m24Index],
									"debtRecoveryRate"
								)
							}
						>
							{getIn(
								form.errors.installedCorrectingInstalledMeters?.[m24Index],
								"debtRecoveryRate"
							)}
						</FormHelperText>
					</FormControl>
					<FormControl>
						<Field
							name={`installedCorrectingInstalledMeters[${m24Index}].emergencyCredit`}
							label={`Emergency Credit ${m24Index + 1}`}
							as={TextField}
							form={form}
							error={
								!!getIn(
									form.errors.installedCorrectingInstalledMeters?.[m24Index],
									"emergencyCredit"
								)
							}
						/>
						<FormHelperText
							error={
								!!getIn(
									form.errors.installedCorrectingInstalledMeters?.[m24Index],
									"emergencyCredit"
								)
							}
						>
							{getIn(
								form.errors.installedCorrectingInstalledMeters?.[m24Index],
								"emergencyCredit"
							)}
						</FormHelperText>
					</FormControl>
					<FormControl>
						<Field
							name={`installedCorrectingInstalledMeters[${m24Index}].initialCredit`}
							label={`Initial Credit ${m24Index + 1}`}
							as={TextField}
							form={form}
							error={
								!!getIn(
									form.errors.installedCorrectingInstalledMeters?.[m24Index],
									"initialCredit"
								)
							}
						/>
						<FormHelperText
							error={
								!!getIn(
									form.errors.installedCorrectingInstalledMeters?.[m24Index],
									"initialCredit"
								)
							}
						>
							{getIn(
								form.errors.installedCorrectingInstalledMeters?.[m24Index],
								"initialCredit"
							)}
						</FormHelperText>
					</FormControl>
					<FormControl>
						<Field
							name={`installedCorrectingInstalledMeters[${m24Index}].standingCharge`}
							label={`Standing Charge ${m24Index + 1}`}
							as={TextField}
							form={form}
							error={
								!!getIn(
									form.errors.installedCorrectingInstalledMeters?.[m24Index],
									"standingCharge"
								)
							}
						/>
						<FormHelperText
							error={
								!!getIn(
									form.errors.installedCorrectingInstalledMeters?.[m24Index],
									"standingCharge"
								)
							}
						>
							{getIn(
								form.errors.installedCorrectingInstalledMeters?.[m24Index],
								"standingCharge"
							)}
						</FormHelperText>
					</FormControl>
					<FormControl>
						<Field
							name={`installedCorrectingInstalledMeters[${m24Index}].meterAssetProviderId`}
							label={`Meter Asset Provider Id ${m24Index + 1}`}
							as={TextField}
							form={form}
							error={
								!!getIn(
									form.errors.installedCorrectingInstalledMeters?.[m24Index],
									"meterAssetProviderId"
								)
							}
						/>
						<FormHelperText
							error={
								!!getIn(
									form.errors.installedCorrectingInstalledMeters?.[m24Index],
									"meterAssetProviderId"
								)
							}
						>
							{getIn(
								form.errors.installedCorrectingInstalledMeters?.[m24Index],
								"meterAssetProviderId"
							)}
						</FormHelperText>
					</FormControl>
					<FormControl>
						<Field
							name={`installedCorrectingInstalledMeters[${m24Index}].timingDeviceId`}
							label={`Timing Device Id ${m24Index + 1}`}
							as={TextField}
							form={form}
							error={
								!!getIn(
									form.errors.installedCorrectingInstalledMeters?.[m24Index],
									"timingDeviceId"
								)
							}
						/>
						<FormHelperText
							error={
								!!getIn(
									form.errors.installedCorrectingInstalledMeters?.[m24Index],
									"timingDeviceId"
								)
							}
						>
							{getIn(
								form.errors.installedCorrectingInstalledMeters?.[m24Index],
								"timingDeviceId"
							)}
						</FormHelperText>
					</FormControl>
					<FormControl>
						<Field
							name={`installedCorrectingInstalledMeters[${m24Index}].meterAccuracyClass`}
							label={`Meter Accuracy Class ${m24Index + 1}`}
							as={TextField}
							form={form}
							error={
								!!getIn(
									form.errors.installedCorrectingInstalledMeters?.[m24Index],
									"meterAccuracyClass"
								)
							}
						/>
						<FormHelperText
							error={
								!!getIn(
									form.errors.installedCorrectingInstalledMeters?.[m24Index],
									"meterAccuracyClass"
								)
							}
						>
							{getIn(
								form.errors.installedCorrectingInstalledMeters?.[m24Index],
								"meterAccuracyClass"
							)}
						</FormHelperText>
					</FormControl>
					<FormControl>
						<FormLabel>{`Compensation Applied To Meter ${
							m24Index + 1
						}`}</FormLabel>
						<Field
							name={`installedCorrectingInstalledMeters[${m24Index}].compensationAppliedToMeter`}
							as={RadioGroup}
							form={form}
						>
							<Container>
								<FormControlLabel
									value={true}
									control={<Radio />}
									label="Yes"
								/>
								<FormControlLabel
									value={false}
									control={<Radio />}
									label="No"
								/>
								<FormControlLabel value={""} control={<Radio />} label="None" />
							</Container>
							<FormHelperText
								error={
									!!getIn(
										form.errors.installedCorrectingInstalledMeters?.[m24Index],
										"compensationAppliedToMeter"
									)
								}
							>
								{getIn(
									form.errors.installedCorrectingInstalledMeters?.[m24Index],
									"compensationAppliedToMeter"
								)}
							</FormHelperText>
						</Field>
					</FormControl>
					<FormControl>
						<Field
							name={`installedCorrectingInstalledMeters[${m24Index}].numberOfDisplayedRegisterDigits`}
							label={`Number Of Displayed Register Digits ${m24Index + 1}`}
							as={TextField}
							form={form}
							error={
								!!getIn(
									form.errors.installedCorrectingInstalledMeters?.[m24Index],
									"numberOfDisplayedRegisterDigits"
								)
							}
						/>
						<FormHelperText
							error={
								!!getIn(
									form.errors.installedCorrectingInstalledMeters?.[m24Index],
									"numberOfDisplayedRegisterDigits"
								)
							}
						>
							{getIn(
								form.errors.installedCorrectingInstalledMeters?.[m24Index],
								"numberOfDisplayedRegisterDigits"
							)}
						</FormHelperText>
					</FormControl>
				</Stack>
			</Stack>
			<FieldArray
				name={`installedCorrectingInstalledMeters[${m24Index}].installedMeterRegisters`}
				render={(arrayHelpers) => (
					<>
						{form.values.installedCorrectingInstalledMeters?.[
							m24Index
						].installedMeterRegisters?.map(
							(_installedMeterRegisters, index) => {
								return (
									<Fragment
										key={`installedCorrectingInstalledMeters-${m24Index}-installedMeterRegisters-${index}`}
									>
										<Typography variant="body1" color={talosColours.green.main}>
											{`Installed Meter Registers ${m24Index + 1}-${index + 1}`}
										</Typography>
										<InstalledMeterRegistersForm
											form={form}
											m24Index={m24Index}
											m26Index={index}
											removeM26Group={() => arrayHelpers.remove(index)}
										/>
									</Fragment>
								);
							}
						)}
						<Button
							type="button"
							variant="outlined"
							startIcon={<AddIcon />}
							onClick={() =>
								arrayHelpers.push(initialInstalledMeterRegistersM26FormValues)
							}
						>
							Add Installed Meter Registers {m24Index + 1}
						</Button>
					</>
				)}
			/>
			<Button
				type="button"
				variant="outlined"
				color="warning"
				sx={{
					width: "max-content",
					justifyContent: "flex-start",
				}}
				disabled={form.values.installedCorrectingInstalledMeters?.length === 1}
				startIcon={<DeleteIcon />}
				onClick={() => removeM24Group()}
			>
				Remove Installed/Correcting Installed Meters Group
			</Button>
		</>
	);
};
