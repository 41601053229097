import * as Yup from "yup";

export const validationSchema = Yup.object({
	start: Yup.string().required(),
	end: Yup.string()
		.required()
		.when(["start"], (start) => {
			return Yup.date()
				.min(
					new Date(start[0] as string),
					"End date must be after the start date"
				)
				.required();
		}),
});
