import * as Yup from "yup";

import { dateIsNotValid, dateIsNotValidNullable } from "../../../utilities";

export const SubmitMOP04formValidationSchema = Yup.object().shape({
	mpanCore: Yup.number()
		.required("MPAN is required.")
		.min(1000000000000, "MPAN must be exactly 13 digits.")
		.max(9999999999999, "MPAN must be exactly 13 digits."),
	dateOfAction: Yup.string()
		.required("Date of action is required.")
		.test(
			"invalid-date-action",
			"Date of action should be a valid date.",
			dateIsNotValid
		),
	timeOfAction: Yup.string()
		.required("Time of action is required.")
		.test(
			"invalid-time-action",
			"Time of action should be a valid time.",
			dateIsNotValid
		),
	jobType: Yup.string().required("Job Type is required."),
	successfulJob: Yup.string().required("Successful Job is required."),
	supplierId: Yup.string()
		.required("Supplier Id is required.")
		.max(4, "Supplier Id must be between 1 and 4 characters long."),
	originalIncorrectDateOfAction: Yup.string()
		.notRequired()
		.test(
			"invalid-date-action",
			"Original Incorrect Date Of Action should be a valid date.",
			dateIsNotValidNullable
		),
	fieldForceName: Yup.string().max(
		10,
		"Field Force Name must be between 1 and 10 characters long."
	),
	standardSettlementConfigurationId: Yup.string().length(
		4,
		"Standard Settlement Configuration Id must be exactly 4 characters long."
	),
	meteringSystemNonSettlementFunctionalityCode: Yup.string().max(
		10,
		"Metering System Non-Settlement Functionality Code must be between 1 and 10 characters long."
	),
	siteVisitCheckCode: Yup.string().length(
		2,
		"Site Visit Check Code must be exactly 2 characters long."
	),
	meterEquipmentServiceLocation: Yup.string().max(
		30,
		"Meter Equipment Service Location must be between 1 and 30 characters long."
	),
	measurementClassId: Yup.string().length(
		1,
		"Measurement Class Id must be exactly 1 characters long."
	),
	meterCOP: Yup.string().max(
		3,
		"Meter COP must be between 1 and 3 characters long."
	),
	meterCOPIssueNumber: Yup.string().matches(
		/^([1-9][0-9]?|0)$/,
		"Meter COP Issue Number must be an integer between 1 and 99."
	),
	eventIndicator: Yup.string().max(
		1,
		"Event indicator must be exactly 1 character long."
	),
	numberOfPhases: Yup.string().matches(
		/^[1-9]$/,
		"Number of Phases must be an integer between 1 and 9."
	),
	systemVoltage: Yup.string().matches(
		/^(?:[1-9]\d{0,5}|0)$/,
		"System Voltage must be an integer between 1 and 999999."
	),
	additionalInformation: Yup.string().max(
		200,
		"Additional information must be between 1 and 200 characters long."
	),
	dateOfInstallationRemovalOfTimingDevice: Yup.string()
		.notRequired()
		.test(
			"invalid-date-installation-removal-timing-device",
			"Date Of Installation Removal Of Timing Device should be a valid date.",
			dateIsNotValidNullable
		),
	natureOfMaintenance: Yup.string().max(
		200,
		"Nature of maintenance must be between 1 and 200 characters long."
	),

	installedCorrectingInstalledMeters: Yup.array().of(
		Yup.object({
			meterConfigCode: Yup.string()
				.required("Meter Config Code is required.")
				.max(
					100,
					"Meter Config Code must be between 1 and 100 characters long."
				),
			installedMeterId: Yup.string()
				.required("Installed Meter Id is required.")
				.max(
					10,
					"Installed Meter Id must be between 1 and 10 characters long."
				),
			originalIncorrectMeterId: Yup.string().max(
				10,
				"Original Incorrect Meter Id must be between 1 and 10 characters long."
			),
			meterLocation: Yup.string()
				.required("Meter Location is required.")
				.max(1, "Meter Location must be exactly 1 character long."),
			phaseWire: Yup.string().max(
				4,
				"Phase/Wire must be between 1 and 4 characters long."
			),
			feederStatus: Yup.string().max(
				1,
				"Feeder Status must be exactly 1 character long."
			),
			ctRatio: Yup.string().max(
				6,
				"CT Ration must be between 1 and 6 characters long."
			),
			vtRatio: Yup.string().max(
				10,
				"VT Ratio must be between 1 and 10 characters long."
			),
			totalDebt: Yup.string().matches(
				/^\d{1,7}(\.\d{2})?$/,
				"Total Debt must be a float number with max 7 digits and fraction of 2."
			),
			debtRecoveryRate: Yup.string().matches(
				/^\d{1,5}(\.\d{2})?$/,
				"Debt Recovery Rate must be a float number with max 5 digits and fraction of 2."
			),
			emergencyCredit: Yup.string().matches(
				/^\d{1,5}(\.\d{2})?$/,
				"Emergency Credit must be a float number with max 5 digits and fraction of 2."
			),
			initialCredit: Yup.string().matches(
				/^\d{1,5}(\.\d{2})?$/,
				"Initial Credit must be a float number with max 5 digits and fraction of 2."
			),
			standingCharge: Yup.string().matches(
				/^\d{1,5}(\.\d{2})?$/,
				"Standing Charge must be a float number with max 5 digits and fraction of 2."
			),
			meterAssetProviderId: Yup.string().max(
				4,
				"Meter Asset Provider Id must be between 1 and 4 characters long."
			),
			timingDeviceId: Yup.string().max(
				10,
				"Timing Device Id must be between 1 and 10 characters long."
			),
			meterAccuracyClass: Yup.string().max(
				4,
				"Meter Accuracy Class must be between 1 and 4 characters long."
			),
			numberOfDisplayedRegisterDigits: Yup.string().matches(
				/^[1-9]$/,
				"Number Of Displayed Register Digits must be an integer between 1 and 9."
			),
			installedMeterRegisters: Yup.array().of(
				Yup.object().shape({
					meterRegisterId: Yup.string()
						.required("Meter Register Id is required.")
						.max(
							2,
							"Meter Register Id must be between 1 and 2 characters long."
						),
					registerReading: Yup.string()
						.required("Register Reading is required.")
						.matches(
							/^\d{1,9}(\.\d{2})?$/,
							"Register Reading must be a float number with max 9 digits and fraction of 2."
						),
					outstationId: Yup.string().max(
						20,
						"Outstation Id must be between 1 and 20 characters long."
					),
					outstationMultiplier: Yup.string().matches(
						/^\d{1,8}(\.\d{2})?$/,
						"Oustation Multiplier must be a float number with max 8 digits and fraction of 2."
					),
					natureOfMaintenance: Yup.string().max(
						200,
						"Nature Of Maintenance must be between 1 and 200 characters long."
					),
					channelNumber: Yup.string().max(
						3,
						"Channel Number must be between 1 and 3 characters long."
					),
					pulseMultiplier: Yup.string().matches(
						/^\d{1,9}(\.\d{6})?$/,
						"Pulse Multiplier must be a float number with max 9 digits and fraction of 6."
					),
					meterRegisterMultiplier: Yup.string().matches(
						/^\d{1,9}(\.\d{2})?$/,
						"Meter Register Multiplier must be a float number with max 9 digits and fraction of 2."
					),
					numberOfRegisterDigits: Yup.string().matches(
						/^[1-9]$/,
						"Number Of Register Digits must be an integer between 1 and 9."
					),
					associatedMeterId: Yup.string().max(
						10,
						"Associated Meter Id must be between 1 and 10 characters long."
					),
					associatedMeterRegisterId: Yup.string().max(
						2,
						"Associated Meter Register Id must be between 1 and 2 characters long."
					),
					measurementQuantityId: Yup.string().max(
						2,
						"measurement Quantity Id must be between 1 and 2 characters long."
					),
				})
			),
		})
	),

	removedCorrectingRemovedMeters: Yup.array().of(
		Yup.object().shape({
			removedMeterId: Yup.string()
				.required("Removed Meter Id is required.")
				.max(
					10,
					"Removed Meter Id should be between 1 and 10 characters long."
				),
			originalIncorrectRemovedMeterId: Yup.string().max(
				10,
				"Original Incorrect Removed Meter Id should be between 1 and 10 characters long."
			),
			creditBalance: Yup.string().matches(
				/^\d{1,5}(\.\d{2})?$/,
				"Credit Balance must be a float number with max 5 digits and fraction of 2."
			),
			standingCharge: Yup.string().matches(
				/^\d{1,5}(\.\d{2})?$/,
				"Standing Charge must be a float number with max 5 digits and fraction of 2."
			),
			totalCreditAccepted: Yup.string().matches(
				/^\d{1,7}(\.\d{2})?$/,
				"Total Credit Accepted must be a float number with max 7 digits and fraction of 2."
			),
			totalDebtOutstanding: Yup.string().matches(
				/^\d{1,7}(\.\d{2})?$/,
				"Total Debt Outstanding must be a float number with max 7 digits and fraction of 2."
			),
			debtRecoveryRate: Yup.string().matches(
				/^\d{1,5}(\.\d{2})?$/,
				"Debt Recovery Rate must be a float number with max 5 digits and fraction of 2."
			),
			totalTokensInserted: Yup.string().matches(
				/^\d{1,5}$/,
				"Total Tokens Inserted must be 5 digits or less."
			),
			timingDeviceId: Yup.string().max(
				10,
				"Timing device Id must be max 10 characters long."
			),
			numberOfDisplayedRegisterDigits: Yup.string().matches(
				/^\d{1,9}$/,
				"Number Of Displayed Register Digits must be 9 digits or less."
			),
			removedMeterRegisters: Yup.array().of(
				Yup.object().shape({
					meterRegisterId: Yup.string()
						.required("Meter Register Id is required.")
						.max(2, "Meter Register Id must be 2 characters long."),
					registerReading: Yup.string().matches(
						/^\d{1,9}(\.\d{1})?$/,
						"Register Reading must be float number with max 9 digits and fraction of 1."
					),
					registerDescription: Yup.string().max(
						255,
						"Register Description should be between 1 and 256 characters long."
					),
					prepaymentUnitRate: Yup.string().matches(
						/^\d{1,5}(\.\d{2})?$/,
						"Prepayment Unit Rate must be a float number with max 5 digits and fraction of 2."
					),
					natureOfMaintenance: Yup.string().max(
						200,
						"Nature Of Maintenance should be between 1 and 201 characters long."
					),
				})
			),
		})
	),
});
