import { Dispatch, SetStateAction } from "react";

import { DataGrid, GridColDef, GridRowSelectionModel } from "@mui/x-data-grid";

import { EcoesRegistration } from "../../models/themis";

const COLUMN_MIN_WIDTH = 160;
const COLUMN_MAX_WIDTH = 180;

const columns: GridColDef[] = [
	{
		field: "referenceNumber",
		headerName: "Ref Number",
		minWidth: COLUMN_MIN_WIDTH,
		maxWidth: COLUMN_MAX_WIDTH,
		flex: 1,
	},
	{
		field: "yourReference",
		headerName: "Your Ref",
		minWidth: COLUMN_MIN_WIDTH,
		maxWidth: COLUMN_MAX_WIDTH,
		flex: 1,
	},
	{
		field: "obligationDate",
		headerName: "Obligation Date",
		minWidth: COLUMN_MIN_WIDTH,
		maxWidth: COLUMN_MAX_WIDTH,
	},
	{
		field: "addressLine1",
		headerName: "Address 1",
		minWidth: COLUMN_MIN_WIDTH,
		maxWidth: COLUMN_MAX_WIDTH,
	},
	{
		field: "postcode",
		headerName: "Postcode",
		minWidth: COLUMN_MIN_WIDTH,
		maxWidth: COLUMN_MAX_WIDTH,
	},
	{
		field: "distributor",
		headerName: "Distributor",
		minWidth: COLUMN_MIN_WIDTH,
		maxWidth: COLUMN_MAX_WIDTH,
	},
	{
		field: "newMpan",
		headerName: "New MPAN",
		minWidth: COLUMN_MIN_WIDTH,
		maxWidth: COLUMN_MAX_WIDTH,
		flex: 1,
	},
	{
		field: "associatedMpan",
		headerName: "Associated Mpan",
		minWidth: COLUMN_MIN_WIDTH,
		maxWidth: COLUMN_MAX_WIDTH,
		flex: 1,
	},
	{
		field: "submittedDate",
		headerName: "Submitted Date",
		minWidth: COLUMN_MIN_WIDTH,
		maxWidth: COLUMN_MAX_WIDTH,
	},
	{
		field: "submittedBy",
		headerName: "Submitted By",
		minWidth: COLUMN_MIN_WIDTH,
		maxWidth: COLUMN_MAX_WIDTH,
	},
	{
		field: "updatedDate",
		headerName: "Updated Date",
		minWidth: COLUMN_MIN_WIDTH,
		maxWidth: COLUMN_MAX_WIDTH,
	},
	{
		field: "updatedBy",
		headerName: "Updated By",
		minWidth: COLUMN_MIN_WIDTH,
		maxWidth: COLUMN_MAX_WIDTH,
	},
];

interface IProps {
	results: EcoesRegistration[];
	isLoading: boolean;
	rowSelectionModel: GridRowSelectionModel;
	setRowSelectionModel: Dispatch<SetStateAction<GridRowSelectionModel>>;
}

export const EcoesPlanTable: React.FC<IProps> = (props: IProps) => (
	<DataGrid
		sx={{
			typography: "body1",
			"& .MuiDataGrid-columnHeaderTitle": {
				whiteSpace: "break-spaces",
				lineHeight: "normal",
			},
			"& .MuiSvgIcon-fontSizeSmall": {
				fontSize: "1rem",
			},
		}}
		autoHeight
		rows={props.results}
		columns={columns}
		checkboxSelection
		getRowId={(row: EcoesRegistration) => row.referenceNumber}
		loading={props.isLoading}
		onRowSelectionModelChange={(newRowSelectionModel) => {
			props.setRowSelectionModel(newRowSelectionModel);
		}}
		rowSelectionModel={props.rowSelectionModel}
	/>
);
