import { Alert, Snackbar, Typography } from "@mui/material";

export const TalosRolesSnackbar: React.FC = () => {
	return (
		<Snackbar
			data-testid="talos-roles-snackbar"
			open
			anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
		>
			<Alert
				severity="error"
				variant="filled"
				sx={{
					alignItems: "center",
					fontSize: "19px",
					height: "100%",
					margin: "20px 0",
					width: "100%",
				}}
			>
				<Typography variant="h3">
					<strong>
						This user does not have access to any tools, please contact your
						system administrator.
					</strong>
				</Typography>
			</Alert>
		</Snackbar>
	);
};
