import { FC, Fragment } from "react";

import { Field, FieldArray, FormikProps, getIn } from "formik";
import {
	Button,
	FormControl,
	FormControlLabel,
	FormHelperText,
	FormLabel,
	Radio,
	RadioGroup,
	Stack,
	TextField,
	Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";

import {
	IMOP04v10,
	initialRemovedMeterRegistersM28FormValues,
} from "../../../models/outbound";
import { RemovedMeterRegistersM28Form } from "./removed-meter-registers-form";
import { talosColours } from "../../../styles/talos-colors";

interface IRemovedCorrectingRemovedMetersFormProps {
	form: FormikProps<IMOP04v10>;
	m27Index: number;
	removeM27Group: () => void;
}

export const RemovedCorrectingRemovedMetersForm: FC<
	IRemovedCorrectingRemovedMetersFormProps
> = ({ form, m27Index, removeM27Group }) => {
	return (
		<>
			<Stack flexDirection={"row"} gap={2}>
				<Stack flexDirection={"column"} gap={2} flex={1}>
					<FormControl>
						<Field
							name={`removedCorrectingRemovedMeters[${m27Index}].removedMeterId`}
							label={`Removed Meter Id ${m27Index + 1}*`}
							as={TextField}
							error={
								!!getIn(
									form.errors.removedCorrectingRemovedMeters?.[m27Index],
									"removedMeterId"
								)
							}
							form={form}
						/>
						<FormHelperText
							error={
								!!getIn(
									form.errors.removedCorrectingRemovedMeters?.[m27Index],
									"removedMeterId"
								)
							}
						>
							{getIn(
								form.errors.removedCorrectingRemovedMeters?.[m27Index],
								"removedMeterId"
							)}
						</FormHelperText>
					</FormControl>
					<FormControl>
						<Field
							name={`removedCorrectingRemovedMeters[${m27Index}].originalIncorrectRemovedMeterId`}
							label={`Original Incorrect Removed Meter Id ${m27Index + 1}`}
							as={TextField}
							error={
								!!getIn(
									form.errors.removedCorrectingRemovedMeters?.[m27Index],
									"originalIncorrectRemovedMeterId"
								)
							}
							form={form}
						/>
						<FormHelperText
							error={
								!!getIn(
									form.errors.removedCorrectingRemovedMeters?.[m27Index],
									"originalIncorrectRemovedMeterId"
								)
							}
						>
							{getIn(
								form.errors.removedCorrectingRemovedMeters?.[m27Index],
								"originalIncorrectRemovedMeterId"
							)}
						</FormHelperText>
					</FormControl>
					<FormControl>
						<Field
							name={`removedCorrectingRemovedMeters[${m27Index}].creditBalance`}
							label={`Credit Balance ${m27Index + 1}`}
							as={TextField}
							error={
								!!getIn(
									form.errors.removedCorrectingRemovedMeters?.[m27Index],
									"creditBalance"
								)
							}
							form={form}
						/>
						<FormHelperText
							error={
								!!getIn(
									form.errors.removedCorrectingRemovedMeters?.[m27Index],
									"creditBalance"
								)
							}
						>
							{getIn(
								form.errors.removedCorrectingRemovedMeters?.[m27Index],
								"creditBalance"
							)}
						</FormHelperText>
					</FormControl>
					<FormControl>
						<Field
							name={`removedCorrectingRemovedMeters[${m27Index}].standingCharge`}
							label={`Standing Charge ${m27Index + 1}`}
							as={TextField}
							error={
								!!getIn(
									form.errors.removedCorrectingRemovedMeters?.[m27Index],
									"standingCharge"
								)
							}
							form={form}
						/>
						<FormHelperText
							error={
								!!getIn(
									form.errors.removedCorrectingRemovedMeters?.[m27Index],
									"standingCharge"
								)
							}
						>
							{getIn(
								form.errors.removedCorrectingRemovedMeters?.[m27Index],
								"standingCharge"
							)}
						</FormHelperText>
					</FormControl>
					<FormControl>
						<Field
							name={`removedCorrectingRemovedMeters[${m27Index}].totalCreditAccepted`}
							label={`Total Credit Accepted ${m27Index + 1}`}
							as={TextField}
							error={
								!!getIn(
									form.errors.removedCorrectingRemovedMeters?.[m27Index],
									"totalCreditAccepted"
								)
							}
							form={form}
						/>
						<FormHelperText
							error={
								!!getIn(
									form.errors.removedCorrectingRemovedMeters?.[m27Index],
									"totalCreditAccepted"
								)
							}
						>
							{getIn(
								form.errors.removedCorrectingRemovedMeters?.[m27Index],
								"totalCreditAccepted"
							)}
						</FormHelperText>
					</FormControl>
					<FormControl>
						<Field
							name={`removedCorrectingRemovedMeters[${m27Index}].totalDebtOutstanding`}
							label={`Total Debt Outstanding ${m27Index + 1}`}
							as={TextField}
							error={
								!!getIn(
									form.errors.removedCorrectingRemovedMeters?.[m27Index],
									"totalDebtOutstanding"
								)
							}
							form={form}
						/>
						<FormHelperText
							error={
								!!getIn(
									form.errors.removedCorrectingRemovedMeters?.[m27Index],
									"totalDebtOutstanding"
								)
							}
						>
							{getIn(
								form.errors.removedCorrectingRemovedMeters?.[m27Index],
								"totalDebtOutstanding"
							)}
						</FormHelperText>
					</FormControl>
				</Stack>
				<Stack flexDirection={"column"} gap={2} flex={1}>
					<FormControl>
						<Field
							name={`removedCorrectingRemovedMeters[${m27Index}].debtRecoveryRate`}
							label={`Debt Recovery Rate ${m27Index + 1}`}
							as={TextField}
							error={
								!!getIn(
									form.errors.removedCorrectingRemovedMeters?.[m27Index],
									"debtRecoveryRate"
								)
							}
							form={form}
						/>
						<FormHelperText
							error={
								!!getIn(
									form.errors.removedCorrectingRemovedMeters?.[m27Index],
									"debtRecoveryRate"
								)
							}
						>
							{getIn(
								form.errors.removedCorrectingRemovedMeters?.[m27Index],
								"debtRecoveryRate"
							)}
						</FormHelperText>
					</FormControl>
					<FormControl>
						<FormLabel
							id={`removedCorrectingRemovedMeters[${m27Index}].emergencyCreditStatus`}
						>{`Emergency Credit Status ${m27Index + 1}`}</FormLabel>
						<Field
							name={`removedCorrectingRemovedMeters[${m27Index}].emergencyCreditStatus`}
							aria-labelledby={`removedCorrectingRemovedMeters[${m27Index}].emergencyCreditStatus`}
							as={RadioGroup}
							sx={{ flexDirection: "row" }}
							form={form}
						>
							<FormControlLabel value={true} control={<Radio />} label="Yes" />
							<FormControlLabel value={false} control={<Radio />} label="No" />
							<FormControlLabel value={""} control={<Radio />} label="N/A" />
							<FormHelperText
								error={
									!!getIn(
										form.errors.removedCorrectingRemovedMeters?.[m27Index],
										"emergencyCreditStatus"
									)
								}
							>
								{getIn(
									form.errors.removedCorrectingRemovedMeters?.[m27Index],
									"emergencyCreditStatus"
								)}
							</FormHelperText>
						</Field>
					</FormControl>
					<FormControl>
						<Field
							name={`removedCorrectingRemovedMeters[${m27Index}].totalTokensInserted`}
							label={`Total Tokens Inserted ${m27Index + 1}`}
							as={TextField}
							error={
								!!getIn(
									form.errors.removedCorrectingRemovedMeters?.[m27Index],
									"totalTokensInserted"
								)
							}
							form={form}
						/>
						<FormHelperText
							error={
								!!getIn(
									form.errors.removedCorrectingRemovedMeters?.[m27Index],
									"totalTokensInserted"
								)
							}
						>
							{getIn(
								form.errors.removedCorrectingRemovedMeters?.[m27Index],
								"totalTokensInserted"
							)}
						</FormHelperText>
					</FormControl>
					<FormControl>
						<Field
							name={`removedCorrectingRemovedMeters[${m27Index}].timingDeviceId`}
							label={`Timing Device Id ${m27Index + 1}`}
							as={TextField}
							error={
								!!getIn(
									form.errors.removedCorrectingRemovedMeters?.[m27Index],
									"timingDeviceId"
								)
							}
							form={form}
						/>
						<FormHelperText
							error={
								!!getIn(
									form.errors.removedCorrectingRemovedMeters?.[m27Index],
									"timingDeviceId"
								)
							}
						>
							{getIn(
								form.errors.removedCorrectingRemovedMeters?.[m27Index],
								"timingDeviceId"
							)}
						</FormHelperText>
					</FormControl>
					<FormControl>
						<Field
							name={`removedCorrectingRemovedMeters[${m27Index}].numberOfDisplayedRegisterDigits`}
							label={`Number Of Displayed Register Digits ${m27Index + 1}`}
							as={TextField}
							error={
								!!getIn(
									form.errors.removedCorrectingRemovedMeters?.[m27Index],
									"numberOfDisplayedRegisterDigits"
								)
							}
							form={form}
						/>
						<FormHelperText
							error={
								!!getIn(
									form.errors.removedCorrectingRemovedMeters?.[m27Index],
									"numberOfDisplayedRegisterDigits"
								)
							}
						>
							{getIn(
								form.errors.removedCorrectingRemovedMeters?.[m27Index],
								"numberOfDisplayedRegisterDigits"
							)}
						</FormHelperText>
					</FormControl>
				</Stack>
			</Stack>
			<Button
				type="button"
				variant="outlined"
				color="warning"
				sx={{
					width: "max-content",
					justifyContent: "flex-start",
				}}
				disabled={form.values.removedCorrectingRemovedMeters?.length === 1}
				startIcon={<DeleteIcon />}
				onClick={() => removeM27Group()}
			>
				Remove Remove/Correcting Removed Meters Group
			</Button>
			<FieldArray
				name={`removedCorrectingRemovedMeters[${m27Index}].removedMeterRegisters`}
				render={(arrayHelpers) => (
					<>
						{form.values.removedCorrectingRemovedMeters?.[
							m27Index
						].removedMeterRegisters?.map((_removedMeterRegisters, index) => {
							return (
								<Fragment
									key={`removedCorrectingRemovedMeters-${m27Index}-removedMeterRegisters-${index}`}
								>
									<Typography variant="body1" color={talosColours.red.light}>
										{`Removed Meter Registers ${m27Index + 1}-${index + 1}`}
									</Typography>
									<RemovedMeterRegistersM28Form
										form={form}
										m27Index={m27Index}
										m28Index={index}
										removeM28Group={() => arrayHelpers.remove(index)}
									/>
								</Fragment>
							);
						})}
						<Button
							type="button"
							variant="outlined"
							startIcon={<AddIcon />}
							onClick={() =>
								arrayHelpers.push(initialRemovedMeterRegistersM28FormValues)
							}
						>
							Add Removed Meter Registers {m27Index + 1}
						</Button>
					</>
				)}
			/>
		</>
	);
};
