export interface IUpdateIHDStatus {
	mpan: number | "";
	ihdStatus: string;
	ihdStatusEffectiveFromDate: string;
}

export const initialUpdateIHDStatusFormValues: IUpdateIHDStatus = {
	mpan: "",
	ihdStatus: "",
	ihdStatusEffectiveFromDate: new Date().toJSON(),
};
