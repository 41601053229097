export interface IUpdateEnergisationStatus {
	mpan: number | "";
	flowAndDestinationTypes: string;
	energisationStatus: string;
	requestedEnergisationStatus: string;
	appointmentDate: string;
	contactName: string;
	meterId: string;
	meterLocation: string;
}

export enum EnergisationStatusFlowAndDestinationTypes {
	D0134M = "MSS",
	D0134R = "LDSO",
}
