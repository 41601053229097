import { createTheme, SxProps } from "@mui/material/styles";

import {
	FigmaSemanticColors,
	semanticColours,
	TalosColors,
	talosColours,
	TalosPallette,
} from "./talos-colors";
import { talosTypographyStyleDefaults } from "./talos-typography";

// Required by typescript to allow us to use createTheme
// see https://mui.com/material-ui/customization/palette/
declare module "@mui/material/styles" {
	interface Theme {
		customSX: {
			darkTextBox: SxProps;
		};
	}

	interface ThemeOptions {
		customSX: {
			darkTextBox: SxProps;
		};
	}

	interface Palette {
		talos: {
			colour: TalosColors;
			semantic: FigmaSemanticColors;
		};
	}

	interface PaletteOptions {
		talos: {
			colour: TalosColors;
			semantic: FigmaSemanticColors;
		};
	}
}

export const theme = createTheme({
	customSX: {
		darkTextBox: {
			color: semanticColours.message.black,
			backgroundColor: semanticColours.message.light,
			WebkitTextFillColor: semanticColours.message.black,
		},
	},
	palette: TalosPallette,
	typography: talosTypographyStyleDefaults,
	components: {
		MuiAlert: {
			styleOverrides: {
				root: {
					fontSize: talosTypographyStyleDefaults.h3?.fontSize,
					fontWeight: talosTypographyStyleDefaults.h3?.fontWeight,
				},
				icon: {
					marginRight: "8px",
				},
				filledInfo: {
					backgroundColor: semanticColours.message.black,
				},
				filledSuccess: {
					color: talosColours.white.base,
					backgroundColor: talosColours.green.dark,
				},
				filledError: {
					backgroundColor: semanticColours.error.base,
				},
			},
		},
		MuiIconButton: {
			styleOverrides: {
				root: {
					padding: "12px",
					fontSize: "1.7rem",
				},
			},
		},
		MuiTableCell: {
			styleOverrides: {
				root: {
					fontSize: talosTypographyStyleDefaults.body1?.fontSize,
					borderBottom: `1px solid ${semanticColours.border.light}`,
					textTransform: "capitalize" as const,
					fontWeight: talosTypographyStyleDefaults.body1?.fontWeight,
					minHeight: "16px",
					padding: "12px",
					lineHeight: "24px",
				},
				head: talosTypographyStyleDefaults.fontWeightBold,
				stickyHeader: {
					backgroundColor: talosColours.white.base,
				},
			},
		},
		MuiSnackbar: {
			styleOverrides: {
				root: {
					height: "100px",
					width: "50%",
				},
			},
		},
	},
});
