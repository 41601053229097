export interface IDropDownItem {
	display: string;
	value: string;
}

export interface IUpdateConsentGranularityFormValues {
	mpan: string;
	granularity: "H" | "D" | "M" | "N" | "";
	effectiveFrom: string;
}

export interface IUpdateConsentGranularity {
	mpan: string;
	granularity: string;
	effectiveFrom: string;
}

export const CONSENT_GRANULARITY: IDropDownItem[] = [
	{ display: "H - HH Consent Granted", value: "HH_CONSENT_GRANTED" },
	{ display: "D - Daily", value: "DAILY_CONSENT_GRANTED" },
	{ display: "M - Monthly Reads Only", value: "MONTHLY_CONSENT_GRANTED" },
	{ display: "N - No Consent", value: "NO_CONSENT_GRANTED" },
];
