import { useContext } from "react";

import { Typography } from "@mui/material";
import { Link } from "react-router-dom";

import { getS33Flows } from "../../api/talos";
import { S33FlowsResponse } from "../../models/talos/s33-flows";
import { flowsTable } from "../../components/tables/flows-table";
import { PagedRequestsPage } from "../requests-list-page";
import { ROLES } from "../../utilities";
import { AuthContext } from "../../auth";

const responseKey = (key: string): key is keyof S33FlowsResponse =>
	[
		"id",
		"flowId",
		"userId",
		"mpan",
		"status",
		"cause",
		"flowType",
		"createdAt",
		"updatedAt",
	].includes(key);

const responseDataKey = (key: string): key is keyof S33FlowsResponse["data"] =>
	[
		"mpan",
		"flowAndDestinationTypes",
		"meterIdSerialNumber",
		"readingType",
		"siteVisitCheckCode",
		"additionalInformation",
	].includes(key);

const S33FlowsTable = flowsTable<S33FlowsResponse>({
	columns: [
		{ label: "ID", key: "id" },
		{ label: "MPAN", key: "mpan" },
		{ label: "Flow & Destination Types", key: "flowAndDestinationTypes" },
		{
			label: "Meter ID Serial Number",
			key: "meterIdSerialNumber",
		},
		{
			label: "Reading Type",
			key: "readingType",
		},
		{
			label: "Site Visit Check Code",
			key: "siteVisitCheckCode",
		},
		{
			label: "Additional Information",
			key: "additionalInformation",
		},
	],
	dataKey: "s33",
	getId: (response: S33FlowsResponse) => response.id,
	getColumnContent: (columnKey, response) => {
		// Look up value based on where in the response type the column key exists.
		if (responseKey(columnKey)) return response[columnKey];

		if (responseDataKey(columnKey)) return response.data[columnKey];

		throw Error("Unknown column key");
	},
});

export const S33Flows = () => {
	const authContext = useContext(AuthContext);
	return (
		<PagedRequestsPage<S33FlowsResponse>
			title="S3-S33 Flows - Meter Readings to Industry and Read Overrides"
			requestsName={"Meter Readings to Industry and Read Overrides"}
			requestsKey={"s33-flows"}
			getRequests={getS33Flows}
			RequestsTable={S33FlowsTable}
			addendum={
				authContext.hasRole(ROLES.FLOWS_ADMIN) && (
					<Typography variant="body1" data-cy="add-request-text">
						{"To add another Meter Reading to Industry request "}
						<Link
							to="/add-s33-meter-reading"
							data-cy="add-request-link-meter-reading"
						>
							click here
						</Link>
						{" or alternatively to add another Read Override request "}
						<Link
							to="/add-s33-read-override"
							data-cy="add-request-link-read-overrides"
						>
							click here
						</Link>
					</Typography>
				)
			}
			getRequestsOptions={{}}
		/>
	);
};
