import { AxiosResponse } from "axios";

import { updateLegacyDataItem } from "../../../api/metering";
import {
	initialUpdateLegacyDataItemFormValues,
	IUpdateLegacyDataItem,
} from "../../../models/metering";
import { AdditionPage } from "../../addition-page";
import { UpdateLegacyDataItemForm } from "../../../components/legacy-data-item/update-legacy-data-item-form";

export const LegacyDataItem = () => (
	<AdditionPage<IUpdateLegacyDataItem, IUpdateLegacyDataItem, AxiosResponse>
		mapFormToValues={(values) => values}
		header="Update Legacy Data Item"
		initialValues={initialUpdateLegacyDataItemFormValues}
		summary=""
		additionFormKey="update-legacy-data-item"
		AddInstanceForm={UpdateLegacyDataItemForm}
		addInstanceRequest={updateLegacyDataItem}
		successMessage="Successfully submitted Registration Data Change for Legacy Data Item"
		errorMessage={(errorId) =>
			`Something went wrong submitting Registration Data Change for Legacy Data Item. Please contact IOPS Support, Ticket ID: ${errorId}`
		}
	/>
);
