import * as React from "react";

import { Box, Typography, useTheme } from "@mui/material";
import { Form, Formik } from "formik";

import { TalosNumberBox, TalosTextBox } from "../forms";
import {
	IReadSplitterFormValues,
	IReadSplitterResult,
} from "../../models/talos";
import { getReadSplitResult } from "../../utilities";
import { readSplitterValidationSchema } from "./read-splitter.validation-schema";

export const ReadSplitter: React.FC = () => {
	const [result, setResult] = React.useState<IReadSplitterResult>();

	const theme = useTheme();

	const handleOnChange = (values: IReadSplitterFormValues) => {
		if (values && values.dayEac && values.nightEac && values.totalReading) {
			const res = getReadSplitResult(values);
			setResult(res);
		}
	};

	return (
		<Box>
			<Formik
				onSubmit={() => {}}
				initialValues={{ totalReading: 0, dayEac: 0, nightEac: 0 }}
				validate={handleOnChange}
				validationSchema={readSplitterValidationSchema}
			>
				{(form) => (
					<Form>
						<Box
							className="form-wrapper"
							sx={{
								flexDirection: { xs: "column", md: "row" },
								gap: theme.spacing(5),
								alignItems: "flex-start",
							}}
						>
							<Box className="form-column" sx={{ maxWidth: "300px" }}>
								<Typography variant="h6">Input</Typography>
								<TalosNumberBox
									fieldName="totalReading"
									label="Total Reading"
									form={form}
								/>
								<TalosNumberBox
									fieldName="dayEac"
									label="Day EAC"
									form={form}
								/>
								<TalosNumberBox
									fieldName="nightEac"
									label="Night EAC"
									form={form}
								/>
							</Box>
							<Box className="form-column" sx={{ maxWidth: "300px" }}>
								<Typography variant="h6">Result</Typography>
								<TalosTextBox
									fieldName="dayReading"
									label="Day Reading"
									overrideValue={result?.dayReading?.toString()}
									form={form}
									disabled={true}
									sx={theme.customSX.darkTextBox}
								/>
								<TalosTextBox
									fieldName="nightReading"
									label="Night Reading"
									overrideValue={result?.nightReading?.toString()}
									form={form}
									disabled={true}
									sx={theme.customSX.darkTextBox}
								/>
								<TalosTextBox
									fieldName="totalCheck"
									label="Total Check"
									overrideValue={result?.totalCheck?.toString()}
									form={form}
									disabled={true}
									sx={theme.customSX.darkTextBox}
								/>
							</Box>
						</Box>
					</Form>
				)}
			</Formik>
		</Box>
	);
};
