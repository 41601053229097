import React from "react";

import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
} from "@mui/material";

import { theme } from "../../styles/theme";
import {
	getDifferenceBetweenFirstIncorrectAndCorrectReads,
	getDifferenceBetweenSecondIncorrectAndCorrectReads,
	getTotalOfAllCorrectReads,
	getTotalOfAllIncorrectReads,
	roundToFirstDecimal,
} from "../../utilities";
import { IChangeReadFormValues } from "../../models/talos";

interface IProps {
	values: IChangeReadFormValues;
}

export const ReadingsTable: React.FC<IProps> = (props: IProps) => {
	const totalDiffernceBetweenIncorrectAndCorrectReads = roundToFirstDecimal(
		getTotalOfAllIncorrectReads(props.values) -
			getTotalOfAllCorrectReads(props.values)
	);

	const differenceBetweenFirstIncorrectAndCorrectReads = roundToFirstDecimal(
		getDifferenceBetweenFirstIncorrectAndCorrectReads(props.values)
	);

	const differenceBetweenSecondIncorrectAndCorrectReads = roundToFirstDecimal(
		getDifferenceBetweenSecondIncorrectAndCorrectReads(props.values)
	);

	const totalOfIncorrectReads = roundToFirstDecimal(
		getTotalOfAllIncorrectReads(props.values)
	);
	const totalOfCorrectReads = roundToFirstDecimal(
		getTotalOfAllCorrectReads(props.values)
	);

	return (
		<Table size="small">
			<TableHead>
				<TableRow sx={{ background: "#ededed" }}>
					<TableCell></TableCell>
					<TableCell>Incorrect Reads</TableCell>
					<TableCell>Correct Reads</TableCell>
					<TableCell>Difference</TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				<TableRow>
					<TableCell>First Register</TableCell>
					<TableCell align="center">
						{props.values.firstRegisterIncorrectRead}
					</TableCell>
					<TableCell align="center">
						{props.values.firstRegisterCorrectRead}
					</TableCell>
					<TableCell align="center">
						{differenceBetweenFirstIncorrectAndCorrectReads}
					</TableCell>
				</TableRow>

				<TableRow>
					<TableCell>Second Register</TableCell>
					<TableCell align="center">
						{props.values.secondRegisterIncorrectRead}
					</TableCell>
					<TableCell align="center">
						{props.values.secondRegisterCorrectRead}
					</TableCell>
					<TableCell
						align="center"
						sx={{ borderBottom: `1px solid ${theme.palette.warning.dark}` }}
					>
						{differenceBetweenSecondIncorrectAndCorrectReads}
					</TableCell>
				</TableRow>
				<TableRow>
					<TableCell sx={{ fontWeight: "700" }}>Total</TableCell>
					<TableCell align="center" sx={{ fontWeight: "700" }}>
						{totalOfIncorrectReads}
					</TableCell>
					<TableCell
						align="center"
						sx={{
							fontWeight: "700",
						}}
					>
						{totalOfCorrectReads}
					</TableCell>
					<TableCell
						align="center"
						sx={{
							fontWeight: "700",
							border: `1px solid ${theme.palette.warning.dark}`,
						}}
					>
						{totalDiffernceBetweenIncorrectAndCorrectReads}
					</TableCell>
				</TableRow>
			</TableBody>
		</Table>
	);
};
