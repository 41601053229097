import { FC } from "react";

import { Field, FormikProps, getIn } from "formik";
import {
	Button,
	Divider,
	FormControl,
	FormHelperText,
	InputLabel,
	MenuItem,
	Select,
	Stack,
	TextField,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

import { IMOP04v10, SettlementIndicator } from "../../../models/outbound";

interface IInstalledMeterRegistersM26FormProps {
	form: FormikProps<IMOP04v10>;
	m24Index: number;
	m26Index: number;
	removeM26Group: () => void;
}

export const InstalledMeterRegistersForm: FC<
	IInstalledMeterRegistersM26FormProps
> = ({ form, m24Index, m26Index, removeM26Group }) => {
	return (
		<>
			<Stack flexDirection={"row"} gap={2}>
				<Stack gap={2} flexDirection={"column"} flex={1}>
					<FormControl>
						<Field
							name={`installedCorrectingInstalledMeters[${m24Index}].installedMeterRegisters[${m26Index}].meterRegisterId`}
							label={`Meter Register Id ${m24Index + 1}-${m26Index + 1}*`}
							as={TextField}
							error={
								!!getIn(
									form.errors.installedCorrectingInstalledMeters?.[m24Index],
									`installedMeterRegisters[${m26Index}].meterRegisterId`
								)
							}
							form={form}
						/>
						<FormHelperText
							error={
								!!getIn(
									form.errors.installedCorrectingInstalledMeters?.[m24Index],
									`installedMeterRegisters[${m26Index}].meterRegisterId`
								)
							}
						>
							{getIn(
								form.errors.installedCorrectingInstalledMeters?.[m24Index],
								`installedMeterRegisters[${m26Index}].meterRegisterId`
							)}
						</FormHelperText>
					</FormControl>
					<FormControl>
						<Field
							name={`installedCorrectingInstalledMeters[${m24Index}].installedMeterRegisters[${m26Index}].registerReading`}
							label={`Register Reading ${m24Index + 1}-${m26Index + 1}*`}
							as={TextField}
							error={
								!!getIn(
									form.errors.installedCorrectingInstalledMeters?.[m24Index],
									`installedMeterRegisters[${m26Index}].registerReading`
								)
							}
							form={form}
						/>
						<FormHelperText
							error={
								!!getIn(
									form.errors.installedCorrectingInstalledMeters?.[m24Index],
									`installedMeterRegisters[${m26Index}].registerReading`
								)
							}
						>
							{getIn(
								form.errors.installedCorrectingInstalledMeters?.[m24Index],
								`installedMeterRegisters[${m26Index}].registerReading`
							)}
						</FormHelperText>
					</FormControl>
					<FormControl>
						<Field
							name={`installedCorrectingInstalledMeters[${m24Index}].installedMeterRegisters[${m26Index}].outstationId`}
							label={`Outstation Id ${m24Index + 1}-${m26Index + 1}`}
							as={TextField}
							error={
								!!getIn(
									form.errors.installedCorrectingInstalledMeters?.[m24Index],
									`installedMeterRegisters[${m26Index}].outstationId`
								)
							}
							form={form}
						/>
						<FormHelperText
							error={
								!!getIn(
									form.errors.installedCorrectingInstalledMeters?.[m24Index],
									`installedMeterRegisters[${m26Index}].outstationId`
								)
							}
						>
							{getIn(
								form.errors.installedCorrectingInstalledMeters?.[m24Index],
								`installedMeterRegisters[${m26Index}].outstationId`
							)}
						</FormHelperText>
					</FormControl>
					<FormControl>
						<Field
							name={`installedCorrectingInstalledMeters[${m24Index}].installedMeterRegisters[${m26Index}].outstationMultiplier`}
							label={`Outstation Multiplier ${m24Index + 1}-${m26Index + 1}`}
							as={TextField}
							error={
								!!getIn(
									form.errors.installedCorrectingInstalledMeters?.[m24Index],
									`installedMeterRegisters[${m26Index}].outstationMultiplier`
								)
							}
							form={form}
						/>
						<FormHelperText
							error={
								!!getIn(
									form.errors.installedCorrectingInstalledMeters?.[m24Index],
									`installedMeterRegisters[${m26Index}].outstationMultiplier`
								)
							}
						>
							{getIn(
								form.errors.installedCorrectingInstalledMeters?.[m24Index],
								`installedMeterRegisters[${m26Index}].outstationMultiplier`
							)}
						</FormHelperText>
					</FormControl>
					<FormControl>
						<Field
							name={`installedCorrectingInstalledMeters[${m24Index}].installedMeterRegisters[${m26Index}].natureOfMaintenance`}
							label={`Nature Of Maintenance ${m24Index + 1}-${m26Index + 1}`}
							as={TextField}
							multiline
							minRows={4}
							maxRows={4}
							error={
								!!getIn(
									form.errors.installedCorrectingInstalledMeters?.[m24Index],
									`installedMeterRegisters[${m26Index}].natureOfMaintenance`
								)
							}
							form={form}
						/>
						<FormHelperText
							error={
								!!getIn(
									form.errors.installedCorrectingInstalledMeters?.[m24Index],
									`installedMeterRegisters[${m26Index}].natureOfMaintenance`
								)
							}
						>
							{getIn(
								form.errors.installedCorrectingInstalledMeters?.[m24Index],
								`installedMeterRegisters[${m26Index}].natureOfMaintenance`
							)}
						</FormHelperText>
					</FormControl>
					<FormControl>
						<Field
							name={`installedCorrectingInstalledMeters[${m24Index}].installedMeterRegisters[${m26Index}].channelNumber`}
							label={`Channel Number ${m24Index + 1}-${m26Index + 1}`}
							as={TextField}
							error={
								!!getIn(
									form.errors.installedCorrectingInstalledMeters?.[m24Index],
									`installedMeterRegisters[${m26Index}].channelNumber`
								)
							}
							form={form}
						/>
						<FormHelperText
							error={
								!!getIn(
									form.errors.installedCorrectingInstalledMeters?.[m24Index],
									`installedMeterRegisters[${m26Index}].channelNumber`
								)
							}
						>
							{getIn(
								form.errors.installedCorrectingInstalledMeters?.[m24Index],
								`installedMeterRegisters[${m26Index}].channelNumber`
							)}
						</FormHelperText>
					</FormControl>
				</Stack>
				<Stack gap={2} flexDirection={"column"} flex={1}>
					<FormControl>
						<Field
							name={`installedCorrectingInstalledMeters[${m24Index}].installedMeterRegisters[${m26Index}].pulseMultiplier`}
							label={`Pulse Multiplier ${m24Index + 1}-${m26Index + 1}`}
							as={TextField}
							error={
								!!getIn(
									form.errors.installedCorrectingInstalledMeters?.[m24Index],
									`installedMeterRegisters[${m26Index}].pulseMultiplier`
								)
							}
							form={form}
						/>
						<FormHelperText
							error={
								!!getIn(
									form.errors.installedCorrectingInstalledMeters?.[m24Index],
									`installedMeterRegisters[${m26Index}].pulseMultiplier`
								)
							}
						>
							{getIn(
								form.errors.installedCorrectingInstalledMeters?.[m24Index],
								`installedMeterRegisters[${m26Index}].pulseMultiplier`
							)}
						</FormHelperText>
					</FormControl>
					<FormControl>
						<Field
							name={`installedCorrectingInstalledMeters[${m24Index}].installedMeterRegisters[${m26Index}].meterRegisterMultiplier`}
							label={`Meter Register Multiplier ${m24Index + 1}-${
								m26Index + 1
							}`}
							as={TextField}
							error={
								!!getIn(
									form.errors.installedCorrectingInstalledMeters?.[m24Index],
									`installedMeterRegisters[${m26Index}].meterRegisterMultiplier`
								)
							}
							form={form}
						/>
						<FormHelperText
							error={
								!!getIn(
									form.errors.installedCorrectingInstalledMeters?.[m24Index],
									`installedMeterRegisters[${m26Index}].meterRegisterMultiplier`
								)
							}
						>
							{getIn(
								form.errors.installedCorrectingInstalledMeters?.[m24Index],
								`installedMeterRegisters[${m26Index}].meterRegisterMultiplier`
							)}
						</FormHelperText>
					</FormControl>
					<FormControl>
						<Field
							name={`installedCorrectingInstalledMeters[${m24Index}].installedMeterRegisters[${m26Index}].numberOfRegisterDigits`}
							label={`Number Of Register Digits ${m24Index + 1}-${
								m26Index + 1
							}`}
							as={TextField}
							error={
								!!getIn(
									form.errors.installedCorrectingInstalledMeters?.[m24Index],
									`installedMeterRegisters[${m26Index}].numberOfRegisterDigits`
								)
							}
							form={form}
						/>
						<FormHelperText
							error={
								!!getIn(
									form.errors.installedCorrectingInstalledMeters?.[m24Index],
									`installedMeterRegisters[${m26Index}].numberOfRegisterDigits`
								)
							}
						>
							{getIn(
								form.errors.installedCorrectingInstalledMeters?.[m24Index],
								`installedMeterRegisters[${m26Index}].numberOfRegisterDigits`
							)}
						</FormHelperText>
					</FormControl>
					<FormControl>
						<Field
							name={`installedCorrectingInstalledMeters[${m24Index}].installedMeterRegisters[${m26Index}].associatedMeterId`}
							label={`Associated Meter Id ${m24Index + 1}-${m26Index + 1}`}
							as={TextField}
							error={
								!!getIn(
									form.errors.installedCorrectingInstalledMeters?.[m24Index],
									`installedMeterRegisters[${m26Index}].associatedMeterId`
								)
							}
							form={form}
						/>
						<FormHelperText
							error={
								!!getIn(
									form.errors.installedCorrectingInstalledMeters?.[m24Index],
									`installedMeterRegisters[${m26Index}].associatedMeterId`
								)
							}
						>
							{getIn(
								form.errors.installedCorrectingInstalledMeters?.[m24Index],
								`installedMeterRegisters[${m26Index}].associatedMeterId`
							)}
						</FormHelperText>
					</FormControl>
					<FormControl>
						<Field
							name={`installedCorrectingInstalledMeters[${m24Index}].installedMeterRegisters[${m26Index}].associatedMeterRegisterId`}
							label={`Associated Meter Register Id ${m24Index + 1}-${
								m26Index + 1
							}`}
							as={TextField}
							error={
								!!getIn(
									form.errors.installedCorrectingInstalledMeters?.[m24Index],
									`installedMeterRegisters[${m26Index}].associatedMeterRegisterId`
								)
							}
							form={form}
						/>
						<FormHelperText
							error={
								!!getIn(
									form.errors.installedCorrectingInstalledMeters?.[m24Index],
									`installedMeterRegisters[${m26Index}].associatedMeterRegisterId`
								)
							}
						>
							{getIn(
								form.errors.installedCorrectingInstalledMeters?.[m24Index],
								`installedMeterRegisters[${m26Index}].associatedMeterRegisterId`
							)}
						</FormHelperText>
					</FormControl>
					<FormControl>
						<InputLabel
							id={`installedCorrectingInstalledMeters[${m24Index}].installedMeterRegisters[${m26Index}].settlementIndicator`}
							error={
								!!getIn(
									form.errors.installedCorrectingInstalledMeters?.[m24Index],
									`installedMeterRegisters[${m26Index}].settlementIndicator`
								)
							}
						>
							{`Settlement Idicator ${m24Index + 1}-${m26Index + 1}`}
						</InputLabel>
						<Field
							name={`installedCorrectingInstalledMeters[${m24Index}].installedMeterRegisters[${m26Index}].settlementIndicator`}
							label={`Settlement Indicator ${m24Index + 1}-${m26Index + 1}`}
							labelId={`installedCorrectingInstalledMeters[${m24Index}].installedMeterRegisters[${m26Index}].settlementIndicator`}
							as={Select}
							form={form}
							error={
								!!getIn(
									form.errors.installedCorrectingInstalledMeters?.[m24Index],
									`installedMeterRegisters[${m26Index}].settlementIndicator`
								)
							}
						>
							{Object.entries(SettlementIndicator).map((val, index) => {
								return (
									<MenuItem
										key={`ihd_install_status_label_${index}`}
										value={val[1]}
									>
										{`${val[0]}`}
									</MenuItem>
								);
							})}
						</Field>
						<FormHelperText
							error={
								!!getIn(
									form.errors.installedCorrectingInstalledMeters?.[m24Index],
									`installedMeterRegisters[${m26Index}].settlementIndicator`
								)
							}
						>
							{getIn(
								form.errors.installedCorrectingInstalledMeters?.[m24Index],
								`installedMeterRegisters[${m26Index}].settlementIndicator`
							)}
						</FormHelperText>
					</FormControl>
					<FormControl>
						<Field
							name={`installedCorrectingInstalledMeters[${m24Index}].installedMeterRegisters[${m26Index}].measurementQuantityId`}
							label={`Measurement Quantity Id ${m24Index + 1}-${m26Index + 1}`}
							as={TextField}
							error={
								!!getIn(
									form.errors.installedCorrectingInstalledMeters?.[m24Index],
									`installedMeterRegisters[${m26Index}].measurementQuantityId`
								)
							}
							form={form}
						/>
						<FormHelperText
							error={
								!!getIn(
									form.errors.installedCorrectingInstalledMeters?.[m24Index],
									`installedMeterRegisters[${m26Index}].measurementQuantityId`
								)
							}
						>
							{getIn(
								form.errors.installedCorrectingInstalledMeters?.[m24Index],
								`installedMeterRegisters[${m26Index}].measurementQuantityId`
							)}
						</FormHelperText>
					</FormControl>
				</Stack>
			</Stack>
			<Button
				type="button"
				variant="outlined"
				color="warning"
				sx={{
					width: "max-content",
					justifyContent: "flex-start",
				}}
				disabled={
					form.values.installedCorrectingInstalledMeters?.[m24Index]
						.installedMeterRegisters?.length === 1
				}
				startIcon={<DeleteIcon />}
				onClick={() => removeM26Group()}
			>
				Remove Installed Meter Registers
			</Button>
			<Divider variant="middle" sx={{ height: "0.5rem" }} />
		</>
	);
};
