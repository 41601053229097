import React, { useContext, useEffect, useState } from "react";

import { Alert, Collapse, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { AlertContext } from "../../utilities";

export const TalosMessage: React.FC = () => {
	const [open, setOpen] = useState(true);
	const { talosAlert } = useContext(AlertContext);

	useEffect(() => {
		setOpen(true);
	}, [talosAlert]);

	return (
		<AlertContext.Consumer>
			{(alertContext) =>
				alertContext.talosAlert.message && (
					<Collapse in={open}>
						<Alert
							data-cy="talos-message"
							sx={{ margin: "20px 0" }}
							variant="filled"
							severity={alertContext.talosAlert.severity}
							action={
								<IconButton
									aria-label="close"
									color="inherit"
									size="small"
									onClick={() => {
										setOpen(false);
									}}
									sx={{
										margin: 0,
										padding: 0,
									}}
								>
									<CloseIcon fontSize="inherit" />
								</IconButton>
							}
						>
							<Typography variant="h3">
								<strong>{alertContext.talosAlert.message}</strong>
							</Typography>
						</Alert>
					</Collapse>
				)
			}
		</AlertContext.Consumer>
	);
};
