import {
	BooleanFieldDescription,
	DateFieldDescription,
	FieldDescription,
	MpanFieldDescription,
	NumericFieldDescription,
	PagePropsWithErrorHandler,
	PagePropsWithErrorMessage,
	PagePropsWithSuccessHandler,
	PagePropsWithSuccessMessage,
	RadioFieldDescription,
	SectionDescription,
	SelectFieldDescription,
	StringFieldDescription,
} from "./form.types";

export const isMpanField = (
	fieldOrSection: SectionDescription | FieldDescription
): fieldOrSection is MpanFieldDescription =>
	fieldOrSection.componentType === "mpan";

export const isBooleanField = (
	fieldOrSection: SectionDescription | FieldDescription
): fieldOrSection is BooleanFieldDescription => {
	return fieldOrSection.componentType === "boolean";
};

export const isDateField = (
	fieldOrSection: SectionDescription | FieldDescription
): fieldOrSection is DateFieldDescription =>
	fieldOrSection.componentType === "date";

export const isNumericField = (
	fieldOrSection: FieldDescription | SectionDescription
): fieldOrSection is NumericFieldDescription =>
	fieldOrSection.componentType === "numeric";

export const isRadioField = (
	fieldOrSection: FieldDescription | SectionDescription
): fieldOrSection is RadioFieldDescription =>
	fieldOrSection.componentType === "radio";

export const isSelectField = (
	fieldOrSection: FieldDescription | SectionDescription
): fieldOrSection is SelectFieldDescription =>
	fieldOrSection.componentType === "select";

export const isStringField = (
	fieldOrSection: FieldDescription | SectionDescription
): fieldOrSection is StringFieldDescription =>
	fieldOrSection.componentType === "string";

export const isSection = (
	fieldOrSection: FieldDescription | SectionDescription
): fieldOrSection is SectionDescription =>
	fieldOrSection.componentType === "section";

const selectOptionLabelForFormat = (
	format: "value" | "key" | "value - key" | "key - value",
	enumValueEntry: [string, unknown]
) => {
	const [key, value] = enumValueEntry;
	switch (format) {
		case "value":
			return `${value}`;
		case "key":
			return key;
		case "value - key":
			return `${value} - ${key}`;
		case "key - value":
			return `${key} - ${value}`;
	}
};

/**
 * Produce options to be displayed as part of a select field from a typescript
 * enum. The enums entries are extracted as key value pairs. The "key" and "value"
 * referred to by the optionDisplayFormat and optionValueSelector refer to these.
 * @param source Enum to produce options for.
 * @param optionDisplayFormat The text of the option to display. This is a
 * format produced by replacing "key" * and "value" with their counterpart from
 * each enum entry.
 * @param optionValueSelector The value for the field should an option be chosen.
 */
export const selectOptionsFromEnum = (
	source: Record<string, unknown>,
	optionDisplayFormat: "value" | "key" | "value - key" | "key - value",
	optionValueSelector: "key" | "value"
) =>
	Object.entries(source).map((enumValueEntry) => ({
		label: selectOptionLabelForFormat(optionDisplayFormat, enumValueEntry),
		value:
			optionValueSelector === "key" ? enumValueEntry[0] : enumValueEntry[1],
	}));

/**
 * Type guard to be used in predicates to determine whether the props param
 * is either `PagePropsWithSuccessHandler` or `PagePropsWithSuccessMessage`.
 * @param props
 */
export const hasSuccessHandler = <T>(
	props: PagePropsWithSuccessHandler<T> | PagePropsWithSuccessMessage<T>
): props is PagePropsWithSuccessHandler<T> =>
	!!(props as PagePropsWithSuccessHandler<T>).onSuccess;

/**
 * Type guard to be used in predicates to determine whether the props param
 * is either `PagePropsWithErrorHandler` or `PagePropsWithErrorMessage`.
 * @param props
 */
export const hasErrorHandler = (
	props: PagePropsWithErrorHandler | PagePropsWithErrorMessage
): props is PagePropsWithErrorHandler =>
	!!(props as PagePropsWithErrorHandler).onError;
