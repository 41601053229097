import React, { Component, ReactNode } from "react";

import { Box, Link, Typography } from "@mui/material";

import { handleError } from "../../utilities";
import { OvoLogo } from "../icons";
import { talosColours } from "../../styles/talos-colors";

interface IProps {
	children?: ReactNode;
}

interface IState {
	hasError: boolean;
	error?: Error;
	errorId?: string;
}

export class ErrorBoundary extends Component<IProps, IState> {
	public state: IState = {
		hasError: false,
	};

	public static getDerivedStateFromError(error: Error): IState {
		const errorId = handleError(error);

		return { hasError: true, error, errorId };
	}

	public render() {
		if (this.state.hasError) {
			return (
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						margin: "5rem auto",
						width: "100%",
						maxWidth: "50%",
						alignItems: "center",
						gap: "2rem",
					}}
				>
					<OvoLogo fill={talosColours.green.main} />
					<Typography variant="h1" sx={{ padding: "1.5rem" }}>
						Something went wrong. Please try reaching the service later or
						contact IOPS Support Error ID: {this.state.errorId}
					</Typography>
					<Link
						href="/"
						sx={{
							cursor: "pointer",
							color: talosColours.green.main,
						}}
					>
						<Typography variant="h3" sx={{ padding: "1.5rem" }}>
							Go Back to main page
						</Typography>
					</Link>
				</Box>
			);
		}

		return this.props.children;
	}
}

export default ErrorBoundary;
