import * as Yup from "yup";

import { usefulRegex } from "../../utilities";

export const validationSchema = Yup.object({
	mpan: Yup.string().matches(
		usefulRegex.MPAN,
		"MPAN must be a 13 digit integer"
	),
	start: Yup.date().typeError("Start date must be a valid date").nullable(),
	end: Yup.date().typeError("Start date must be a valid date").nullable(),
});
