import { formatJavaStyleDateString } from "../../utilities";
import { getMW4Flows } from "../../api/talos";
import {
	formatMeasurementQuantityIdString,
	formatReasonCodeString,
	MW4FlowsResponse,
} from "../../models/talos/mw4-flows";
import { flowsTable } from "../../components/tables/flows-table";
import { PagedRequestsPage } from "../requests-list-page";

const responseKey = (key: string): key is keyof MW4FlowsResponse =>
	[
		"id",
		"flowId",
		"userId",
		"mpan",
		"status",
		"cause",
		"flowType",
		"createdAt",
		"updatedAt",
	].includes(key);

const responseDataKey = (key: string): key is keyof MW4FlowsResponse["data"] =>
	[
		"mpan",
		"measurementQuantityId",
		"consumptionAmendmentSupplierReference",
		"consumptionAmendmentPeriodStartDate",
		"consumptionAmendmentPeriodEndDate",
		"grossRevisedConsumption",
		"supplierConsumptionAmendmentReasonCode",
	].includes(key);

const MW4FlowsTable = flowsTable<MW4FlowsResponse>({
	columns: [
		{ label: "ID", key: "id" },
		{ label: "MPAN", key: "mpan" },
		{
			label: "Measurement Quantity ID",
			key: "measurementQuantityId",
			formatter: formatMeasurementQuantityIdString,
		},
		{
			label: "Consumption Amendment Supplier Reference",
			key: "consumptionAmendmentSupplierReference",
		},
		{
			label: "Consumption Amendment Period Start Date",
			key: "consumptionAmendmentPeriodStartDate",
			formatter: formatJavaStyleDateString,
		},
		{
			label: "Consumption Amendment Period End Date",
			key: "consumptionAmendmentPeriodEndDate",
			formatter: formatJavaStyleDateString,
		},
		{
			label: "Gross Revised Consumption",
			key: "grossRevisedConsumption",
		},
		{
			label: "Supplier Consumption Amendment Reason Code",
			key: "supplierConsumptionAmendmentReasonCode",
			formatter: formatReasonCodeString,
		},
	],
	dataKey: "mw4",
	getId: (response: MW4FlowsResponse) => response.id,
	getColumnContent: (columnKey, response) => {
		// Look up value based on where in the response type the column key exists.
		if (responseKey(columnKey)) return response[columnKey];

		if (responseDataKey(columnKey)) return response.data[columnKey];

		throw Error("Unknown column key");
	},
});

export const MW4Flows = () => (
	<PagedRequestsPage<MW4FlowsResponse>
		title="Consumption Amendment"
		requestsName={"Consumption Amendment"}
		requestsKey={"mw4-flows"}
		getRequests={getMW4Flows}
		RequestsTable={MW4FlowsTable}
		additionLink={"/add-mw4-flow"}
		getRequestsOptions={{}}
	/>
);
