import { NewBookingRequestForm } from "../../components";
import {
	BookingRequest,
	IBookingFormValues,
	RequestType,
} from "../../models/talos";
import { mapFormValuesToRequest } from "../../utilities";
import { addBookingRequest } from "../../api/talos";
import { AdditionPage } from "../addition-page";

const initialValues: IBookingFormValues = {
	requestType: RequestType.ElectricityOnly,
	mpan: "",
	mprn: "",
	elec_msn: "",
	gas_msn: "",
	customerName: "",
	houseNumber: "",
	houseName: "",
	postOutcode: "",
	postIncode: "",
	address: "",
	accessInstructions: "",
	elec_meterLocation: "",
	gas_meterLocation: "",
	specialInstructions: "",
	customerCareDetails: "",
	customerPassword: "",
	gas_registerID: "1",
	gas_registerName: "",
	elec_registerID: "",
	elec_registerName: "ANYTIME",
	elec_secondRegisterID: "",
	elec_secondRegisterName: "",
	jobType: "",
	appointmentDate: "",
	appointmentTime: "",
	earliestReadDate: "",
	latestReadDate: "",
	subRegisters: "N",
};

export const AddBookingRequest = () => (
	<AdditionPage<BookingRequest, IBookingFormValues>
		header="New Booking Request"
		summary="Fill in this form to request a new appointment with MDS. This will add a request to the queue and will go out in the next batch."
		additionFormKey={"add-booking-request"}
		mapFormToValues={mapFormValuesToRequest}
		addInstanceRequest={addBookingRequest}
		AddInstanceForm={NewBookingRequestForm}
		initialValues={initialValues}
		listPage={{
			link: "/booking-requests",
			subject: "bookings",
			title: "Booking Requests",
		}}
		successMessage="Booking Request has been sent successfully"
	/>
);
