import React, { useState } from "react";

import { Box, Typography, useTheme } from "@mui/material";
import { Form, Formik } from "formik";

import { TalosDatePicker, TalosNumberBox, TalosTextBox } from "../forms";
import { readsCalculatorValidationSchema } from "./reads-calculator.validation-schema";
import {
	IReadsCalculatorOutput,
	IReadsCalculatorValues,
	MeterReading,
} from "../../models/talos";
import { getReadsCalculatorOutput } from "../../utilities";

const initialValues: IReadsCalculatorValues = {
	readOneDate: new Date(2023, 0, 1),
	readOneRegOne: 0,
	readOneRegTwo: 0,
	readTwoDate: new Date(2023, 11, 31),
	readTwoRegOne: 0,
	readTwoRegTwo: 0,
	ssd: new Date(2023, 6, 2),
};

export const ReadsCalculator: React.FC = () => {
	const [calculation, setCalculation] = useState<
		IReadsCalculatorOutput | undefined
	>(undefined);

	const theme = useTheme();

	function handleChange(values: IReadsCalculatorValues): void {
		const estimationDate = new Date(values.ssd);
		const read_one: MeterReading = {
			register_one: Number(values.readOneRegOne),
			register_two: Number(values.readOneRegTwo),
			date: values.readOneDate,
		};
		const read_two: MeterReading = {
			register_one: Number(values.readTwoRegOne),
			register_two: Number(values.readTwoRegTwo),
			date: values.readTwoDate,
		};

		const result = getReadsCalculatorOutput(read_one, read_two, estimationDate);

		setCalculation(result);
	}

	return (
		<Box>
			<Formik
				onSubmit={() => {}}
				initialValues={initialValues}
				validationSchema={readsCalculatorValidationSchema}
				validate={handleChange}
			>
				{(form) => (
					<Form>
						<Box
							className="form-wrapper "
							sx={{
								flexDirection: { xs: "column", sm: "column", lg: "row" },
								gap: theme.spacing(5),
								alignItems: "flex-start",
							}}
						>
							<Box
								className="form-column"
								sx={{ maxWidth: { xs: "100%", md: "300px" }, flexGrow: "1" }}
							>
								<Typography variant="h6">1st Reading</Typography>
								<TalosDatePicker
									fieldName="readOneDate"
									label="Date 1"
									form={form}
								/>
								<TalosNumberBox
									fieldName="readOneRegOne"
									label="Reg 1"
									form={form}
								/>
								<TalosNumberBox
									fieldName="readOneRegTwo"
									label="Reg 2"
									form={form}
								/>
							</Box>
							<Box
								className="form-column"
								sx={{ maxWidth: { xs: "100%", md: "300px" }, flexGrow: "1" }}
							>
								<Typography variant="h6">2nd Reading</Typography>
								<TalosDatePicker
									fieldName="readTwoDate"
									label="Date 2"
									form={form}
								/>
								<TalosNumberBox
									fieldName="readTwoRegOne"
									label="Reg 1"
									form={form}
								/>
								<TalosNumberBox
									fieldName="readTwoRegTwo"
									label="Reg 2"
									form={form}
								/>
							</Box>
							<Box
								className="form-column"
								sx={{ maxWidth: { xs: "100%", md: "300px" }, flexGrow: "1" }}
							>
								<Typography variant="h6">Estimate</Typography>
								<TalosDatePicker fieldName="ssd" label="SSD" form={form} />
								<TalosTextBox
									fieldName="estimatedRegOne"
									label="Reg 1"
									overrideValue={calculation?.estimate?.register_one?.toString()}
									form={form}
									sx={theme.customSX.darkTextBox}
									disabled={true}
								/>
								<TalosTextBox
									fieldName="estimatedRegTwo"
									overrideValue={calculation?.estimate?.register_two?.toString()}
									label="Reg 2"
									form={form}
									sx={theme.customSX.darkTextBox}
									disabled={true}
								/>
							</Box>
							<Box
								className="form-column"
								sx={{ maxWidth: { xs: "100%", md: "300px" }, flexGrow: "1" }}
							>
								<Typography variant="h6">New EAC</Typography>
								<TalosTextBox
									fieldName="eacRegOne"
									label="Reg 1"
									form={form}
									overrideValue={calculation?.eac?.register_one?.toString()}
									sx={theme.customSX.darkTextBox}
									disabled={true}
								/>
								<TalosTextBox
									fieldName="eacRegTwo"
									label="Reg 2"
									form={form}
									overrideValue={calculation?.eac?.register_two?.toString()}
									sx={theme.customSX.darkTextBox}
									disabled={true}
								/>
							</Box>
						</Box>
						<Box sx={{ maxWidth: { xs: "100%" }, flexGrow: "2" }}>
							<Typography variant="h6" sx={{ textAlign: "center" }}>
								P1
							</Typography>
							<Typography
								variant="body1"
								className="text-result"
								sx={{
									...theme.customSX.darkTextBox,
									border: "1px solid #999",
									width: "100%",
									padding: "15px 0 0 0",
									textAlign: "center",
									minHeight: "50px",
								}}
							>
								{calculation?.P1}
							</Typography>
							<Typography variant="h6" sx={{ textAlign: "center" }}>
								P2
							</Typography>
							<Typography
								className="text-result"
								variant="body1"
								sx={{
									...theme.customSX.darkTextBox,
									border: "1px solid #999",
									width: "100%",
									padding: "15px 0 0 0",
									textAlign: "center",
									minHeight: "50px",
								}}
							>
								{calculation?.P2}
							</Typography>
						</Box>
					</Form>
				)}
			</Formik>
		</Box>
	);
};
