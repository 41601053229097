import React from "react";

import dayjs from "dayjs";
import { Form, Formik } from "formik";
import { Box, MenuItem } from "@mui/material";
import { UseMutationResult } from "react-query";

import { IBookingFormValues, RequestType } from "../../models/talos";
import {
	TalosDatePicker,
	TalosDropDown,
	TalosRadioGroup,
	TalosTextBox,
	TalosButton,
} from "..";
import {
	getJobTypes,
	hasSubRegisters,
	is2HourAppointment,
	isAdhoc,
	isDual,
	isElec,
	isGas,
	isTimeSlotAppointment,
} from "../../utilities";
import { formValidationSchema } from "./add-booking-request-form.validation-schema";
interface IProps {
	formSubmitMutation: UseMutationResult<Boolean, Error, IBookingFormValues>;
	formData: IBookingFormValues;
}

export const AddBookingRequestForm: React.FC<IProps> = ({
	formSubmitMutation: bookingRequestSubmitMutation,
	formData,
}) => {
	const getTimeSlotOptions = (values: IBookingFormValues) => {
		const hourlySlots = is2HourAppointment(values)
			? [
					"08:00 - 10:00",
					"10:00 - 12:00",
					"12:00 - 14:00",
					"14:00 - 16:00",
					"16:00 - 18:00",
					"18:00 - 20:00",
			  ].map((timeSlot, idx) => (
					<MenuItem key={`time_slot_${idx}`} value={timeSlot}>
						{timeSlot}
					</MenuItem>
			  ))
			: [];

		const timeSlots = isTimeSlotAppointment(values)
			? ["All Day", "AM", "PM", "EVE"].map((timeSlot, idx) => (
					<MenuItem key={`am_pm_slot_${idx}`} value={timeSlot}>
						{timeSlot}
					</MenuItem>
			  ))
			: [];

		return hourlySlots.concat(timeSlots);
	};

	const onSubmit = (values: IBookingFormValues) =>
		bookingRequestSubmitMutation.mutate(values);

	return (
		<Formik
			onSubmit={onSubmit}
			initialValues={formData}
			validationSchema={formValidationSchema}
			validateOnChange={true}
		>
			{(form) => (
				<Form>
					<Box className="form-wrapper">
						<Box className="form-column">
							<h4>Booking Type</h4>
							<TalosRadioGroup
								form={form}
								fieldName="requestType"
								postOnChange={(event) => {
									form.resetForm({
										values: {
											...formData,
											requestType: Number(event.currentTarget.value),
										},
									});
								}}
								options={[
									{
										value: RequestType.ElectricityOnly.toString(),
										label: "Electricity Only",
									},
									{
										value: RequestType.GasOnly.toString(),
										label: "Gas Only",
									},
									{
										value: RequestType.Dual.toString(),
										label: "Dual Fuel Booking",
									},
								]}
							/>
						</Box>
						<Box className="form-column">
							{(isGas(form.values) || isDual(form.values)) && (
								<>
									<h4>Gas Meter Details</h4>
									<TalosTextBox fieldName="mprn" label="MPRN*" form={form} />
									<TalosTextBox
										fieldName="gas_msn"
										label={`${
											isDual(form.values) ? "Gas " : ""
										}Meter Serial No.*`}
										form={form}
									/>
									<TalosTextBox
										fieldName="gas_registerID"
										label={`${isDual(form.values) ? "Gas " : ""}Register ID*`}
										form={form}
									/>
									<TalosTextBox
										fieldName="gas_meterLocation"
										label={`${isDual(form.values) ? "Gas " : ""}Meter Location`}
										form={form}
									/>
								</>
							)}

							{(isElec(form.values) || isDual(form.values)) && (
								<>
									<h4>Electric Meter Details</h4>
									<TalosTextBox fieldName="mpan" label="MPAN*" form={form} />
									<TalosTextBox
										fieldName="elec_msn"
										label={`${
											isDual(form.values) ? "Elec " : ""
										}Meter Serial No.*`}
										form={form}
									/>
									<TalosTextBox
										fieldName="elec_meterLocation"
										label={`${
											isDual(form.values) ? "Elec " : ""
										}Meter Location`}
										form={form}
									/>
									<h4>Does the meter have a second register?</h4>
									<TalosRadioGroup
										form={form}
										fieldName="subRegisters"
										options={[
											{
												value: "Y",
												label: "Yes",
											},
											{
												value: "N",
												label: "No",
											},
										]}
										postOnChange={(evt, value) => {
											if (value === "N") {
												form.setFieldValue("elec_registerName", "ANYTIME");
											}
											if (value === "Y") {
												form.setFieldValue("elec_registerName", "");
											}
										}}
									/>
									<TalosTextBox
										fieldName="elec_registerID"
										label={`${isDual(form.values) ? "Elec " : ""}Register ID*`}
										form={form}
									/>
									<TalosDropDown
										fieldName={"elec_registerName"}
										label={`${
											isDual(form.values) ? "Elec " : ""
										}Register Name*`}
										form={form}
										disabled={form.values["subRegisters"] === "N"}
										menuItems={["ANYTIME", "DAY", "NIGHT"].map((opt, idx) => (
											<MenuItem
												key={`opt_${idx}`}
												value={opt}
												data-testid={`opt-${idx}`}
											>
												{opt}
											</MenuItem>
										))}
										postOnChange={(e, val) => {
											if (form.values["subRegisters"] === "Y") {
												if (val.toLowerCase().includes("day")) {
													form.setFieldValue(
														"elec_secondRegisterName",
														"NIGHT"
													);
												} else if (val.toLowerCase().includes("night")) {
													form.setFieldValue("elec_secondRegisterName", "DAY");
												}
											}
										}}
									/>
									{hasSubRegisters(form.values) && (
										<>
											<TalosTextBox
												fieldName="elec_secondRegisterID"
												label="Second Register ID"
												form={form}
											/>
											<TalosDropDown
												fieldName={"elec_secondRegisterName"}
												label={"Second Register Name"}
												form={form}
												menuItems={["DAY", "NIGHT"].map((opt, idx) => (
													<MenuItem
														key={`opt_${idx}`}
														value={opt}
														data-testid={`opt-${idx}`}
													>
														{opt}
													</MenuItem>
												))}
											/>
										</>
									)}
								</>
							)}
						</Box>
						<Box className="form-column">
							<h3>Customer Information</h3>
							<TalosTextBox
								fieldName="customerName"
								label="Customer Full Name*"
								form={form}
							/>
							<TalosTextBox
								fieldName="houseNumber"
								label="House Number*"
								form={form}
							/>
							<TalosTextBox
								fieldName="houseName"
								label="House Name"
								form={form}
							/>
							<TalosTextBox
								fieldName="address"
								label="First Line of Address*"
								form={form}
							/>
							<Box sx={{ display: "flex", gap: "10px" }}>
								<TalosTextBox
									fieldName="postOutcode"
									label="Post Outcode (i.e. BS1)*"
									form={form}
								/>
								<TalosTextBox
									fieldName="postIncode"
									label="Post Incode (i.e. 6ED)*"
									form={form}
								/>
							</Box>
							<TalosTextBox
								fieldName="accessInstructions"
								label="Access Instructions"
								form={form}
							/>
							<TalosTextBox
								fieldName="specialInstructions"
								label="Special Instructions"
								form={form}
							/>
							<TalosTextBox
								fieldName="customerCareDetails"
								label="Customer Care Details"
								form={form}
							/>
							<TalosTextBox
								fieldName="customerPassword"
								label="Customer Password"
								form={form}
							/>
						</Box>
						<Box className="form-column">
							<h3>Appointment Details</h3>
							<TalosDropDown
								fieldName={"jobType"}
								label={"Job Type*"}
								form={form}
								menuItems={getJobTypes(form.values).map((jobType, idx) => (
									<MenuItem
										key={`job_type_${idx}`}
										value={jobType}
										data-testid={`job-type-option-${idx}`}
									>
										{jobType}
									</MenuItem>
								))}
								postOnChange={(e, val) => {
									if (val.toLowerCase().includes("photo")) {
										form.setFieldValue(
											"specialInstructions",
											`${"Please take a clear photo of the meter showing the reads and the meter serial number."} ${
												form.values.specialInstructions
											}`
										);
									}
								}}
							/>

							{isAdhoc(form.values.jobType) && (
								<>
									<TalosDatePicker
										fieldName={"earliestReadDate"}
										label={"Earliest Read Date*"}
										form={form}
										disableSundays={true}
										disablePast={true}
										minDate={dayjs().add(6, "day").toDate()}
									/>
									{form.values.earliestReadDate && (
										<TalosDatePicker
											fieldName="latestReadDate"
											label="Latest Read Date*"
											form={form}
											disableSundays={true}
											disablePast={true}
											minDate={dayjs(form.values.earliestReadDate)
												.add(12, "day")
												.toDate()}
										/>
									)}
								</>
							)}
							{form.values.jobType && !isAdhoc(form.values.jobType) && (
								<>
									<TalosDatePicker
										fieldName="appointmentDate"
										label="Appointment Date"
										form={form}
										disableHolidays={true}
										disablePast={true}
										minDate={dayjs().add(6, "day").toDate()}
									/>

									{(is2HourAppointment(form.values) ||
										isTimeSlotAppointment(form.values)) && (
										<TalosDropDown
											fieldName="appointmentTime"
											form={form}
											label="Appointment Time*"
											menuItems={getTimeSlotOptions(form.values)}
										/>
									)}
								</>
							)}
							<TalosButton
								fieldName="form_submit"
								form={form}
								buttonText="Submit"
								loading={bookingRequestSubmitMutation.isLoading}
							/>
						</Box>
					</Box>
				</Form>
			)}
		</Formik>
	);
};
