import React from "react";

import { format } from "date-fns";

import { addMW2FlowRequest } from "../../api/talos";
import { IFlowFormValues, MW2FlowRequest } from "../../models/talos/mw2-flows";
import { AddMW2FlowForm } from "../../components/mw2";
import { AdditionPage } from "../addition-page";

const initialValues: IFlowFormValues = {
	mpan: "",
	supplierNotificationType: "",
	supplierNotificationEffectiveFromDate: "",
	supplierNotificationExpiryDate: "",
	meterIdSerialNumber: "",
	additionalInformation: "",
	supplierNominatedAnnualConsumption: "",
};

const mapFormToValues = (values: IFlowFormValues): MW2FlowRequest => {
	return {
		...values,
		meterIdSerialNumber: mapMeterIdSerialNumber(values),
		additionalInformation:
			values.additionalInformation === "" ? null : values.additionalInformation,
		supplierNotificationEffectiveFromDate:
			values.supplierNotificationEffectiveFromDate
				? format(
						new Date(values.supplierNotificationEffectiveFromDate),
						"yyyy-MM-dd"
				  )
				: "",
		supplierNotificationExpiryDate: values.supplierNotificationExpiryDate
			? format(new Date(values.supplierNotificationExpiryDate), "yyyy-MM-dd")
			: null,
		supplierNominatedAnnualConsumption: mapSNAC(values),
	};
};

const mapMeterIdSerialNumber = (values: IFlowFormValues): string | null => {
	if (
		values.supplierNotificationType === "SNRD" ||
		values.supplierNotificationType === "SNRE"
	)
		return values.meterIdSerialNumber;
	else return null;
};

const mapSNAC = (values: IFlowFormValues): string | null => {
	if (values.supplierNotificationType === "SNAC")
		return values.supplierNominatedAnnualConsumption;
	else return null;
};

export const AddMW2Flow: React.FC = () => (
	<AdditionPage<MW2FlowRequest, IFlowFormValues>
		additionFormKey={"add-mw2-flow"}
		additionInstanceName="Consumption Update"
		underlyingFlowName="S0003_MW2"
		mapFormToValues={mapFormToValues}
		addInstanceRequest={addMW2FlowRequest}
		AddInstanceForm={AddMW2FlowForm}
		initialValues={initialValues}
		listPage={{
			link: "/mw2-flows",
			subject: "consumption updates",
			title: "Consumption Update Requests",
		}}
	/>
);
