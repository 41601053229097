import { useMemo, useState } from "react";

import { subDays } from "date-fns";

import { getBookingRequests, processFilterParams } from "../../api/talos";
import { BookingRequestsTable } from "../../components";
import {
	BookingRequestResponse,
	BookingRequestsFilter,
} from "../../models/talos";
import { PagedRequestsPage } from "../requests-list-page";
import { SearchByDateFilter } from "../../components/filters/search-by-date-filter";
import { BookingRequestsInstructions } from "../../components/cmr/booking-request-instructions";

export const BookingRequests = () => {
	const [filterState, setFilterState] = useState<BookingRequestsFilter>({
		start: subDays(new Date(), 4),
		end: new Date(),
	});
	const { start, end } = filterState;
	const searchParams = useMemo(
		() =>
			processFilterParams({
				fields: { unsentOnly: false },
				dates: { startDate: start, endDate: end },
			}),
		[start, end]
	);

	return (
		<PagedRequestsPage<BookingRequestResponse>
			title="Booking Requests"
			requestsName={"Booking Requests"}
			requestsKey={"booking-requests"}
			getRequests={getBookingRequests}
			RequestsTable={BookingRequestsTable}
			additionLink={"/new-booking-request"}
			getRequestsOptions={searchParams}
			requestsOptions={
				<SearchByDateFilter
					handleSubmit={setFilterState}
					initialValues={filterState}
				/>
			}
		>
			<BookingRequestsInstructions />
		</PagedRequestsPage>
	);
};
