import { ChangeEvent, useContext, useEffect, useState } from "react";

import { useMutation } from "react-query";
import { Box, Button, Link, Typography } from "@mui/material";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import CancelIcon from "@mui/icons-material/Cancel";
import { AxiosError } from "axios";

import { AuthContext } from "../../auth";
import { uploadMigrationRegistrations } from "../../api/themis/themis-api";
import { AlertContext, handleError } from "../../utilities";

export const MigrationUpload: React.FC = () => {
	const [csvFile, setCsvFile] = useState<File>();

	const { setTalosAlert } = useContext(AlertContext);
	const authContext = useContext(AuthContext);

	const handleFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
		if (!e.target.files) {
			return;
		}

		const file = e.target.files[0];
		setCsvFile(file);

		e.target.value = "";
	};

	const handleCancelClick = () => {
		setCsvFile(undefined);
	};

	const uploadMigrationRegistrationsMutation = useMutation(
		(csvFile: File) => {
			return uploadMigrationRegistrations(authContext, csvFile, {
				sourceType: "SSE",
			});
		},
		{
			onSuccess: () => {
				setTalosAlert({
					message: "Successfully uploaded file.",
					severity: "success",
				});
			},
			onError: (e: AxiosError) => {
				const errorId = handleError(e);

				let message = "";
				if (e.response?.status === 400)
					message = "Wrong file format. Please try again.";
				else
					message = `Something went wrong uploading file. Please contact IOPS Support, Ticket ID: ${errorId}`;

				setTalosAlert({
					message: message,
					severity: "error",
				});
				// Scroll to the top of the page, so the user can see error message
				scrollTo(0, 0);
			},
		}
	);

	useEffect(() => {
		if (uploadMigrationRegistrationsMutation.isSuccess) setCsvFile(undefined);
	}, [uploadMigrationRegistrationsMutation.isSuccess]);

	return (
		<Box sx={{ width: "600px", height: "300px", margin: "0 auto;" }}>
			<Box display={"flex"} flexDirection={"column"} gap={2}>
				<Typography variant="h1">
					Upload a csv file to start migration registrations.
				</Typography>
				<Typography variant="body1" marginBottom={7}>
					To see registrations go to the{" "}
					<Link href={"/export/registrations"}>View Registrations</Link> page.
				</Typography>
			</Box>
			<Box display={"flex"} gap={2}>
				<Button
					component="label"
					variant="contained"
					startIcon={<UploadFileIcon />}
				>
					Upload CSV
					<input type="file" accept=".csv" hidden onChange={handleFileUpload} />
				</Button>

				<Button
					variant="outlined"
					disabled={!csvFile || uploadMigrationRegistrationsMutation.isLoading}
					onClick={() => uploadMigrationRegistrationsMutation.mutate(csvFile!)}
				>
					Migrate
				</Button>
			</Box>

			<Box marginTop={3} display={"flex"} flexDirection={"column"}>
				<Typography variant="h3">File ready for migration: </Typography>
				<Box display={"flex"} alignItems={"center"}>
					<Typography variant="h3">
						{csvFile ? csvFile.name : "none"}
					</Typography>
					{csvFile && (
						<Button startIcon={<CancelIcon />} onClick={handleCancelClick} />
					)}
				</Box>
			</Box>
		</Box>
	);
};
