import React, { useContext, useEffect } from "react";

import { useFormikContext } from "formik";
import { subMonths, format } from "date-fns";

import { API_TYPES, getRecentFVRRequests } from "../../api/talos";
import { generateFormPage, selectOptionsFromEnum } from "../../form-generator";
import { COMMON_FIELDS } from "../../form-generator/form-component.generator";
import { AlertContext } from "../../utilities";
import {
	AddFVRequest,
	FVRFormValues,
	FVRReadType,
} from "../../models/talos/force-validation-reads";
import { AuthContext } from "../../auth";

const READ_DATE_WITHIN_LAST_14_MONTHS_ERROR =
	"Read date must be within the last 14 months";

const mapFormToValues = ({
	mpan,
	msn,
	readDate,
	readType,
	reg1Id,
	reg1Read,
	reg2Id,
	reg2Read,
	hasSecondRegister,
}: FVRFormValues): AddFVRequest => {
	const minimumRequest = {
		mpan: Number(mpan),
		msn: msn,
		readType,
		readDate: format(readDate, "yyyy-MM-dd"),
		reg1Data: { regId: reg1Id, read: reg1Read },
	};

	return hasSecondRegister === "yes" &&
		reg2Id != undefined &&
		reg2Read != undefined
		? {
				...minimumRequest,
				reg2Data: { regId: reg2Id, read: reg2Read },
		  }
		: minimumRequest;
};

const minimumReadDate = () => subMonths(new Date(), 14);

export const AddFVRRequest = generateFormPage<AddFVRequest, FVRFormValues>(
	{
		formKey: "add-fvr-request",
		title: "Force Validate Read",
		mapFormToValues,
		fields: [
			{
				componentType: "section",
				fields: [
					COMMON_FIELDS.MPAN,
					{
						...COMMON_FIELDS.MSN,
						required: true,
					},
					{
						label: "Read Date",
						fieldName: "readDate",
						componentType: "date",
						required: true,
						// Read date must be in the past 14 months.
						datePickerProps: {
							disableFuture: true,
							minDate: minimumReadDate(),
						},
						customValidation: (schema) =>
							schema
								.min(minimumReadDate(), READ_DATE_WITHIN_LAST_14_MONTHS_ERROR)
								.max(new Date(), READ_DATE_WITHIN_LAST_14_MONTHS_ERROR),
					},
					{
						label: "Read Type",
						fieldName: "readType",
						componentType: "select",
						required: true,
						options: selectOptionsFromEnum(FVRReadType, "key - value", "key"),
					},
					{
						label: "Register 1 ID",
						fieldName: "reg1Id",
						componentType: "string",
						required: true,
						minLength: 1,
						maxLength: 2,
					},
					{
						...COMMON_FIELDS.REGISTER_READ,
						label: "Register 1 Read",
						fieldName: "reg1Read",
						required: true,
					},
					{
						label: "Second register?",
						fieldName: "hasSecondRegister",
						componentType: "radio",
						required: true,
						defaultValue: "no",
						options: [
							{ label: "Yes", value: "yes" },
							{ label: "No", value: "no" },
						],
					},
				],
			},
			{
				componentType: "section",
				title: "Register 2",
				excludeFromForm: (values) => values["hasSecondRegister"] != "yes",
				fields: [
					{
						label: "Register 2 ID",
						fieldName: "reg2Id",
						componentType: "string",
						required: true,
						minLength: 1,
						maxLength: 2,
					},
					{
						...COMMON_FIELDS.REGISTER_READ,
						label: "Register 2 Read",
						fieldName: "reg2Read",
						required: true,
					},
				],
			},
		],
		listPage: {
			subject: "force validate read requests",
			title: "Force Validate Read Requests",
			link: "/force-validate-read-requests",
		},
		FormWrapper: ({ children }: { children: React.ReactNode }) => {
			const { values } = useFormikContext<AddFVRequest>();
			const mpan = values.mpan.toString();
			const { setTalosAlert } = useContext(AlertContext);
			const authContext = useContext(AuthContext);

			useEffect(() => {
				let ignore = false;
				if (mpan.length === 13 && !ignore) {
					getRecentFVRRequests(authContext, mpan, 5)
						.then((recentRequests) => {
							if (recentRequests.page.totalItems > 0 && !ignore) {
								const mostRecent = recentRequests.items[0];
								setTalosAlert({
									message: `A request was already made against MPAN ${mpan} on ${mostRecent.createdAt} for read type ${mostRecent.readType}`,
									severity: "warning",
								});
							}
						})
						.catch((e) =>
							console.debug("Exception retrieving recent FVR Requests", e)
						);
				}
				return () => {
					ignore = true;
				};
			}, [authContext, mpan, setTalosAlert]);

			return <>{children}</>;
		},
	},
	{ apiType: API_TYPES.SETTLEMENTS_READS, path: "fvr" }
);
