import { AxiosResponse } from "axios";
import { Field, Form, Formik } from "formik";
import {
	Button,
	FormControl,
	FormHelperText,
	InputLabel,
	MenuItem,
	Select,
	Stack,
} from "@mui/material";

import { AdditionFormProps } from "../../form-generator";
import { IUpdateIHDStatus } from "../../models/metering";
import { IHDStatus } from "../../models/metering/common";
import { updateIHDStatusFormValidationSchema } from "../metering/change-of-registration-data";
import { TalosMpanField } from "../forms/talos-mpan-field";
import { ThemisDateField } from "../forms/themis-date-field";

export const UpdateIHDStatusForm = ({
	formSubmitMutation,
	formData,
}: AdditionFormProps<IUpdateIHDStatus, AxiosResponse>) => (
	<Formik
		onSubmit={(values: IUpdateIHDStatus, { resetForm }) => {
			return formSubmitMutation.mutate(values, {
				onSuccess: () => resetForm(),
			});
		}}
		initialValues={formData}
		validationSchema={updateIHDStatusFormValidationSchema}
		validateOnChange
		enableReinitialize
	>
		{(form) => (
			<Form>
				<Stack gap={2}>
					<TalosMpanField label={"MPAN*"} form={form} fieldName={"mpan"} />
					<FormControl>
						<InputLabel id={"ihd_status_label"} error={!!form.errors.ihdStatus}>
							{"IHD Status*"}
						</InputLabel>
						<Field
							name="ihdStatus"
							label="IHD Status*"
							labelId={"ihd_status_label"}
							as={Select}
							form={form}
							error={!!form.errors.ihdStatus}
						>
							{Object.entries(IHDStatus).map((val, index) => {
								return (
									<MenuItem key={`ihd_status_${index}`} value={val[1].value}>
										{val[1].view}
									</MenuItem>
								);
							})}
						</Field>
						<FormHelperText error={!!form.errors.ihdStatus}>
							{form.errors.ihdStatus}
						</FormHelperText>
					</FormControl>
					{form.values.ihdStatus && (
						<ThemisDateField
							value={form.values.ihdStatusEffectiveFromDate!!}
							name="ihdStatusEffectiveFromDate"
							label="IHD Status Effective From Date"
							helperText={form.errors.ihdStatusEffectiveFromDate}
							error={!!form.errors.ihdStatusEffectiveFromDate}
							form={form}
						/>
					)}
				</Stack>
				<Stack direction={"row"} gap={2} marginTop={2}>
					<Button
						variant="contained"
						type="submit"
						disabled={!form.isValid || formSubmitMutation.isLoading}
					>
						Submit
					</Button>
				</Stack>
			</Form>
		)}
	</Formik>
);
