import { IChangeReadFormValues } from "../models/talos";

export const getTotalOfAllCorrectReads = (values: IChangeReadFormValues) => {
	const incorrectValOne =
		values.firstRegisterCorrectRead !== ""
			? values.firstRegisterCorrectRead
			: 0;
	const incorrectValTwo =
		values.secondRegisterCorrectRead !== ""
			? values.secondRegisterCorrectRead
			: 0;

	return incorrectValOne + incorrectValTwo;
};

export const getTotalOfAllIncorrectReads = (values: IChangeReadFormValues) => {
	const incorrectValOne =
		values.firstRegisterIncorrectRead !== ""
			? values.firstRegisterIncorrectRead
			: 0;
	const incorrectValTwo =
		values.secondRegisterIncorrectRead !== ""
			? values.secondRegisterIncorrectRead
			: 0;

	return incorrectValOne + incorrectValTwo;
};

export const getDifferenceBetweenFirstIncorrectAndCorrectReads = (
	values: IChangeReadFormValues
) => {
	const valOne =
		values.firstRegisterIncorrectRead !== ""
			? values.firstRegisterIncorrectRead
			: 0;
	const valTwo =
		values.firstRegisterCorrectRead !== ""
			? values.firstRegisterCorrectRead
			: 0;

	return valOne - valTwo;
};

export const getDifferenceBetweenSecondIncorrectAndCorrectReads = (
	values: IChangeReadFormValues
) => {
	const valOne =
		values.secondRegisterIncorrectRead !== ""
			? values.secondRegisterIncorrectRead
			: 0;
	const valTwo =
		values.secondRegisterCorrectRead !== ""
			? values.secondRegisterCorrectRead
			: 0;

	return valOne - valTwo;
};

/**
 * The total of the correct reads and the total of the incorrect reads should be more than 250 units apart.
 * @param values the Chagne read form
 * @returns true is the parameters are incorrect.
 */
export const correctAndIncorrectReadingsAreTooCloseTogether = (
	values: IChangeReadFormValues
) => {
	const sumCorrectReads = getTotalOfAllCorrectReads(values);
	const sumIncorrectReads = getTotalOfAllIncorrectReads(values);
	const diff = sumIncorrectReads - sumCorrectReads;

	return diff >= -250 && diff <= 250;
};

export const roundToFirstDecimal = (num: number) => {
	return Math.round(num * 10) / 10;
};

export const mapChangeReadsStatuses = (status: string) => {
	switch (status) {
		case "WITHDRAWN_SUBMITTED":
			return "WITHDRAWN SUBMITTED";
		case "WITHDRAWN_SUBMISSION_FAILED":
			return "WITHDRAWN SUBMISSION FAILED";
		case "WITHDRAWN_RECEIVED":
			return "WITHDRAWN RECEIVED";
		case "CORRECTED_SUBMITTED":
			return "CORRECTED SUBMITTED";
		case "CORRECTED_SUBMISSION_FAILED":
			return "CORRECTED SUBMISSION FAILED";
		default:
			return status;
	}
};
