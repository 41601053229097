import React, { useState } from "react";

import { Tooltip } from "@mui/material";

interface ExpandableProps {
	smallTemplate: React.ReactNode;
	expandedTemplate: React.ReactNode;
	dataCy: string;
}

export const ExpandableComponent: React.FC<ExpandableProps> = ({
	smallTemplate,
	expandedTemplate,
	dataCy,
}) => {
	const [expanded, setExpanded] = useState(false);

	const toggleExpanded = () => {
		setExpanded((prevExpanded) => !prevExpanded);
	};

	return (
		<Tooltip
			title={expanded ? "Click to shrink" : "Click to expand"}
			placement="bottom-end"
			arrow
		>
			<div
				style={{
					cursor: "pointer",
				}}
				onClick={toggleExpanded}
				data-cy={dataCy}
			>
				{expanded ? expandedTemplate : smallTemplate}
			</div>
		</Tooltip>
	);
};
