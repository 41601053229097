export enum EnergisationStatus {
	"De-Energised" = "D",
	"Energised" = "E",
}

export enum MeterLocation {
	A = "A",
	B = "B",
	C = "C",
	D = "D",
	E = "E",
	F = "F",
	G = "G",
	H = "H",
	I = "I",
	J = "J",
	K = "K",
	L = "L",
	M = "M",
	N = "N",
	O = "O",
	P = "P",
	R = "R",
	S = "S",
	T = "T",
	U = "U",
	V = "V",
	W = "W",
	X = "X",
	Y = "Y",
	Z = "Z",
}

//used for /mop04 and /ihd-status requests
export const IHDStatus = {
	INSTALLED: {
		view: "INSTALLED - I",
		value: "INSTALLED",
	},
	DECLINED: {
		view: "DECLINED - D",
		value: "DECLINED",
	},
	EXISTING: {
		view: "EXISTING - E",
		value: "EXISTING",
	},
	FAILED: {
		view: "FAILED - F",
		value: "FAILED",
	},
} as const;

export const IHDStatusNullable = {
	...IHDStatus,
	None: {
		view: "None",
		value: undefined,
	},
} as const;
