import { FC, useContext } from "react";

import { useMutation } from "react-query";
import { AxiosError } from "axios";
import {
	Box,
	Button,
	MenuItem,
	Stack,
	TextField,
	Typography,
} from "@mui/material";
import { Field, Form, Formik } from "formik";

import { AuthContext } from "../../../auth";
import { requestInstallRemoveMeter } from "../../../api/metering";
import {
	AlertContext,
	handleError,
	mapInstallRemoveMeterFormValuesToRequest,
} from "../../../utilities";
import {
	IInstallRemoveMeter,
	RetrievalMethod,
	initialInstallRemoveMeterFormValues,
} from "../../../models/metering";
import { requestInstallRemoveMeterFormValidationSchema } from "../../../components/metering/change-of-registration-data";
import {
	OutboundTimeField,
	TalosDateField,
	TalosDropDown,
	TalosRadioGroup,
	TalosTextBox,
} from "../../../components";
import { EnergisationStatus } from "../../../models/metering/common";

export const RequestInstallRemoveMeter: FC = () => {
	const authContext = useContext(AuthContext);
	const { setTalosAlert } = useContext(AlertContext);

	const requestInstallRemoveMeterMutation = useMutation(
		(installRemoveMeter: IInstallRemoveMeter) =>
			requestInstallRemoveMeter(authContext, installRemoveMeter),
		{
			onSuccess: () => {
				setTalosAlert({
					message: "Successfully send request.",
					severity: "success",
				});
			},
			onError: (e: AxiosError) => {
				if (e.response?.status === 400) {
					setTalosAlert({
						message: `Validation failed: ${e.response.data}`,
						severity: "error",
					});
				} else {
					const errorId = handleError(e);
					setTalosAlert({
						message: `Something went wrong while sending request. Please contact IOPS Support, Ticket ID: ${errorId}`,
						severity: "error",
					});
				}
			},
			onSettled: () => {
				// Scroll to the top of the page, so the user can see messages
				scrollTo(0, 0);
			},
		}
	);

	return (
		<Box sx={{ width: "800px" }}>
			<Formik
				onSubmit={(values: IInstallRemoveMeter, { resetForm }) => {
					const valuesToSubmit =
						mapInstallRemoveMeterFormValuesToRequest(values);

					return requestInstallRemoveMeterMutation.mutate(valuesToSubmit, {
						onSuccess: () => resetForm(),
					});
				}}
				initialValues={initialInstallRemoveMeterFormValues}
				validationSchema={requestInstallRemoveMeterFormValidationSchema}
				validateOnChange
				enableReinitialize
			>
				{(form) => (
					<Form>
						<Box>
							<Typography variant="h1" mb={2}>
								Request Install/Remove Meter
							</Typography>
						</Box>
						<Stack gap={2}>
							<Field
								name="mpan"
								label="MPAN"
								as={TextField}
								form={form}
								onChange={(e: any) => {
									e.preventDefault();
									const { value } = e.target;
									const regex = /^[0-9]+$/;
									if (
										regex.test(value.toString()) &&
										value.toString().length <= 13
									) {
										form.setFieldValue("mpan", Number(value));
									} else if (value.toString().length === 0)
										form.setFieldValue("mpan", "");
								}}
								error={!!form.errors.mpan}
								helperText={form.errors.mpan}
							/>
							<TalosTextBox
								fieldName="transactionId"
								label="Transaction Id"
								form={form}
							/>
							<TalosDateField
								fieldName="appointmentDate"
								label="Appointment Date"
								form={form}
							/>
							<OutboundTimeField
								value={form.values.earliestAppointmentTime!!}
								name="earliestAppointmentTime"
								label="Earliest Appointment Time"
								helperText={form.errors.earliestAppointmentTime}
								error={!!form.errors.earliestAppointmentTime}
								form={form}
							/>
							<OutboundTimeField
								value={form.values.latestAppointmentTime!!}
								name="latestAppointmentTime"
								label="Latest Appointment Time"
								helperText={form.errors.latestAppointmentTime}
								error={!!form.errors.latestAppointmentTime}
								form={form}
							/>
							<TalosDropDown
								fieldName="retrievalMethod"
								label="Retrieval Method"
								form={form}
								menuItems={Object.entries(RetrievalMethod).map((val, index) => {
									return (
										<MenuItem
											key={`retrieval_method_${index}`}
											value={val[0]}
											onChange={() =>
												form.setValues(initialInstallRemoveMeterFormValues)
											}
										>
											{`${val[0]} - ${val[1]}`}
										</MenuItem>
									);
								})}
							/>
							<TalosDropDown
								fieldName="requestedEnergisationStatus"
								label="Requested Energisation Status"
								form={form}
								menuItems={Object.entries(EnergisationStatus).map(
									(val, index) => {
										return (
											<MenuItem
												key={`requested_energisation_status_${index}`}
												value={val[1]}
												onChange={() =>
													form.setValues(initialInstallRemoveMeterFormValues)
												}
											>
												{`${val[1]} - ${val[0]}`}
											</MenuItem>
										);
									}
								)}
							/>
							<TalosTextBox
								fieldName="standardSettlementConfigurationId"
								label="Standard Settlement Configuration Id"
								form={form}
							/>
							<TalosTextBox
								fieldName="additionalInformation"
								label="Additional Information"
								form={form}
								multiline
								minRows={3}
								maxRows={3}
							/>
							<TalosRadioGroup
								label="Remove Metering Point Meters"
								fieldName="removeMeteringPointMeters"
								form={form}
								row
								options={[
									{ value: "true", label: "Yes" },
									{ value: "false", label: "No" },
									{ value: "", label: "None" },
								]}
							/>
							<TalosTextBox
								fieldName="meteringSystemNonSettlementFunctionalityCode"
								label="Metering System Non-Settlement Functionality Code"
								form={form}
							/>
						</Stack>
						<Stack direction={"row"} gap={2} marginTop={2}>
							<Button
								variant="contained"
								type="submit"
								disabled={
									!form.isValid || requestInstallRemoveMeterMutation.isLoading
								}
							>
								Submit
							</Button>
						</Stack>
					</Form>
				)}
			</Formik>
		</Box>
	);
};
