import * as Yup from "yup";

import { dateIsNotValidNullable } from "../../../utilities";

export const requestInstallRemoveMeterFormValidationSchema = Yup.object().shape(
	{
		mpan: Yup.number()
			.required("MPAN is required.")
			.min(1000000000000, "MPAN must be exactly 13 digits.")
			.max(9999999999999, "MPAN must be exactly 13 digits."),
		transactionId: Yup.string().max(
			15,
			"Transaction id must be at most 15 characters."
		),
		appointmentDate: Yup.string().test(
			"invalid-date-action",
			"Appointment date should be a valid date.",
			dateIsNotValidNullable
		),
		earliestAppointmentTime: Yup.string(),
		latestAppointmentTime: Yup.string(),
		retrievalMethod: Yup.string().required("Retrieval method is required."),
		requestedEnergisationStatus: Yup.string().required(
			"Energisation status is required."
		),
		standardSettlementConfigurationId: Yup.string().matches(
			/^\d{4}$/,
			"SSC must be exactly 4 digits."
		),
		additionalInformation: Yup.string().max(
			200,
			"Additional information must be at most 200 characters."
		),
		removeMeteringPointMeters: Yup.boolean(),
		meteringSystemNonSettlementFunctionalityCode: Yup.string().max(
			10,
			"Functionality code must be at most 10 characters."
		),
	}
);
