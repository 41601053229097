import { Box, MenuItem } from "@mui/material";
import { Form, Formik } from "formik";
import { UseMutationResult } from "react-query";

import {
	TalosButton,
	TalosDateField,
	TalosDropDown,
	TalosTextBox,
} from "../forms";
import { validationSchema } from "./ut003-flows-filter-validation-schema";
import {
	READ_REASON,
	UT003FlowFormValues,
	READ_SOURCE,
	RTC,
	YFlag,
} from "../../models/talos/ut003-flows";

interface IProps {
	formSubmitMutation: UseMutationResult<Boolean, Error, UT003FlowFormValues>;
	formData: UT003FlowFormValues;
}

export const AddUT003FlowForm: React.FC<IProps> = ({
	formSubmitMutation,
	formData,
}) => {
	const handleOnSubmit = (values: UT003FlowFormValues) => {
		formSubmitMutation.mutate(values);
	};

	return (
		<Formik
			enableReinitialize
			onSubmit={handleOnSubmit}
			initialValues={formData}
			validationSchema={validationSchema}
			validateOnChange={true}
		>
			{(form) => (
				<Form>
					<Box className="form-wrapper">
						<Box className="form-column">
							<TalosTextBox fieldName="mprn" label="MPRN*" form={form} />
							<TalosTextBox fieldName="msn" label="MSN*" form={form} />
							<TalosTextBox fieldName="read" label="Read Value*" form={form} />
							<TalosDateField
								fieldName="read_date"
								label="Read Date*"
								form={form}
							/>
							<TalosDropDown
								fieldName="read_reason"
								form={form}
								label="Read Reason*"
								menuItems={READ_REASON.map((wl, idx) => (
									<MenuItem key={idx} value={wl.value}>
										{wl.display}
									</MenuItem>
								))}
							/>
							<TalosDropDown
								fieldName="read_source"
								form={form}
								label="Read Source"
								menuItems={READ_SOURCE.map((wl, idx) => (
									<MenuItem key={idx} value={wl.value}>
										{wl.display}
									</MenuItem>
								))}
							/>
							<TalosDropDown
								fieldName="rtc"
								form={form}
								label="RTC"
								menuItems={RTC.map((wl, idx) => (
									<MenuItem key={idx} value={wl.value}>
										{wl.display}
									</MenuItem>
								))}
							/>
							<TalosDropDown
								form={form}
								label="Y flag"
								fieldName="y_flag"
								menuItems={YFlag.map((wl, idx) => (
									<MenuItem key={idx} value={wl.value}>
										{wl.display}
									</MenuItem>
								))}
							/>
							<TalosButton
								fieldName="form_submit"
								form={form}
								buttonText="Submit"
								loading={formSubmitMutation.isLoading}
							/>
						</Box>
					</Box>
				</Form>
			)}
		</Formik>
	);
};
