import { useContext, useState } from "react";

import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Box, CircularProgress, Link } from "@mui/material";
import { AxiosError } from "axios";

import { getRegistrationById } from "../../api/themis";
import { AuthContext } from "../../auth";
import { AlertContext, handleError } from "../../utilities";
import {
	RegistrationDetailsTable,
	UpdateRegistrationDetailsForm,
} from "../../components";
import { RegistrationDetailsResponse } from "../../models/themis";
import {
	deleteRegistration,
	submitAregi,
	updateRegistrationDetails,
} from "../../api/themis/themis-api";

export const RegistrationDetails: React.FC = () => {
	const { registrationId } = useParams();
	const navigate = useNavigate();

	const { setTalosAlert } = useContext(AlertContext);
	const authContext = useContext(AuthContext);
	const queryClient = useQueryClient();

	const [showEditForm, setShowEditForm] = useState(false);

	const { data, isLoading } = useQuery(
		["registrationDetails", registrationId],
		() => getRegistrationById(authContext, registrationId!),
		{
			onError: (e: Error) => {
				const errorId = handleError(e);
				setTalosAlert({
					message: `Something went wrong fetching registration, please try again later or contact IOPS Support. Ticket ID: ${errorId}`,
					severity: "error",
				});
			},
			refetchOnWindowFocus: false,
		}
	);

	const submitAregiMutation = useMutation(
		(registrationId: string) => {
			return submitAregi(authContext, registrationId);
		},
		{
			onSuccess: () => {
				setTalosAlert({
					message: "Successfully submitted AREGI",
					severity: "success",
				});

				navigate("/export/registrations");
			},
			onError: (e: AxiosError) => {
				const errorId = handleError(e);
				setTalosAlert({
					message: `Something went wrong submitting AREGI. Please contact IOPS Support, Ticket ID: ${errorId}`,
					severity: "error",
				});
			},
			onSettled: () => {
				// Scroll to the top of the page, so the user can see messages
				scrollTo(0, 0);
			},
		}
	);

	const updateRegistrationDetailsMutation = useMutation(
		(registrationDetails: RegistrationDetailsResponse) => {
			return updateRegistrationDetails(authContext, registrationDetails);
		},
		{
			onSuccess: () => {
				setTalosAlert({
					message: "Successfully updated registration.",
					severity: "success",
				});

				queryClient.invalidateQueries(["registrationDetails", registrationId]);

				setShowEditForm(false);
			},
			onError: (e: Error) => {
				const errorId = handleError(e);

				setTalosAlert({
					message: `Something went wrong updating registration. Please contact IOPS Support, Ticket ID: ${errorId}`,
					severity: "error",
				});
			},
			onSettled: () => scrollTo(0, 0),
		}
	);

	const deleteRegistrationMutation = useMutation(
		(registrationId: string) => {
			return deleteRegistration(authContext, registrationId);
		},
		{
			onSuccess: () => {
				setTalosAlert({
					message: "Successfully deleted registration.",
					severity: "success",
				});

				navigate("/export/registrations");
			},
			onError: (e: AxiosError) => {
				const errorId = handleError(e);

				let errorMessage = "";
				if (e.response?.status === 409)
					errorMessage = e.response?.data as string;
				else
					errorMessage = `Something went wrong deleting registration. Please contact IOPS Support, Ticket ID: ${errorId}`;
				setTalosAlert({
					message: errorMessage,
					severity: "error",
				});
			},
			onSettled: () => scrollTo(0, 0),
		}
	);

	if (isLoading) return <CircularProgress />;
	if (!data)
		return (
			<Box>
				Go <Link href="/export/registrations">back</Link> to registrations
			</Box>
		);

	if (showEditForm)
		return (
			<UpdateRegistrationDetailsForm
				formData={data}
				setShowEditForm={setShowEditForm}
				updateRegistrationDetailsMutation={updateRegistrationDetailsMutation}
			/>
		);

	return (
		<RegistrationDetailsTable
			registration={data}
			authContext={authContext}
			setShowEditForm={setShowEditForm}
			submitAregiMutation={submitAregiMutation}
			deleteRegistrationMutation={deleteRegistrationMutation}
		/>
	);
};
