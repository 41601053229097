import { FC } from "react";

import { Button, Link, useTheme } from "@mui/material";

interface IProps {
	url: string;
	className?: string;
	label: string;
}

export const TalosLinkButton: FC<IProps> = (props) => {
	const theme = useTheme();

	return (
		<Link
			style={{ textDecoration: "none" }}
			href={props.url}
			target="_blank"
			rel="noreferrer"
			data-cy="linkButton"
		>
			<Button
				className={props.className}
				size="medium"
				sx={{
					backgroundColor: theme.palette.warning.dark,
					color: theme.palette.warning.light,
					"&:hover": {
						backgroundColor: theme.palette.warning.main,
					},
				}}
			>
				{props.label}
			</Button>
		</Link>
	);
};
