import { Box } from "@mui/material";
import { Field, FormikProps } from "formik";
import { DateField } from "@mui/x-date-pickers/DateField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";

import { getValueFromForm } from "../../utilities";

interface DateTimeFieldProps<T> {
	fieldName: string;
	label: string;
	form: FormikProps<T>;
	disabled?: boolean;
	maxDate?: Date;
}

export const TalosDateTimeField = <T extends object>(
	props: DateTimeFieldProps<T>
): JSX.Element => {
	const fieldValue = getValueFromForm(props.fieldName, props.form.values);
	const hasError = getValueFromForm(props.fieldName, props.form.errors);

	return (
		<LocalizationProvider dateAdapter={AdapterDateFns}>
			<Box className="form-row">
				<Field
					className="form-field"
					data-cy={props.fieldName}
					value={fieldValue ? new Date(fieldValue) : undefined}
					label={props.label}
					helperText={hasError}
					name={props.fieldName}
					error={!!hasError}
					disabled={props.disabled}
					as={DateField}
					maxDate={props.maxDate}
					format="dd/MM/yyyy HH:mm"
					onChange={(newValue: any) =>
						props.form.setFieldValue(props.fieldName, newValue)
					}
				/>
			</Box>
		</LocalizationProvider>
	);
};
