export interface FVRFormValues {
	mpan: string;
	msn: string;
	readDate: Date;
	readType: FVRReadType;
	hasSecondRegister: "yes" | "no";
	reg1Id: string;
	reg1Read: number;
	reg2Id?: string;
	reg2Read?: number;
}

export enum FVRReadType {
	C = "Customer read",
	D = "Deemed",
	F = "Final",
	I = "Initial",
	R = "Routine",
	W = "Withdrawn",
}

export interface RegisterData {
	regId: string;
	read: number;
}

export interface AddFVRequest {
	mpan: number;
	msn: string;
	readDate: string;
	readType: FVRReadType;
	reg1Data: RegisterData;
	reg2Data?: RegisterData;
}

export interface AddFVRResponse {
	id: string;
}

export interface FVRResponse {
	id: string;
	agentName: string;
	mpan: number;
	msn: string;
	readDate: string;
	readType: FVRReadType;
	createdAt: string;
	reg1Data: RegisterData;
	reg2Data?: RegisterData;
}

export interface FVRFilter {
	mpan?: string;
	start?: Date;
	end?: Date;
}
