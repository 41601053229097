import { FC } from "react";

import { Box, Typography, useTheme } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";

import { TalosLinkButton } from "../forms";

interface IProps {
	enabled: boolean;
	link: string | null;
	text: string;
}

export const IncidentBanner: FC<IProps> = (props) => {
	const theme = useTheme();

	return (
		<Box
			data-cy="issueBanner"
			sx={{
				alignItems: "center",
				backgroundColor: theme.palette.warning.light,
				border: `1px solid ${theme.palette.warning.dark}`,
				borderRadius: theme.spacing(0.5),
				color: theme.palette.warning.dark,
				display: "flex",
				flexGrow: 0,
				gap: theme.spacing(1),
				justifyContent: "space-between",
				minWidth: 0,
				padding: `${theme.spacing(3)}
							${theme.spacing(1)}
							${theme.spacing(3)}
							${theme.spacing(1)}`,
			}}
		>
			<Box
				sx={{
					alignItems: "center",
					display: "flex",
					gap: theme.spacing(1),
				}}
			>
				<ErrorIcon />
				<Typography
					sx={{
						fontSize: "large",
						lineHeight: `${theme.spacing(5)}px`,
						overflow: "hidden",
						textOverflow: "ellipsis",
						whiteSpace: "nowrap",
					}}
				>
					{props.text}
				</Typography>
			</Box>
			{props.link ? <TalosLinkButton url={props.link} label="Details" /> : null}
		</Box>
	);
};
