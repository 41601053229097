import React, { Dispatch, Fragment, useState } from "react";

import {
	Box,
	Button,
	Chip,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableRow,
	Tooltip,
	Typography,
} from "@mui/material";
import { UseMutationResult } from "react-query";
import { AxiosError } from "axios";

import { RegistrationDetailsResponse } from "../../models/themis";
import { IAuthContext } from "../../auth";
import { ROLES } from "../../utilities";

interface IProps {
	registration: RegistrationDetailsResponse;
	authContext: IAuthContext;
	setShowEditForm: Dispatch<React.SetStateAction<boolean>>;
	submitAregiMutation: UseMutationResult<
		boolean,
		AxiosError<unknown, any>,
		string
	>;
	deleteRegistrationMutation: UseMutationResult<
		boolean,
		Error,
		string,
		unknown
	>;
}

export const RegistrationDetailsTable: React.FC<IProps> = (props: IProps) => {
	const [showWarning, setShowWarning] = useState(false);
	const tableRow = (
		value: string | JSX.Element | JSX.Element[] | undefined,
		label: string
	) => {
		return (
			<TableRow>
				<TableCell variant="head">{label}</TableCell>
				<TableCell align="right">{value}</TableCell>
			</TableRow>
		);
	};

	if (!props.registration) return null;

	return (
		<Box sx={{ width: "800px" }}>
			<Stack direction={"row"} justifyContent={"space-between"}>
				<Typography variant="h1">
					Registration Details
					{props.registration.registrationDetail.processState
						?.processStatus && (
						<Chip
							label={
								props.registration.registrationDetail.processState
									?.processStatus
							}
							sx={{ marginLeft: "1rem" }}
						/>
					)}
				</Typography>
				{props.authContext.hasRole(ROLES.EXPORT_REGISTRATION_WRITE) && (
					<Button
						variant="contained"
						disabled={props.submitAregiMutation.isLoading}
						onClick={() =>
							props.submitAregiMutation.mutate(props.registration.id)
						}
					>
						Submit AREGI
					</Button>
				)}
			</Stack>
			<Box margin="1rem 0" display="flex" gap="1rem">
				{props.authContext.hasRole(ROLES.EXPORT_REGISTRATION_WRITE) && (
					<Button
						variant="outlined"
						onClick={() => props.setShowEditForm(true)}
						disabled={props.deleteRegistrationMutation.isLoading}
					>
						Edit AREGI
					</Button>
				)}
				{props.authContext.hasRole(ROLES.EXPORT_REGISTRATION_WRITE) && (
					<Tooltip
						title={
							props.registration.registrationDetail.processState !== null
								? "AREGI has been submitted"
								: ""
						}
						disableFocusListener
						disableTouchListener
						arrow
					>
						<span>
							<Button
								color="error"
								variant="contained"
								disabled={
									props.registration.registrationDetail.processState !== null ||
									props.deleteRegistrationMutation.isLoading
								}
								onClick={() => setShowWarning(true)}
							>
								Delete AREGI
							</Button>
						</span>
					</Tooltip>
				)}
			</Box>
			<Table>
				<TableBody>
					{tableRow(
						props.registration.registrationDetail.processState?.processPhase
							? `${props.registration.registrationDetail.processState?.processPhase}, 
							${props.registration.registrationDetail.processState?.processStatus}`
							: "-",

						"Status"
					)}
					{tableRow(
						props.registration.registrationDetail.contractType,
						"Contract Type"
					)}
					{tableRow(
						props.registration.registrationDetail.registrationType,
						"Submission Type"
					)}
					{tableRow(props.registration.createdDate, "Created date")}
					<TableRow>
						<TableCell colSpan={2}>
							<Typography variant="h2">Customer Details</Typography>
						</TableCell>
					</TableRow>
					{tableRow(props.registration.customerDetail.name, "Customer Name")}
					{tableRow(
						props.registration.customerDetail.address || "-",
						"First Line of Address"
					)}
					{tableRow(props.registration.customerDetail.postcode, "Postcode")}
					{tableRow(
						props.registration.customerDetail.priorityServices.length > 0
							? "Yes"
							: "No",
						"PSR"
					)}
					{props.registration.customerDetail.priorityServices.map(
						(psr, index) => {
							return (
								<Fragment key={psr.id}>
									{tableRow(psr.category, `PSR Code ${index + 1}`)}
									{tableRow(psr.expiryDate, `PSR expiration date ${index + 1}`)}
								</Fragment>
							);
						}
					)}
					<TableRow>
						<TableCell colSpan={2}>
							<Typography variant="h2">Site Details</Typography>
						</TableCell>
					</TableRow>
					{tableRow(
						props.registration.registrationDetail.isDomesticPremises !== null
							? props.registration.registrationDetail.isDomesticPremises
								? "Yes"
								: "No"
							: "-",
						"Is this a domestic property?"
					)}
					{tableRow(
						props.registration.registrationDetail.importSupplier,
						"Supplier"
					)}
					{tableRow(
						props.registration.registrationDetail.importMpan,
						"Import MPAN"
					)}
					{tableRow(
						props.registration.registrationDetail.exportMpan,
						"Export MPAN"
					)}
					{tableRow(
						props.registration.registrationDetail.meterSerialNumber,
						"Electricity MSN"
					)}
					{tableRow(
						props.registration.registrationDetail.ecoesRaised,
						"Date raised to Ecoes"
					)}
					{tableRow(
						props.registration.registrationDetail.contractStart,
						"Contract start date"
					)}
					{tableRow(
						props.registration.registrationDetail.mpanCreatedDate,
						"MPAN creation date"
					)}
					{tableRow(
						props.registration.registrationDetail.readingType,
						"Settlement Type"
					)}
					{tableRow(
						props.registration.registrationDetail.standardSettlementConfigId,
						"Standard Settlement Config Id"
					)}
					{tableRow(
						props.registration.registrationDetail.meterTimeSwitchCode,
						"Meter timeswitch code"
					)}
					{tableRow(
						props.registration.registrationDetail.electiveSettlementHHIndicator,
						"Elective Settlement HH Indicator"
					)}
					{tableRow(
						props.registration.registrationDetail.retrievalMethod,
						"Retrieval Method"
					)}
					{tableRow(
						props.registration.registrationDetail.meterOperatorId,
						"MOP ID"
					)}
					{tableRow(
						props.registration.registrationDetail.dataAggregatorId,
						"Data aggregator ID"
					)}
					{tableRow(
						props.registration.registrationDetail.dataCollectorId,
						"Data collected ID"
					)}
					{tableRow(
						props.registration.registrationDetail.d0217Received,
						"D0217 date"
					)}
					{tableRow(
						props.registration.registrationDetail.d0010iReceived,
						"D0010i date"
					)}
					{tableRow(
						props.registration.registrationDetail.d0019Received,
						"D0019 date"
					)}
					{tableRow(
						props.registration.registrationDetail.additionalNotes,
						"Additional Notes"
					)}
				</TableBody>
			</Table>
			<Dialog open={showWarning} onClose={() => setShowWarning(false)}>
				<DialogTitle>Are you sure?</DialogTitle>
				<DialogContent>Deleting a registration is not reversible</DialogContent>
				<DialogActions>
					<Button variant="contained" onClick={() => setShowWarning(false)}>
						No
					</Button>
					<Button
						color="error"
						variant="contained"
						onClick={() => {
							setShowWarning(false);
							props.deleteRegistrationMutation.mutate(props.registration.id);
						}}
					>
						Delete
					</Button>
				</DialogActions>
			</Dialog>
		</Box>
	);
};
