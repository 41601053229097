import * as Yup from "yup";

import { POSITIVE_NUMBERS_ONLY_MSG } from "../../utilities";

export const readsCalculatorBasedOnEacOrAaValidationSchema = Yup.object().shape(
	{
		lastValidatedDate: Yup.string().required(),
		lastValidatedDayRead: Yup.number().min(0, POSITIVE_NUMBERS_ONLY_MSG),
		lastValidatedNightRead: Yup.number().min(0, POSITIVE_NUMBERS_ONLY_MSG),
		estimationBasedOn: Yup.string().required(),
		eacaaDayRead: Yup.number().min(0, POSITIVE_NUMBERS_ONLY_MSG),
		eacaaNightRead: Yup.number().min(0, POSITIVE_NUMBERS_ONLY_MSG),
	}
);
