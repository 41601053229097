import { FC, useContext } from "react";

import { Box, Divider, Typography, useTheme } from "@mui/material";
import { AxiosError, AxiosResponse } from "axios";
import { UseMutationResult, useMutation } from "react-query";

import { updateConsentGranularity } from "../../../api/metering";
import { AuthContext } from "../../../auth";
import { UpdateConsentGranularityForm } from "../../../components/metering/change-of-registration-data";
import {
	IUpdateConsentGranularity,
	IUpdateConsentGranularityFormValues,
} from "../../../models/metering";
import { AlertContext, handleError } from "../../../utilities";

const initialUpdateConsentFormValues: IUpdateConsentGranularityFormValues = {
	mpan: "",
	granularity: "",
	effectiveFrom: new Date().toJSON(),
};

export const ConsentGranularityUpdate: FC = () => {
	const theme = useTheme();
	const authContext = useContext(AuthContext);
	const { setTalosAlert } = useContext(AlertContext);

	const updateConsentGranularityMutation: UseMutationResult<
		AxiosResponse,
		Error,
		IUpdateConsentGranularity
	> = useMutation(
		(consent: IUpdateConsentGranularity) => {
			return updateConsentGranularity(authContext, consent);
		},
		{
			onSuccess: () => {
				setTalosAlert({
					message: "Successfully submitted update Consent Granularity",
					severity: "success",
				});
			},
			onError: (e: AxiosError) => {
				if (e.response?.status === 400) {
					setTalosAlert({
						message: `Validation failed: ${e.response.data}`,
						severity: "error",
					});
				} else {
					const errorId = handleError(e);
					setTalosAlert({
						message: `Something went wrong submitting update Consent Granularity. Please contact IOPS Support, Ticket ID: ${errorId}`,
						severity: "error",
					});
				}
			},
			onSettled: () => {
				// Scroll to the top of the page, so the user can see messages
				scrollTo(0, 0);
			},
		}
	);

	return (
		<Box sx={{ width: "700px", margin: "0 auto;" }}>
			<Box>
				<Typography variant="h1" sx={{ mt: 0, mb: 1, textAlign: "center" }}>
					Update Consent Granularity
				</Typography>
				<Typography variant="body1">
					You can use this form to update Consent Granularity.
				</Typography>
				<Typography variant="body1" sx={{ mt: 1 }}>
					* = Required
				</Typography>
			</Box>
			<Divider sx={{ margin: theme.spacing(3, 0) }} />
			<UpdateConsentGranularityForm
				updateConsentGranularityMutation={updateConsentGranularityMutation}
				formData={initialUpdateConsentFormValues}
			/>
		</Box>
	);
};
