import { LDClient } from "launchdarkly-js-client-sdk";
import { asyncWithLDProvider } from "launchdarkly-react-client-sdk";

import { handleError, talosConfig } from "../utilities";

export const initialiseLaunchDarkly = () => {
	const clientSideId = talosConfig.launchDarkly.clientSideId || "";

	if (!clientSideId) {
		handleError(
			"REACT_APP_LAUNCH_DARKLY_CLIENT_SIDE_ID is missing. LaunchDarkly will fail to initialise. " +
				"Feature flags will be 'off' by default.",
			"warning"
		);
	}

	// Attempt to load launch darkly flags as JSON from an environment variable if
	// the current environment is "development". This allows for easy testing of
	// flagged features without interfering with production flagging.
	// Set variable REACT_APP_LD_FLAGS to a string representing a JSON object with
	// the appropriate flags set.
	let developmentFlags: any = undefined;
	if (
		process.env.NODE_ENV === "development" &&
		process.env.REACT_APP_LD_FLAGS
	) {
		try {
			developmentFlags = JSON.parse(process.env.REACT_APP_LD_FLAGS);
		} catch {
			// Suppress exception if the JSON is malformed
		}
	}

	return asyncWithLDProvider({
		clientSideID: clientSideId,
		flags: developmentFlags,
	});
};

export const identifyLaunchDarklyUser = async (
	ldClient?: LDClient,
	userId?: string
) => {
	if (!ldClient) {
		handleError(
			"LaunchDarkly client has failed to initialise. All feature flags will be 'off' by default.",
			"warning"
		);
	}

	if (!userId) {
		handleError(
			"LaunchDarkly has failed to get the user id. All feature flags will be 'off' by default.",
			"warning"
		);
	}

	try {
		await ldClient?.identify({ key: userId });
	} catch {
		handleError(
			"Error while identifying LaunchDarkly user. All feature flags will be 'off' by default.",
			"warning"
		);
	}
};
