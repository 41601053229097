import * as Yup from "yup";

import { dateIsNotValid, usefulRegex } from "../../../utilities";

export const updateConsentFormValidationSchema = Yup.object().shape({
	mpan: Yup.string()
		.matches(usefulRegex.MPAN, "MPAN must be a 13 digit integer")
		.required("MPAN is required"),
	granularity: Yup.string().required("Conset Granularity is required."),
	effectiveFrom: Yup.string()
		.required()
		.test(
			"invalid-date-action",
			"New Conset Granularity Effective Date should be a valid date.",
			dateIsNotValid
		),
});
