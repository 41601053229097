import React from "react";

import { Box } from "@mui/material";
import { Form, Formik } from "formik";

import { TalosDatePicker, TalosButton } from "../forms";
import { validationSchema } from "./search-by-date-validation-schema";
import { BookingRequestsFilter } from "../../models/talos";

interface IProps {
	handleSubmit: (filter: BookingRequestsFilter) => void;
	initialValues: BookingRequestsFilter;
}

export const SearchByDateFilter: React.FC<IProps> = (props: IProps) => {
	return (
		<Formik<BookingRequestsFilter>
			initialValues={props.initialValues}
			onSubmit={(values) => {
				props.handleSubmit(values);
			}}
			validationSchema={validationSchema}
		>
			{(form) => (
				<Form>
					<Box className="form-wrapper-horizontal">
						<Box className="form-column-narrow">
							<TalosDatePicker
								fieldName="start"
								label="Start Date"
								form={form}
							/>
						</Box>
						<Box className="form-column-narrow">
							<TalosDatePicker fieldName="end" label="End Date" form={form} />
						</Box>
						<Box className="form-column-narrow">
							<TalosButton
								fieldName="search_button"
								buttonText="Search"
								form={form}
								loading={false}
								enableIfNotDirty={true}
							/>
						</Box>
					</Box>
				</Form>
			)}
		</Formik>
	);
};
