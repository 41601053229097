import { addF09FlowRequest } from "../../api/talos";
import { generateFormPage } from "../../form-generator";

interface F09FlowFormValues {
	mpan: string;
	domesticPremisesIndicator: boolean;
}

const mapFormToValues = ({
	domesticPremisesIndicator,
	mpan,
}: F09FlowFormValues) => ({
	mpan: Number(mpan),
	domesticPremisesIndicatorUpdateF09: {
		domesticPremisesIndicator: domesticPremisesIndicator,
	},
});

export const AddF09Flow = generateFormPage(
	{
		formKey: "add-f09-flow",
		header: "Update Domestic Premise Indicator",
		title: "Domestic Premise Indicator Update",
		underlyingFlowName: "F09",
		fields: [
			{
				componentType: "mpan",
				fieldName: "mpan",
				label: "MPAN",
				required: true,
			},
			{
				componentType: "select",
				fieldName: "domesticPremisesIndicator",
				label: "Domestic Premise Indicator",
				options: [
					{ label: "True", value: true },
					{ label: "False", value: false },
				],
				required: true,
			},
		],
		listPage: {
			subject: "domestic presence indicator update requests",
			title: "Domestic Presence Update Requests",
			link: "/f09-flows",
		},
		mapFormToValues,
	},
	addF09FlowRequest
);
