export interface IMOP04v10 {
	mpanCore: number | "";
	dateOfAction: string;
	timeOfAction: string;
	jobType: string;
	successfulJob: boolean | "";
	supplierId: string;
	originalIncorrectDateOfAction?: string;
	correctionFlow?: boolean | null;
	dccConnected?: boolean | null;
	fieldForceName?: string;
	standardSettlementConfigurationId?: string;
	meteringSystemNonSettlementFunctionalityCode?: string;
	siteVisitCheckCode?: string;
	complexSiteIndicator?: boolean | null;
	meterEquipmentServiceLocation?: string;
	measurementClassId?: string;
	meterCOP?: string;
	meterCOPIssueNumber?: number | "";
	eventIndicator?: string;
	numberOfPhases?: number | "";
	systemVoltage?: number | "";
	additionalInformation?: string;
	remoteDisableEnableCapability?: boolean | null;
	measurementTransformerCommissioningCompleted?: boolean | null;
	moaCommissioningCompleted?: boolean | null;
	overallAccuracyOfMeteringSystem?: boolean | null;
	dateOfInstallationRemovalOfTimingDevice?: string;
	natureOfMaintenance?: string;
	ihdInstallStatus?: string;
	installedCorrectingInstalledMeters?: IinstalledCorrectingInstalledMetersM24[];
	removedCorrectingRemovedMeters?: IremovedCorrectingRemovedMetersM27[];
}

export interface IinstalledCorrectingInstalledMetersM24 {
	meterConfigCode: string;
	installedMeterId: string;
	originalIncorrectMeterId?: string;
	meterLocation: string;
	phaseWire?: string;
	feederStatus?: string;
	ctRatio?: string;
	vtRatio?: string;
	totalDebt?: number | "";
	debtRecoveryRate?: number | "";
	emergencyCredit?: number | "";
	initialCredit?: number | "";
	standingCharge?: number | "";
	meterAssetProviderId?: string;
	timingDeviceId?: string;
	meterAccuracyClass?: string;
	compensationAppliedToMeter?: boolean | null;
	numberOfDisplayedRegisterDigits?: number | "";
	installedMeterRegisters: IinstalledMeterRegistersM26[];
}

export interface IinstalledMeterRegistersM26 {
	meterRegisterId: string;
	registerReading: number | "";
	outstationId?: string;
	outstationMultiplier?: number | "";
	natureOfMaintenance?: string;
	channelNumber?: string;
	pulseMultiplier?: number | "";
	meterRegisterMultiplier?: number | "";
	numberOfRegisterDigits?: number | "";
	associatedMeterId?: string;
	associatedMeterRegisterId?: string;
	settlementIndicator?: string;
	measurementQuantityId?: string;
}

export interface IremovedCorrectingRemovedMetersM27 {
	removedMeterId: string;
	originalIncorrectRemovedMeterId?: string;
	creditBalance?: number | "";
	standingCharge?: number | "";
	totalCreditAccepted?: number | "";
	totalDebtOutstanding?: number | "";
	debtRecoveryRate?: number | "";
	emergencyCreditStatus?: boolean | null;
	totalTokensInserted?: number | "";
	timingDeviceId?: string;
	numberOfDisplayedRegisterDigits?: number | "";
	removedMeterRegisters: IremovedMeterRegistersM28[];
}

export interface IremovedMeterRegistersM28 {
	meterRegisterId: string;
	registerReading?: number | "";
	registerDescription?: string;
	prepaymentUnitRate?: number | "";
	natureOfMaintenance?: string;
}

export enum JobType {
	COMMISSIONING = "COMMISSIONING",
	EXCHANGE = "EXCHANGE",
	FEEDER = "FEEDER",
	HH_TO_NHH = "HH to NHH",
	INSTALL = "INSTALL",
	NHH_TO_HH = "NHH to HH",
	READS = "READS",
	RECONFIG = "RECONFIG",
	REMOVE = "REMOVE",
}

export enum SettlementIndicator {
	N = "N",
	S = "S",
	None = "",
}

export const initialMOP04FormValues: IMOP04v10 = {
	mpanCore: "",
	dateOfAction: "",
	timeOfAction: "",
	jobType: "",
	successfulJob: "",
	supplierId: "",
	originalIncorrectDateOfAction: "",
	correctionFlow: null,
	dccConnected: null,
	fieldForceName: "",
	standardSettlementConfigurationId: "",
	meteringSystemNonSettlementFunctionalityCode: "",
	siteVisitCheckCode: "",
	complexSiteIndicator: null,
	meterEquipmentServiceLocation: "",
	measurementClassId: "",
	meterCOP: "",
	meterCOPIssueNumber: "",
	eventIndicator: "",
	numberOfPhases: "",
	systemVoltage: "",
	additionalInformation: "",
	remoteDisableEnableCapability: null,
	measurementTransformerCommissioningCompleted: null,
	moaCommissioningCompleted: null,
	overallAccuracyOfMeteringSystem: null,
	dateOfInstallationRemovalOfTimingDevice: "",
	natureOfMaintenance: "",
	ihdInstallStatus: undefined,
	installedCorrectingInstalledMeters: [],
	removedCorrectingRemovedMeters: [],
};

export const initialInstalledMeterRegistersM26FormValues: IinstalledMeterRegistersM26 =
	{
		meterRegisterId: "",
		registerReading: "",
		outstationId: "",
		outstationMultiplier: "",
		natureOfMaintenance: "",
		channelNumber: "",
		pulseMultiplier: "",
		meterRegisterMultiplier: "",
		numberOfRegisterDigits: "",
		associatedMeterId: "",
		associatedMeterRegisterId: "",
		settlementIndicator: "",
		measurementQuantityId: "",
	};

export const initialInstalledCorrectingInstalledMeters24FormValues: IinstalledCorrectingInstalledMetersM24 =
	{
		meterConfigCode: "",
		installedMeterId: "",
		originalIncorrectMeterId: "",
		meterLocation: "",
		phaseWire: "",
		feederStatus: "",
		ctRatio: "",
		vtRatio: "",
		totalDebt: "",
		debtRecoveryRate: "",
		emergencyCredit: "",
		initialCredit: "",
		standingCharge: "",
		meterAssetProviderId: "",
		timingDeviceId: "",
		meterAccuracyClass: "",
		compensationAppliedToMeter: null,
		numberOfDisplayedRegisterDigits: "",
		installedMeterRegisters: [initialInstalledMeterRegistersM26FormValues],
	};

export const initialRemovedMeterRegistersM28FormValues: IremovedMeterRegistersM28 =
	{
		meterRegisterId: "",
		registerReading: "",
		registerDescription: "",
		prepaymentUnitRate: "",
		natureOfMaintenance: "",
	};

export const initialRemovedCorrectingRemovedMetersM27FormValues: IremovedCorrectingRemovedMetersM27 =
	{
		removedMeterId: "",
		originalIncorrectRemovedMeterId: "",
		creditBalance: "",
		standingCharge: "",
		totalCreditAccepted: "",
		totalDebtOutstanding: "",
		debtRecoveryRate: "",
		emergencyCreditStatus: null,
		totalTokensInserted: "",
		timingDeviceId: "",
		numberOfDisplayedRegisterDigits: "",
		removedMeterRegisters: [initialRemovedMeterRegistersM28FormValues],
	};
