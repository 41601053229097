import * as Yup from "yup";

import { dateIsNotValid, usefulRegex } from "../../utilities";

export const formValidationSchema = Yup.object().shape(
	{
		mpan: Yup.string()
			.matches(usefulRegex.MPAN, "MPAN must be a 13 digit integer")
			.required("MPAN is required"),

		measurementQuantityId: Yup.string().required(
			"Measurement Quantity Id is required"
		),

		consumptionAmendmentSupplierReference: Yup.string()
			.nullable()
			.transform((curr, orig) => (orig === "" ? null : curr))
			.when("consumptionAmendmentSupplierReference", {
				is: (value: string) => value && value !== "",
				then: (schema) =>
					schema
						.required("Consumption Amendment Supplier Reference is required")
						.test(
							"len",
							"Consumption Amendment Supplier Reference must be less then 50 characters",
							(value: string) => value.length <= 50
						),
			}),

		consumptionAmendmentPeriodStartDate: Yup.string()
			.required("Consumption Amendment Period Start Date is required")
			.test(
				"cap-sd-is-not-valid",
				"Consumption Amendment Period Start Date should be a valid date",
				dateIsNotValid
			),

		consumptionAmendmentPeriodEndDate: Yup.string()
			.required("Consumption Amendment Period End Date is required")
			.test(
				"cap-ed-is-not-valid",
				"Consumption Amendment Period End Date should be a valid date",
				dateIsNotValid
			),

		grossRevisedConsumption: Yup.number()
			.required("Gross Revised Consumption is required")
			.min(1, "Gross Revised Consumption must be between 1 and 999999999")
			.max(
				999999999,
				"Gross Revised Consumption must be between 1 and 999999999"
			),

		supplierConsumptionAmendmentReasonCode: Yup.string().required(
			"Supplier Consumption Amendment Reason Code is required"
		),
	},
	[
		[
			"consumptionAmendmentSupplierReference",
			"consumptionAmendmentSupplierReference",
		],
	]
);
