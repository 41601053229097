import React from "react";

import { Box, Link, Typography, useTheme } from "@mui/material";

import { OvoLogo } from "../components";
import { talosColours } from "../styles/talos-colors";

export const NotFound: React.FC = () => {
	const theme = useTheme();

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				width: "100%",
				alignItems: "center",
				gap: "2rem",
			}}
		>
			<OvoLogo fill={talosColours.green.main} />
			<Typography variant="h1" sx={{ padding: theme.spacing(1.5, 0) }}>
				This page does not exist.
			</Typography>
			<Link
				href="/"
				sx={{
					cursor: "pointer",
					color: theme.palette.talos.semantic.message.dark,
				}}
			>
				<Typography variant="h3" sx={{ padding: theme.spacing(1.5, 0) }}>
					Back to main page
				</Typography>
			</Link>
		</Box>
	);
};
