import * as Yup from "yup";

import { dateIsNotFuture, dateIsNotValid, usefulRegex } from "../../utilities";
import {
	READ_REASON,
	READ_SOURCE,
	RTC,
	YFlag,
} from "../../models/talos/ut003-flows";

export const validationSchema = Yup.object({
	mprn: Yup.string()
		.matches(usefulRegex.MPRN, "MPRN must be between 6 and 10 integers")
		.required("MPRN is required"),
	msn: Yup.string().required("MSN is required"),
	read: Yup.number()
		.positive("Read must be a positive number")
		.required("Read is required"),
	read_date: Yup.string()
		.required("Read date must be a valid date in the past")
		.test(
			"read-date-is-not-future",
			"Read date shouldn't be in the future",
			dateIsNotFuture
		)
		.test(
			"read-date-is-not-valid",
			"Read date should be a valid date",
			dateIsNotValid
		),
	read_reason: Yup.string()
		.oneOf(
			READ_REASON.map((reason) => reason.value),
			"Must be one of: " + READ_REASON.map((reason) => reason.value).join(", ")
		)
		.required("Read Reason is required"),
	read_source: Yup.string()
		.oneOf(
			READ_SOURCE.map((reason) => reason.value),
			"Must be one of: " + READ_SOURCE.map((reason) => reason.value).join(", ")
		)
		.required("Read Source is required"),
	rtc: Yup.string()
		.oneOf(
			RTC.map((reason) => reason.value),
			"Must be one of: " + RTC.map((reason) => reason.value).join(", ")
		)
		.required("RTC is required"),
	y_flag: Yup.string()
		.oneOf(
			YFlag.map((reason) => reason.value),
			"Must be one of: " + YFlag.map((reason) => reason.value).join(", ")
		)
		.required("Y flag is required"),
});
