export enum ROLES {
	BOOKING_REQUEST_WRITE = "BOOKING_REQUEST_WRITE",
	BOOKING_REQUEST_READ = "BOOKING_REQUEST_READ",
	BOOKING_REQUEST_CANCEL = "BOOKING_REQUEST_CANCEL",
	CHANGE_READ_WRITE = "CHANGE_READ_WRITE",
	CHANGE_READ_READ = "CHANGE_READ_READ",
	CHANGE_READ_CANCEL = "CHANGE_READ_CANCEL",
	UTILITIES_ACCESS = "UTILITIES_ACCESS",
	EXPORT_REGISTRATION_READ = "EXPORT_REGISTRATION_READ",
	EXPORT_REGISTRATION_WRITE = "EXPORT_REGISTRATION_WRITE",
	EXPORT_REGISTRATION_BULK_WRITE = "EXPORT_REGISTRATION_BULK_WRITE",
	FLOWS_USER = "FLOWS_USER",
	FLOWS_ADMIN = "FLOWS_ADMIN",
	OUTBOUND_MTD_WRITE = "OUTBOUND_MTD_WRITE",
	METERING_CHANGE_OF_REGISTRATION_WRITE = "METERING_CHANGE_OF_REGISTRATION_WRITE",
	SETTLEMENTS_READS_FVR_READ = "SETTLEMENTS_READS_FVR_READ",
	SETTLEMENTS_READS_FVR_WRITE = "SETTLEMENTS_READS_FVR_WRITE",
}
