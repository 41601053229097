import { requestSupplyDisconnection } from "../../../api/metering";
import {
	CustomerPreferredContactMethod,
	DisconnectionType,
	ISupplyDisconnection,
} from "../../../models/metering";
import { EnergisationStatus } from "../../../models/metering/common";
import {
	generateFormPage,
	selectOptionsFromEnum,
} from "../../../form-generator";
import { COMMON_FIELDS } from "../../../form-generator/form-component.generator";

export const RequestSupplyDisconnection = generateFormPage<
	ISupplyDisconnection,
	ISupplyDisconnection
>(
	{
		formKey: "request-supply-disconnection",
		header: "Request Supply Disconnection",
		newRequestText: "",
		fields: [
			COMMON_FIELDS.MPAN,
			{
				componentType: "select",
				fieldName: "disconnectionType",
				label: "Disconnection Type",
				options: selectOptionsFromEnum(
					DisconnectionType,
					"value - key",
					"value"
				),
				required: true,
			},
			{
				componentType: "string",
				fieldName: "additionalInformation",
				label: "Additional Information",
				minRows: 3,
				maxRows: 3,
				required: true,
			},
			{
				componentType: "date",
				fieldName: "appointmentDate",
				label: "Appointment Date",
				required: true,
			},
			{
				componentType: "select",
				fieldName: "energisationStatus",
				label: "Energisation Status",
				defaultValue: EnergisationStatus["De-Energised"],
				options: selectOptionsFromEnum(
					EnergisationStatus,
					"value - key",
					"value"
				),
				disabled: true,
				required: true,
			},
			{
				componentType: "string",
				fieldName: "contactName",
				label: "Contact Name",
				minRows: 2,
				maxRows: 2,
				required: true,
			},
			{
				componentType: "select",
				fieldName: "customerPreferredContactMethod",
				label: "Customer Preferred Contact Method",
				options: selectOptionsFromEnum(
					CustomerPreferredContactMethod,
					"value - key",
					"value"
				),
				required: true,
			},
			{
				componentType: "string",
				fieldName: "customerPreferredContactDetails",
				label: "Customer Preferred Contact Details",
				minRows: 3,
				maxRows: 3,
				required: true,
			},
		],
		successMessage: "Successfully requested supply disconnection.",
		errorMessage: (errorId) =>
			`Something went wrong while requesting supply disconnection. Please contact IOPS Support, Ticket ID: ${errorId}`,
	},
	requestSupplyDisconnection
);
