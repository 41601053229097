import React from "react";

import { Breadcrumbs, Link, Typography, useTheme } from "@mui/material";
import { useLocation } from "react-router-dom";

import { getBreadCrumbsFromPath } from "../../utilities/navigation.helpers";

export const TalosBreadCrumbs: React.FC = () => {
	const location = useLocation();
	const theme = useTheme();

	const crumbs = getBreadCrumbsFromPath(location.pathname);

	return (
		<Breadcrumbs
			separator=">"
			sx={{
				paddingTop: theme.spacing(2.5),
				marginLeft: theme.spacing(2),
				"& ol": {
					flexWrap: "nowrap",
				},
				"& p": {
					whiteSpace: "nowrap",
				},
			}}
		>
			{crumbs.map((crumb, idx) => (
				<Typography
					key={`breadcrumb_${idx}`}
					sx={{ textTransform: "capitalize" }}
				>
					{idx !== crumbs.length - 1 ? (
						<Link
							href={crumb.path}
							sx={{
								color: theme.palette.talos.semantic.message.dark,
							}}
						>
							{crumb.displayText}
						</Link>
					) : (
						<>{crumb.displayText}</>
					)}
				</Typography>
			))}
		</Breadcrumbs>
	);
};
