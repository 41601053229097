/**
 * Chained type which takes a subject type for input and final output and another
 * type containing methods for chaining
 */
type Chain<
	TSubject,
	TMethods extends Record<
		string,
		(subject: TSubject, ...args: any[]) => unknown
	>
> = {
	[K in keyof TMethods]: TMethods[K] extends (
		subject: TSubject,
		...args: infer TArgs
	) => any
		? (...args: TArgs) => Chain<TSubject, TMethods>
		: never;
} & { end: () => TSubject };

/**
 * Creates a chain function which takes an input of type T at the start of the
 * chain and finally produces an output of type T at the end of the chain.
 * In between any number of methods can be invoked on the current instance of T
 * in the chain to produce a new instance of T, the current iteration.
 * The parameter to `createChain` is an object with properties which are
 * methods taking input of type T and outputting type T.
 * The function returned by `createChain` takes an input of type T and applies it
 * as the first argument for any of the methods that would be invoked. Any of those
 * function calls will create a new chain with the return value of the previous
 * function call as it's input allowing for another chained call up until the
 * special function `end` is invoked to return the last return value from the
 * chain.
 */
const createChain = <T>(funcs: {
	[key: string]: { (subject: T, ...args: any[]): T };
}): ((input: T) => Chain<T, typeof funcs>) => {
	const chain = (input: T): Chain<T, typeof funcs> => {
		const chainedFuncs = Object.fromEntries(
			Object.keys(funcs).map((key) => {
				const chainedFunc = (...args: any[]) =>
					chain(funcs[key](input, ...args));
				return [key, chainedFunc] as [string, typeof chainedFunc];
			})
		);
		return Object.assign(chainedFuncs, { end: () => input });
	};
	return chain;
};

export const chain = createChain({
	if: <T>(subject: T, condition: boolean, chainAction: (subject: T) => T): T =>
		condition ? chainAction(subject) : subject,
});
