import React from "react";

import { Box, Typography, useTheme } from "@mui/material";
import { Form, Formik } from "formik";

import { getEacOrAABasedCalculation } from "../../utilities";
import { TalosDatePicker, TalosNumberBox, TalosTextBox } from "../forms";
import { readsCalculatorBasedOnEacOrAaValidationSchema } from "./reads-calculator-based-on-eac-or-aa.validation-schema";
import { IEACFormValues, IEACResult } from "../../models/talos";

export const ReadsCalculatorBasedOnEACorAA: React.FC = () => {
	const [result, setResult] = React.useState<IEACResult>();

	const theme = useTheme();

	const handleOnChange = (values: IEACFormValues) => {
		const res = getEacOrAABasedCalculation(values);

		setResult(res);
	};

	return (
		<Box>
			<Formik
				onSubmit={() => {}}
				initialValues={{
					lastValidatedDate: new Date("2023-01-01"),
					lastValidatedDayRead: 0,
					lastValidatedNightRead: 0,
					estimationBasedOn: new Date("2023-07-02"),
					eacaaDayRead: 0,
					eacaaNightRead: 0,
				}}
				validationSchema={readsCalculatorBasedOnEacOrAaValidationSchema}
				validate={handleOnChange}
			>
				{(form) => (
					<Form>
						<Box
							className="form-wrapper"
							sx={{
								flexDirection: { xs: "column", md: "row" },
								gap: theme.spacing(5),
								alignItems: "flex-start",
							}}
						>
							<Box className="form-column" sx={{ maxWidth: "300px" }}>
								<Typography variant="h6">EAC/AA</Typography>
								<TalosNumberBox
									fieldName="eacaaDayRead"
									label="Day"
									form={form}
								/>
								<TalosNumberBox
									fieldName="eacaaNightRead"
									label="Night"
									form={form}
								/>

								<Typography variant="h6">Last Validated </Typography>
								<TalosDatePicker
									fieldName="lastValidatedDate"
									label="Date"
									form={form}
								/>
								<TalosNumberBox
									fieldName="lastValidatedDayRead"
									label="Day"
									form={form}
								/>
								<TalosNumberBox
									fieldName="lastValidatedNightRead"
									label="Night"
									form={form}
								/>
								<Typography variant="h6">Estimation Based On</Typography>
								<TalosDatePicker
									fieldName="estimationBasedOn"
									label="Date"
									form={form}
								/>
							</Box>
							<Box className="form-column" sx={{ maxWidth: "300px" }}>
								<Typography variant="h6">Result</Typography>
								<TalosTextBox
									fieldName="result.Day"
									label="Day"
									overrideValue={result?.dayRead?.toString()}
									form={form}
									sx={theme.customSX.darkTextBox}
									disabled={true}
								/>
								<TalosTextBox
									fieldName="result.Night"
									label="Night"
									overrideValue={result?.nightRead?.toString()}
									form={form}
									disabled={true}
									sx={theme.customSX.darkTextBox}
								/>
							</Box>
						</Box>
					</Form>
				)}
			</Formik>
		</Box>
	);
};
