import React, { useEffect, useRef, useState } from "react";

import { useLDClient } from "launchdarkly-react-client-sdk";
import { QueryClient, QueryClientProvider } from "react-query";

import "./styles/App.css";
import { TalosRouter } from "./routes";
import {
	configureSentry,
	AlertContext,
	defaultAlertState,
	handleError,
	ITalosAlert,
	useInitialiseAnalytics,
	identifyAnalyticsUser,
} from "./utilities";
import { Loading } from "./pages";
import { AuthContext, IAuthContext, initializeAuthContext } from "./auth";
import { identifyLaunchDarklyUser } from "./flags";
import { ErrorBoundary } from "./components";

configureSentry();

const queryClient = new QueryClient();

export const App: React.FC = () => {
	const [authContext, setAuthContext] = useState<IAuthContext | undefined>(
		undefined
	);
	const [talosAlert, setTalosAlert] = useState<ITalosAlert>(defaultAlertState);

	const alertContext = {
		talosAlert,
		setTalosAlert,
	};

	const authRef = useRef(false);
	const ldClient = useLDClient();

	useInitialiseAnalytics();

	useEffect(() => {
		if (authRef.current === false) {
			authRef.current = true;

			initializeAuthContext()
				.then((ctx) => {
					setAuthContext(ctx);
					identifyLaunchDarklyUser(ldClient, ctx.provider?.profile?.id);
					identifyAnalyticsUser(ctx.email);
				})
				.catch(handleError);
		}
	}, [ldClient]);

	return authContext ? (
		<ErrorBoundary>
			<AuthContext.Provider value={authContext}>
				<AlertContext.Provider value={alertContext}>
					<QueryClientProvider client={queryClient}>
						<TalosRouter />
					</QueryClientProvider>
				</AlertContext.Provider>
			</AuthContext.Provider>
		</ErrorBoundary>
	) : (
		<Loading />
	);
};
