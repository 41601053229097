import React from "react";

import { Box, FormControl } from "@mui/material";
import { FormikProps } from "formik";
import {
	DatePicker,
	DatePickerProps as MuiDatePickerProps,
	LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import { getValueFromForm, isHoliday, isSunday } from "../../utilities";

export interface DatePickerProps<T> {
	fieldName: string;
	label: string;
	form: FormikProps<T>;
	disableSundays?: boolean;
	disableHolidays?: boolean;
	minDate?: Date;
	maxDate?: Date;
	disablePast?: boolean;
	disableFuture?: boolean;
	sx?: MuiDatePickerProps<Date>["sx"];
}

export const TalosDatePicker = <T extends object>(
	props: DatePickerProps<T>
): JSX.Element => {
	const fieldValue = getValueFromForm(props.fieldName, props.form.values);
	const error = getValueFromForm(props.fieldName, props.form.errors);

	const daysForDisable = (date: Date | undefined) => {
		if (props.disableSundays) {
			return isSunday(date);
		} else if (props.disableHolidays) {
			return isHoliday(date);
		} else {
			return false;
		}
	};

	return (
		<LocalizationProvider dateAdapter={AdapterDateFns}>
			<Box className="form-row">
				<FormControl
					className="form-field"
					data-cy={`a-date-picker-${props.fieldName}`}
				>
					<DatePicker
						onChange={(value: any) => {
							props.form.setFieldValue(props.fieldName, value, true);
						}}
						label={props.label}
						data-cy={`date-picker-${props.fieldName}`}
						value={new Date(fieldValue)}
						format="dd/MM/yyyy"
						slotProps={{
							textField: {
								error: !!error,
								helperText: error ? error : "",
								label: props.label,
								inputProps: {
									"data-cy": props.fieldName,
									name: props.fieldName,
								},
							},
						}}
						maxDate={props.maxDate}
						sx={props.sx}
						disablePast={props.disablePast}
						disableFuture={props.disableFuture}
						minDate={props.minDate}
						shouldDisableDate={daysForDisable}
					/>
				</FormControl>
			</Box>
		</LocalizationProvider>
	);
};
