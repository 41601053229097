import { Box, SxProps, TextField, Theme } from "@mui/material";
import { Field, FormikProps } from "formik";

import { getValueFromForm } from "../../utilities";

interface NumberBoxProps<T> {
	fieldName: string;
	label: string;
	form: FormikProps<T>;
	disabled?: boolean;
	sx?: SxProps<Theme>;
	overrideValue?: number | undefined;
}

export const TalosNumberBox = <T extends object>(
	props: NumberBoxProps<T>
): JSX.Element => {
	const fieldValue =
		props.overrideValue || getValueFromForm(props.fieldName, props.form.values);
	const hasError = getValueFromForm(props.fieldName, props.form.errors);

	return (
		<Box className="form-row">
			<Field
				className="form-field"
				data-cy={props.fieldName}
				value={fieldValue}
				as={TextField}
				sx={props.sx}
				type="number"
				label={props.label}
				helperText={hasError}
				name={props.fieldName}
				error={!!hasError}
				disabled={props.disabled}
			/>
		</Box>
	);
};
