import React, { useContext } from "react";

import { Navigate } from "react-router-dom";

import { AuthContext } from "../auth";

interface IProps {
	role: string;
	children: JSX.Element;
}

export const ProtectedPage = (props: IProps) => {
	const { hasRole } = useContext(AuthContext);

	if (!hasRole(props.role)) {
		return <Navigate to="/404" />;
	}

	return props.children;
};
