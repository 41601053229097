import { useEffect } from "react";

import mixpanel from "mixpanel-browser";

import { talosConfig } from "./configuration";

const token = talosConfig.mixpanel.token;

/**
 * Initialise Mixpanel analytics with the token from the environment variables.
 */
export const useInitialiseAnalytics = () =>
	useEffect(() => {
		if (!token) {
			console.warn("Mixpanel token not found.");
			return;
		}

		mixpanel.init(token, talosConfig.mixpanel.config);

		// Register some properties we want to have sent with every event.
		mixpanel.register_once(talosConfig.mixpanel);
	}, []);

/**
 * Identify the user in Mixpanel analytics. Most likely this will be the user's
 * email address.
 */
export const identifyAnalyticsUser = (userId: string) => {
	// If mixpanel token or user ID aren't available then don't identify the user.
	if (!token || !userId) return;

	mixpanel.identify(userId);
};

/**
 * Track the duration of an event. Invoking this hook starts the timer, invoking
 * the returned function stops the timer and sends the event to Mixpanel.
 * It also accepts a boolean to disable analytics.
 * @param eventName Name of the event to track.
 * @param eventKey Key to identify the event, e.g. which form page are we tracking.
 * This data will be bundled with the event data.
 * @param eventData Additional data to track with the event.
 * @param disableAnalytics In some cases we may not want to track the event.
 * Hooks cannot be called conditionally so provide a boolean to disable tracking.
 */
export const useTrackEventTime = (
	eventName: string,
	eventKey: string,
	eventData: Record<string, any>,
	disableAnalytics = false
) => {
	const mixpanelDisabled = !token || disableAnalytics;

	useEffect(() => {
		// If mixpanel token is not found or analytics are disabled, do not track
		// the event.
		if (mixpanelDisabled) {
			return;
		}

		// Track the event with the key and data. Record phase start so that we can
		// differentiate between the number of started events, and the number of
		// completed events.
		mixpanel.track(eventName, {
			...eventData,
			key: eventKey,
			phase: "initiate",
		});

		// Begin event timing, this will give us an accurate duration for the event.
		mixpanel.time_event(eventName);
	}, [eventName, eventKey, mixpanelDisabled]); // eslint-disable-line react-hooks/exhaustive-deps

	return () => {
		if (mixpanelDisabled) return;

		// Track the event with the key and data. Record phase end so that we can
		// differentiate between the number of started events, and the number of
		// completed events.
		mixpanel.track(eventName, {
			...eventData,
			key: eventKey,
			phase: "complete",
		});
	};
};

export const useTrackEvent = (
	eventName: EVENT,
	eventKey: string,
	eventData: Record<string, unknown> = {},
	disableAnalytics = false
) =>
	useEffect(() => {
		// If mixpanel token is not found or analytics are disabled, do not track
		// the event.
		if (!token || disableAnalytics) return;

		mixpanel.track(eventName, { ...eventData, key: eventKey });
	}, [eventName, eventKey]); // eslint-disable-line react-hooks/exhaustive-deps

export const useTrackFormCompletion = (
	formKey: string,
	eventData: Record<string, unknown> = {},
	disableAnalytics = false
) =>
	useTrackEventTime(EVENT.FORM_COMPLETE, formKey, eventData, disableAnalytics);

export enum EVENT {
	FORM_COMPLETE = "Talos Form Complete",
	PAGE_VIEW = "Talos Page View",
}
