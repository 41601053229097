import React, { useContext, useState } from "react";

import {
	Box,
	CircularProgress,
	Divider,
	Typography,
	useTheme,
} from "@mui/material";
import { format } from "date-fns";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useMutation, useQuery } from "react-query";

import { AddChangeReadRequestForm } from "../../components";
import { AuthContext } from "../../auth";
import { ChangeReadRequest, IChangeReadFormValues } from "../../models/talos";
import {
	addChangeReadRequest,
	getChangeReadRequestById,
} from "../../api/talos";
import { AlertContext, handleError } from "../../utilities";

const initialValues: IChangeReadFormValues = {
	worklist: "",
	mpan: "",
	msn: "",
	rootCause: "",
	readDate: "",
	readType: "",
	firstRegisterId: "",
	firstRegisterIncorrectRead: "",
	firstRegisterCorrectRead: "",
	subRegisters: "N",
	secondRegisterId: undefined,
	secondRegisterIncorrectRead: "",
	secondRegisterCorrectRead: "",
};

export const AddChangeReadRequest: React.FC = () => {
	const theme = useTheme();
	const authContext = useContext(AuthContext);

	const { setTalosAlert } = useContext(AlertContext);

	const navigate = useNavigate();

	const [formData, setFormData] =
		useState<IChangeReadFormValues>(initialValues);

	const [searchParams] = useSearchParams();
	const id = searchParams.get("id");

	const { isLoading } = useQuery(
		["change-read-id", authContext, id],
		() => {
			if (id) return getChangeReadRequestById(id, authContext);
		},
		{
			onSuccess: (data) => {
				if (data) {
					setFormData({
						...data.changeRead,
						subRegisters: data.changeRead.secondRegisterId ? "Y" : "N",
						secondRegisterId: data.changeRead.secondRegisterId || undefined,
						secondRegisterIncorrectRead:
							data.changeRead.secondRegisterIncorrectRead || "",
						secondRegisterCorrectRead:
							data.changeRead.secondRegisterCorrectRead || "",
					});
				}
			},
			onError: (e: Error) => {
				const errorId = handleError(e);
				setTalosAlert({
					message: `Something went wrong fetching change read, please try again later or contact IOPS Support. Ticket ID: ${errorId}`,
					severity: "error",
				});
			},
		}
	);

	const changeReadsSubmitMutation = useMutation(
		(values: IChangeReadFormValues) => {
			const requestValues = mapFormToValues(values);
			return addChangeReadRequest(authContext, requestValues);
		},
		{
			onSuccess: () => {
				setTalosAlert({
					message: "Change Read Request has been sent successfully",
					severity: "success",
				});
				navigate("/change-reads");
			},
			onError: (e: Error) => {
				const errorId = handleError(e);

				setTalosAlert({
					message: `Something went wrong submitting change read request form. Please contact IOPS Support, Ticket ID: ${errorId}`,
					severity: "error",
				});

				// Scroll to the top of the page, so the user can see error message
				scrollTo(0, 0);
			},
		}
	);

	return (
		<Box sx={{ width: "700px", margin: "0 auto;" }}>
			<Box>
				<Typography
					variant="h1"
					sx={{ margin: theme.spacing(3, 0), textAlign: "center" }}
				>
					New Change Read Request
				</Typography>
				<Typography variant="body1">
					Fill in this form to request a new change read request.
				</Typography>
				<Typography variant="body1">* = Required</Typography>
				<Typography variant="body1">
					To see change reads go to the{" "}
					<Link to="/change-reads">Change Read Requests</Link> page
				</Typography>
			</Box>
			<Divider sx={{ margin: theme.spacing(3, 0) }} />
			{isLoading ? (
				<CircularProgress />
			) : (
				<AddChangeReadRequestForm
					changeReadsSubmitMutation={changeReadsSubmitMutation}
					formData={formData}
				/>
			)}
		</Box>
	);
};

const mapFormToValues = (values: IChangeReadFormValues): ChangeReadRequest => {
	return {
		...values,
		readDate: values.readDate
			? format(new Date(values.readDate), "yyyy-MM-dd")
			: "",
		firstRegisterIncorrectRead: values.firstRegisterIncorrectRead
			? values.firstRegisterIncorrectRead
			: 0,
		firstRegisterCorrectRead: values.firstRegisterCorrectRead
			? values.firstRegisterCorrectRead
			: 0,
		secondRegisterIncorrectRead:
			values.secondRegisterIncorrectRead === ""
				? undefined
				: values.secondRegisterIncorrectRead,
		secondRegisterCorrectRead:
			values.secondRegisterCorrectRead === ""
				? undefined
				: values.secondRegisterCorrectRead,
	};
};
