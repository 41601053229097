import { BaseFlowResponse } from "./base-flow";
import { DropDownItem } from "./change-read";

export interface S33FlowsResponse extends BaseFlowResponse {
	data: S33FlowsResponseData;
}

export interface S33FlowsResponseData {
	mpan: string;
	flowAndDestinationTypes: string;
	meterIdSerialNumber: string;
	readingType: string;
	siteVisitCheckCode: string;
	additionalInformation: string | null;
}

export interface IFlowFormValues {
	mpan: string;
	flowAndDestinationTypes: string;
	meterIdSerialNumber: string;
	readingType: string;
	siteVisitCheckCode: string;
	additionalInformation: string | null;
	doesHaveASecondRegister: "Y" | "N";
	meterRegisterIdOne: string;
	timePatternRegimeOne: string | null;
	readingDateTimeOne: string;
	registerReadingOne: string;
	readingMethodOne: string | null;
	meterRegisterIdTwo: string;
	timePatternRegimeTwo: string | null;
	readingDateTimeTwo: string;
	registerReadingTwo: string;
	readingMethodTwo: string | null;
}

export interface S33FlowRequest {
	mpan: string;
	flowAndDestinationTypes: string;
	meterIdSerialNumber: string;
	readingType: string;
	siteVisitCheckCode: string;
	additionalInformation: string | null;
	meterRegisterDetailsPerMeter: MeterRegisterDetailPerMeter[];
}

export interface MeterRegisterDetailPerMeter {
	meterRegisterId: string;
	timePatternRegime: string | null;
	readingDateTime: string;
	registerReading: string;
	readingMethod: string | null;
	meterReadingFlag: boolean | null;
	readingOverrideFlag: boolean;
}

export const D0010_TYPES: DropDownItem[] = [
	{ display: "D0010D", value: "D0010D" },
	{ display: "D0010R", value: "D0010R" },
	{ display: "D0010X", value: "D0010X" },
];

export const READING_METHOD_TYPES: DropDownItem[] = [
	{ display: "N", value: "N" },
	{ display: "P", value: "P" },
];
