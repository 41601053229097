import * as React from "react";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

export const Loading: React.FC = () => {
	return (
		<Backdrop
			sx={{
				color: "#0A9828",
				backgroundColor: "#ededed",
				zIndex: (theme) => theme.zIndex.drawer + 1,
			}}
			open={true}
		>
			<CircularProgress color="inherit" aria-label="loading" />
		</Backdrop>
	);
};
