import { PagedRequestsPage } from "../requests-list-page";
import { getF09Flows } from "../../api/talos";
import { F09FlowsResponse } from "../../models/talos/f09-flows";
import { flowsTable } from "../../components/tables/flows-table";
import { formatJavaStyleDateString } from "../../utilities";

const responseKey = (key: string): key is keyof F09FlowsResponse =>
	[
		"id",
		"flowId",
		"userId",
		"mpan",
		"status",
		"cause",
		"flowType",
		"createdAt",
		"updatedAt",
	].includes(key);

const DomesticPremiseIndicatorTable = flowsTable<F09FlowsResponse>({
	columns: [
		{ label: "ID", key: "id" },
		{ label: "MPAN", key: "mpan" },
		{
			label: "Domestic Premise Indicator",
			key: "domesticPremisesIndicator",
		},
		{
			label: "Created",
			key: "createdAt",
			formatter: formatJavaStyleDateString,
		},
	],
	dataKey: "f09",
	getId: (response: F09FlowsResponse) => response.id,
	getColumnContent: (columnKey, response) => {
		// Look up value based on where in the response type the column key exists.
		if (responseKey(columnKey)) return response[columnKey];

		// Otherwise must be domestic premise indicator
		return response.data.domesticPremisesIndicatorUpdateF09
			.domesticPremisesIndicator;
	},
});

export const F09Flows = () => (
	<PagedRequestsPage<F09FlowsResponse>
		title="Domestic Premise Indicator Update"
		requestsName={"Domestic Premise Indicator Update"}
		requestsKey={"f09-flows"}
		getRequests={getF09Flows}
		RequestsTable={DomesticPremiseIndicatorTable}
		additionLink={"/add-f09-flow"}
		getRequestsOptions={{}}
	/>
);
