import { Box, MenuItem } from "@mui/material";
import { Form, Formik } from "formik";
import { UseMutationResult } from "react-query";
import { AxiosResponse } from "axios";

import {
	CONSENT_GRANULARITY,
	IUpdateConsentGranularityFormValues,
	IUpdateConsentGranularity,
} from "../../../models/metering";
import {
	TalosButton,
	TalosDatePicker,
	TalosDropDown,
	TalosTextBox,
} from "../../forms";
import { updateConsentFormValidationSchema } from "./update-consent-granularity-form.validation-schema";

interface IProps {
	updateConsentGranularityMutation: UseMutationResult<
		AxiosResponse,
		Error,
		IUpdateConsentGranularity
	>;
	formData: IUpdateConsentGranularityFormValues;
}

export const UpdateConsentGranularityForm: React.FC<IProps> = ({
	updateConsentGranularityMutation,
	formData,
}) => {
	return (
		<Formik
			enableReinitialize
			onSubmit={(
				values: IUpdateConsentGranularityFormValues,
				{ resetForm }
			) => {
				const requestValues = mapFormToValues(values);
				return updateConsentGranularityMutation.mutate(requestValues, {
					onSuccess: () => resetForm(),
				});
			}}
			initialValues={formData}
			validationSchema={updateConsentFormValidationSchema}
			validateOnChange={true}
		>
			{(form) => (
				<Form>
					<Box className="form-wrapper">
						<Box className="form-column">
							<TalosTextBox
								fieldName="mpan"
								label="MPAN *"
								form={form}
								sx={{ my: 1, width: "100%" }}
							/>
							<Box sx={{ my: 1, width: "100%" }}>
								<TalosDropDown
									fieldName="granularity"
									label="Consent Granularity *"
									form={form}
									menuItems={CONSENT_GRANULARITY.map((wl, idx) => (
										<MenuItem
											key={`consent_granularity_${idx}`}
											value={wl.value}
										>
											{wl.display}
										</MenuItem>
									))}
								/>
							</Box>
							<Box sx={{ my: 1, width: "100%" }}>
								<TalosDatePicker
									fieldName="effectiveFrom"
									label="Effective Date From *"
									form={form}
								/>
							</Box>
							<TalosButton
								fieldName="form_submit"
								form={form}
								buttonText="Submit"
								loading={updateConsentGranularityMutation.isLoading}
							/>
						</Box>
					</Box>
				</Form>
			)}
		</Formik>
	);
};

const mapFormToValues = (
	values: IUpdateConsentGranularityFormValues
): IUpdateConsentGranularity => {
	return values;
};
