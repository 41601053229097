import { Box } from "@mui/material";
import { Field, FormikProps } from "formik";
import { DateField } from "@mui/x-date-pickers/DateField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";

import { getValueFromForm } from "../../utilities";

interface DateFieldProps<T> {
	fieldName: string;
	label: string;
	form: FormikProps<T>;
	disabled?: boolean;
	maxDate?: Date;
}

export const TalosDateField = <T extends object>(
	props: DateFieldProps<T>
): JSX.Element => {
	const fieldValue = getValueFromForm(props.fieldName, props.form.values);
	const hasError = getValueFromForm(props.fieldName, props.form.errors);

	return (
		<LocalizationProvider dateAdapter={AdapterDateFns}>
			<Box className="form-row">
				<Field
					className="form-field"
					data-cy={props.fieldName}
					value={
						// Incoming value must be a date or undefined, not an empty string
						// which is used by some forms as the initial value.
						fieldValue ? new Date(fieldValue) : undefined
					}
					label={props.label}
					helperText={hasError}
					name={props.fieldName}
					error={!!hasError}
					disabled={props.disabled}
					as={DateField}
					maxDate={props.maxDate}
					format="dd/MM/yyyy"
					onChange={(newValue: any) =>
						props.form.setFieldValue(props.fieldName, newValue)
					}
					onBlur={() => {
						// Although this is a "date picker" it's using the underlying JS
						// Date object as it's value which can cause issues with
						// timezones when submitting to other APIs. For example if there is
						// a TZ offset we can end up with a date which is not what we expect.
						// This adjusts the date value such that the date returned for this
						// date field is always what is entered by the user.
						// This has to be done in the onBlur event rather than in the
						// onChange event as modifying the date value as you type results
						// in valid but incorrect values being set and and sometimes
						// sent to the server.
						const adjustedValue = fieldValue
							? fieldValue instanceof Date && isFinite(fieldValue.getTime())
								? fieldValue.getTime() - fieldValue.getTimezoneOffset() * 60000
								: fieldValue
							: undefined;

						props.form.setFieldValue(props.fieldName, new Date(adjustedValue));
					}}
				/>
			</Box>
		</LocalizationProvider>
	);
};

export const dateWithoutTimezone = (date: Date) => {
	const tzoffset = date.getTimezoneOffset() * 60000; //offset in milliseconds
	const withoutTimezone = new Date(date.valueOf() - tzoffset)
		.toISOString()
		.slice(0, -1);
	return withoutTimezone;
};
