import { Box, Button } from "@mui/material";
import { FormikProps } from "formik";
import { LoadingButton } from "@mui/lab";

interface IProps<T> {
	fieldName: string;
	buttonText: string;
	form: FormikProps<T>;
	loading: boolean;
	enableIfNotDirty?: boolean;
	onReset?: () => void;
	disabled?: boolean;
}

export const TalosButton = <T extends object>(props: IProps<T>) => {
	return (
		<Box className="form-row form-row-right">
			{props.onReset ? (
				<Button
					variant="outlined"
					onClick={() => props.onReset!()}
					sx={{ marginRight: "10px" }}
					data-cy={`${props.fieldName}-reset`}
				>
					Reset
				</Button>
			) : null}
			<LoadingButton
				type="submit"
				variant="contained"
				color="primary"
				loading={props.loading}
				data-cy={props.fieldName}
				disabled={
					props.disabled ||
					props.loading ||
					!props.form.isValid ||
					(props.enableIfNotDirty ? false : !props.form.dirty)
				}
			>
				{props.buttonText}
			</LoadingButton>
		</Box>
	);
};
