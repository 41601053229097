import * as Yup from "yup";
import { DateSchema } from "yup";
import { FormikValues } from "formik";

import { usefulRegex } from "../utilities";
import {
	isBooleanField,
	isDateField,
	isMpanField,
	isNumericField,
	isRadioField,
	isSection,
	isSelectField,
	isStringField,
} from "./form.utilities";
import {
	BooleanFieldDescription,
	DateFieldDescription,
	FieldDescription,
	MpanFieldDescription,
	NumericFieldDescription,
	RadioFieldDescription,
	SectionDescription,
	SelectFieldDescription,
	SimpleFormDescription,
	StringFieldDescription,
} from "./form.types";
import { chain } from "../utilities/chain";

type FieldValidatorEntry = [string, Yup.Schema];

/**
 * Combines two validator functions. If either function is undefined the
 * other validator is returned. If the two validators contain any duplicate
 * configurations, the values from validator2 will take precedence.
 */
const combineValidations =
	(
		validator1: ((schema: Yup.Schema) => Yup.Schema) | undefined,
		validator2: ((schema: Yup.Schema) => Yup.Schema) | undefined
	) =>
	(schema: Yup.Schema) => {
		if (validator1 === undefined) return validator2?.(schema);
		if (validator2 === undefined) return validator1?.(schema);
		return validator1(validator2(schema));
	};

const selectValidator = (selectFieldDescription: SelectFieldDescription) =>
	chain(Yup.string().label(selectFieldDescription.label))
		.if(selectFieldDescription.required, (validator: Yup.StringSchema) =>
			validator.required()
		)
		.if(
			selectFieldDescription.customValidation,
			selectFieldDescription.customValidation
		)
		.end();

const stringValidator = (stringFieldDescription: StringFieldDescription) =>
	chain(Yup.string().label(stringFieldDescription.label))
		.if(stringFieldDescription.required, (validator: Yup.StringSchema) =>
			validator.required()
		)
		.if(
			stringFieldDescription.minLength !== undefined,
			(validator: Yup.StringSchema) =>
				validator.min(stringFieldDescription.minLength!)
		)
		.if(
			stringFieldDescription.maxLength !== undefined,
			(validator: Yup.StringSchema) =>
				validator.max(stringFieldDescription.maxLength!)
		)
		.if(
			stringFieldDescription.customValidation,
			stringFieldDescription.customValidation
		)
		.end();

const booleanValidator = (fieldDescription: BooleanFieldDescription) =>
	chain(Yup.boolean().label(fieldDescription.label))
		.if(fieldDescription.required, (validator: Yup.StringSchema) =>
			validator.required()
		)
		.if(fieldDescription.customValidation, fieldDescription.customValidation)
		.end();

const mpanValidator = (mpanFieldDescription: MpanFieldDescription) => {
	const label = mpanFieldDescription.label || "MPAN";
	return chain(
		Yup.string()
			.label(label)
			.matches(usefulRegex.MPAN, `${label} must be a 13 digit integer`)
	)
		.if(mpanFieldDescription.required ?? true, (validator: Yup.StringSchema) =>
			validator.required()
		)
		.if(
			mpanFieldDescription.customValidation,
			mpanFieldDescription.customValidation
		)
		.end();
};

const dateValidator = (dateFieldDescription: DateFieldDescription) =>
	chain(
		Yup.date()
			.label(dateFieldDescription.label)
			.typeError(({ label }) => `${label} must be a valid date`)
	)
		.if(dateFieldDescription.required, (validator: DateSchema) =>
			validator.required()
		)
		.if(dateFieldDescription.min, (validator: Yup.DateSchema) =>
			validator.min(dateFieldDescription.min)
		)
		.if(dateFieldDescription.max, (validator: Yup.DateSchema) =>
			validator.max(dateFieldDescription.max)
		)
		.if(
			dateFieldDescription.customValidation,
			dateFieldDescription.customValidation
		)
		.end();

const numericValidator = (numericFieldDescription: NumericFieldDescription) =>
	chain(Yup.number().label(numericFieldDescription.label))
		.if(numericFieldDescription.required, (validator: Yup.NumberSchema) =>
			validator.required()
		)
		.if(
			numericFieldDescription.min !== undefined,
			(validator: Yup.NumberSchema) =>
				validator.min(numericFieldDescription.min!)
		)
		.if(
			numericFieldDescription.max !== undefined,
			(validator: Yup.NumberSchema) =>
				validator.max(numericFieldDescription.max!)
		)
		.if(
			numericFieldDescription.customValidation,
			numericFieldDescription.customValidation
		)
		.end();

const radioValidator = (radioFieldDescription: RadioFieldDescription) =>
	chain(Yup.string().label(radioFieldDescription.label))
		.if(radioFieldDescription.required, (validator: Yup.StringSchema) =>
			validator.required()
		)
		.if(
			radioFieldDescription.customValidation,
			radioFieldDescription.customValidation
		)
		.end();

const validatorsFromFieldDescriptions = (
	fieldDescriptions: SectionDescription[] | FieldDescription[]
): FieldValidatorEntry[] =>
	fieldDescriptions.flatMap((fieldOrSection) => {
		// Unfortunately switch statements don't allow for smart casting so directly
		// checking the type of the fieldOrSection and providing switch statement
		// with `true` as the condition is the tidiest way currently available.
		// Support for this form was added in Typescript 5.3.
		switch (true) {
			case isMpanField(fieldOrSection):
				// Using double brackets because of the flatMap above, otherwise the
				// entry elements will be lifted into the top level array.
				return [[fieldOrSection.fieldName, mpanValidator(fieldOrSection)]];
			case isBooleanField(fieldOrSection):
				return [[fieldOrSection.fieldName, booleanValidator(fieldOrSection)]];
			case isDateField(fieldOrSection):
				return [[fieldOrSection.fieldName, dateValidator(fieldOrSection)]];
			case isNumericField(fieldOrSection):
				return [[fieldOrSection.fieldName, numericValidator(fieldOrSection)]];
			case isRadioField(fieldOrSection):
				return [[fieldOrSection.fieldName, radioValidator(fieldOrSection)]];
			case isStringField(fieldOrSection):
				return [[fieldOrSection.fieldName, stringValidator(fieldOrSection)]];
			case isSelectField(fieldOrSection):
				return [[fieldOrSection.fieldName, selectValidator(fieldOrSection)]];
			case isSection(fieldOrSection): {
				// If the section has an exclude from form option then it may be removed
				// from the form then we want to remove any required validation.
				if (fieldOrSection.excludeFromForm !== undefined) {
					const excludedValidator = (schema: Yup.Schema): Yup.Schema =>
						// "$" is the root context of the validation operation, which is all the form values
						// we pass this to the section's excludeFromForm function to determine if the
						// section should be excluded and if so, we mark the supplied schema
						// not required.
						schema.when("$", ([values], schema) => {
							let excludeFromForm = fieldOrSection.excludeFromForm?.(values);
							// If a field should be excluded as part of a section replace it's schema with a `mixed` schema with no
							// conditions. `mixed` is used for any/unknown types.
							return excludeFromForm ? Yup.mixed() : schema;
						});

					// Map over the fields within this section and combine their custom validation
					// methods with the excluded validator so that, if the current section is excluded
					// the fields within it are marked as not required.
					const possiblyExcludedFields = fieldOrSection.fields.map(
						(fieldDescription) => ({
							...fieldDescription,
							customValidation: combineValidations(
								excludedValidator,
								fieldDescription.customValidation as
									| ((schema: Yup.Schema) => Yup.Schema)
									| undefined
							),
						})
					);
					// Recurse into fields property of sections, these will be hoisted to
					// the top level of the validator object by flatMap.
					return validatorsFromFieldDescriptions(possiblyExcludedFields);
				}
				// Recurse into fields property of sections, these will be hoisted to
				// the top level of the validator object by flatMap.
				return validatorsFromFieldDescriptions(fieldOrSection.fields);
			}
			default:
				throw new Error("Invalid field type");
		}
	});
export const generateValidationSchema = <
	TRequest,
	TFormValues extends FormikValues
>(
	value: SimpleFormDescription<TRequest, TFormValues>
) => {
	const validatorEntries = validatorsFromFieldDescriptions(value.fields);
	return Yup.object().shape(Object.fromEntries(validatorEntries));
};
