import React from "react";

import {
	Box,
	FormControl,
	FormControlLabel,
	FormLabel,
	Radio,
	RadioGroup,
} from "@mui/material";
import { FormikProps } from "formik";

import { getValueFromForm } from "../../utilities";

interface RadioGroupProps<T> {
	fieldName: string;
	options: { value: string; label: string }[];
	postOnChange?: (
		event: React.ChangeEvent<HTMLInputElement>,
		value: string
	) => void;
	form: FormikProps<T>;
	label?: string;
	row?: boolean;
}

export const TalosRadioGroup = <T extends object>(
	props: RadioGroupProps<T>
) => {
	const fieldValue = getValueFromForm(props.fieldName, props.form.values);

	return (
		<Box className="form-row">
			<FormControl component="fieldset">
				{props.label && <FormLabel>{props.label}</FormLabel>}
				<RadioGroup
					name={props.fieldName}
					value={fieldValue}
					data-cy={`radio-${props.fieldName}`}
					onChange={(event) => {
						props.form.setFieldValue(
							props.fieldName,
							event.currentTarget.value
						);

						if (props.postOnChange) {
							props.postOnChange(event, event.currentTarget.value);
						}
					}}
					row={props.row}
				>
					{props.options.map((opt, idx) => (
						<FormControlLabel
							key={`radio_option_${opt.value}_${idx}`}
							value={opt.value}
							control={
								<Radio data-cy={`radio-${props.fieldName}-${opt.value}`} />
							}
							label={opt.label}
						/>
					))}
				</RadioGroup>
			</FormControl>
		</Box>
	);
};
