export enum ContractType {
	SEG = "SEG",
	FIT = "FIT",
	PPA = "PPA",
	V2G = "V2G",
}

export enum RegistrationType {
	NEW = "NEW",
	GAIN = "GAIN",
	COS = "COS",
}

export enum ReadingType {
	HH = "HH",
	NHH = "NHH",
}

export enum PSR {
	NEW = "NEW",
	GAIN = "GAIN",
}

export enum Supplier {
	OVO = "OVO",
	SSE = "SSE",
	Other = "Other",
}

export enum ElectiveSettlementHHIndicator {
	T = "T",
	F = "F",
}

export enum RegistrationPhase {
	SUBMISSION = "SUBMISSION",
	SWITCH_WINDOW = "SWITCH_WINDOW",
	SWITCH_CONFIRMED = "SWITCH_CONFIRMED",
}

export enum CustomerPriorityServices {
	NEBULISER_AND_APNOEA_MONITOR = "1",
	HEART_AND_LUNG_VENTILATOR = "2",
	DIALYSIS_FEEDING_PUMP_AND_AUTOMATED_MEDICATION = "3",
	OXYGEN_CONCENTRATOR = "4",
	BLIND = "8",
	PARTIALLY_SIGHTED = "9",
	DO_NOT_USE = "10",
	STAIR_LIFT_HOIST_ELECTRIC_BED = "12",
	PENSIONABLE_AGE = "14",
	PHYSICAL_IMPAIRMENT = "15",
	UNABLE_TO_COMMUNICATE_IN_ENGLISH = "17",
	DEVELOPMENTAL_CONDITION = "18",
	UNABLE_TO_ANSWER_DOOR = "19",
	DEMENTIAS_COGNITIVE_IMPAIRMENT = "20",
	CHRONIC_SERIOUS_ILLNESS = "22",
	MEDICALLY_DEPENDENT_SHOWERING_BATHING = "23",
	CARELINE_TELECARE_SYSTEM = "24",
	MEDICINE_REFRIGERATION = "25",
	OXYGEN_USE = "26",
	POOR_SENSE_OF_SMELL_TASTE = "27",
	RESTRICTED_HAND_MOVEMENT = "28",
	FAMILIES_WITH_YOUNG_CHILDREN_5_OR_UNDER = "29",
	MENTAL_HEALTH = "30",
	ADDITIONAL_PRESENCE_PREFERRED = "31",
	TEMPORARY_LIFE_CHANGES = "32",
	TEMPORARY_POST_HOSPITAL_RECOVERY = "33",
	TEMPORARY_YOUNG_ADULT_HOUSEHOLDER = "34",
	HEARING_IMPAIRMENT_INC_DEAF = "35",
	SPEECH_IMPAIRMENT = "36",
	WATER_DEPENDENT = "37",
}

export interface RegistrationSimple {
	id: string;
	customerName: string;
	meterSerialNumber: string;
	type: string;
	readingType: string;
	importMpan: string;
	exportMpan: string;
	aregiSubmitted: boolean;
	registrationPhase: string;
	registrationState: string;
	additionalNotes: string;
}

export interface RegistrationsResponse {
	items: RegistrationSimple[];
}

export interface RegistrationDetailsResponse {
	id: string;
	updatedBy: string;
	createdBy: string;
	createdDate: string;
	customerDetail: {
		id: string;
		name: string;
		postcode: string;
		address: string;
		priorityServices: {
			id: string;
			category: string;
			expiryDate: string;
		}[];
	};
	registrationDetail: {
		id: string;
		meterSerialNumber: string;
		importSupplier: string;
		importMpan: string;
		ecoesRaised: string;
		contractEnd: string;
		contractStart: string;
		exportMpan: string;
		mpanCreatedDate: string;
		ssc: string;
		meterTimeSwitchCode: string;
		standardSettlementConfigId: string;
		meterOperatorId: string;
		dataAggregatorId: string;
		dataCollectorId: string;
		d0217Received: string;
		mtdsReceived: string;
		d0010iReceived: string;
		d0019Received: string;
		additionalNotes: string;
		electiveSettlementHHIndicator: string;
		retrievalMethod: string;
		registrationType: string;
		contractType: string;
		readingType: string;
		isDomesticPremises: boolean | null;
		processState: {
			id: string;
			processPhase: string;
			processStatus: string;
			dateEnteredProcessPhase: string;
			dateLastUpdated: string;
			flowDetail: {
				id: string;
				name: string;
				status: string;
				message: string;
			};
		};
	};
}
export interface EcoesRegistration {
	referenceNumber: string;
	yourReference: string;
	requestType: string;
	status: string;
	obligationDate: string;
	addressLine1: string;
	addressLine2: string;
	addressLine3: string;
	addressLine4: string;
	addressLine5: string;
	addressLine6: string;
	addressLine7: string;
	addressLine8: string;
	addressLine9: string;
	postcode: string;
	distributor: string;
	newMpan: string;
	associatedMpan: string;
	submittedDate: string;
	submittedBy: string;
	updatedDate: string;
	updatedBy: string;
}

export interface EcoesRegistrationImportPlan {
	registrationsAbleToStart: EcoesRegistration[];
	registrationsNotAbleToStart: EcoesRegistration[];
}
