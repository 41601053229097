import React from "react";

import Keycloak from "keycloak-js";

export interface IAuthContext {
	userName: string;
	email: string;
	provider?: Keycloak;
	hasRole: (roleName: string) => boolean;
	hasAnyRole: () => boolean;
	hasTalosPermissions: () => boolean;
}

export const AuthContext = React.createContext<IAuthContext>({
	userName: "",
	email: "",
	provider: undefined,
	hasRole: () => false,
	hasAnyRole: () => false,
	hasTalosPermissions: () => false,
});

export const useAuth = (): IAuthContext => React.useContext(AuthContext);
