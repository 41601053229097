import React from "react";

import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from "@mui/material";

import {
	D0052FlowsResponse,
	D0052Object,
} from "../../models/talos/d0052-flows";
import { formatJavaStyleDateString } from "../../utilities";
import { ExpandableComponent } from "../tables/ExpandingCell";

interface IProps {
	results: D0052FlowsResponse[];
}

const nameMap: Record<string, string> = {
	mpxn: "MPAN",
	mpan_efd: "MPAN EFD",
	profile_class: "Profile Class",
	profile_class_efd: "Profile Class EFD",
	measurement_class_id: "Measurement Class ID",
	measurement_class_efd: "Measurement Class EFD",
	ssc: "SSC",
	scon_date: "SCON Date",
	gsp: "GSP",
	gsp_efd: "GSP EFD",
	day_tpr: "Day/Anytime TPR",
	day_eac: "Day/Anytime EAC",
	day_eac_efd: "Day/Anytime EAC EFD",
	night_tpr: "Night TPR",
	night_eac: "Night EAC",
	night_eac_efd: "Night EAC EFD",
};

export const GSP_DROPDOWN: string[] = [
	"_A",
	"_B",
	"_C",
	"_D",
	"_E",
	"_F",
	"_G",
	"_H",
	"_J",
	"_K",
	"_L",
	"_M",
	"_N",
	"_P",
];

export const PROFILE_CLASS: string[] = [
	"0",
	"1",
	"2",
	"3",
	"4",
	"5",
	"6",
	"7",
	"8",
];

const intoHumanReadable = (data: D0052Object) => {
	const convertedObj: Record<string, string | null | number> = {};
	for (const key in data) {
		if (Object.prototype.hasOwnProperty.call(data, key)) {
			const newKey = nameMap[key as keyof typeof nameMap] || key;
			if (data[key]) {
				convertedObj[newKey] = data[key];
			}
		}
	}
	return convertedObj;
};

const formatJSON = (json: object) => {
	return Object.entries(json)
		.map(([key, value]) => `${key}: ${value}`)
		.join(", ");
};

const objToTemplate = (obj: object) => {
	return (
		<div>
			{Object.entries(obj).map(([key, value]) => {
				const newKey = nameMap[key as keyof typeof nameMap] || key;
				return (
					<div key={key}>
						<span style={{ fontWeight: "bold" }}>{newKey}: </span>
						<span>{value}</span>
					</div>
				);
			})}
		</div>
	);
};

const truncate = (text: string, size: number) => {
	return text.substring(0, size) + "...";
};

export const D0052FlowsTable: React.FC<IProps> = (props: IProps) => {
	return (
		<TableContainer>
			<Table size="small" data-cy="d0052-flows-table">
				<TableHead>
					<TableRow sx={{ background: "#efefef" }}>
						<TableCell data-cy="d0052-cell-id">ID</TableCell>
						<TableCell data-cy="d0052-cell-user-id">User ID</TableCell>
						<TableCell data-cy="d0052-cell-form-data">Form Data</TableCell>
						<TableCell data-cy="d0052-cell-mpan">MPAN</TableCell>
						<TableCell data-cy="d0052-cell-status">Status</TableCell>
						<TableCell data-cy="d0052-cell-flow-type">Flow Type</TableCell>
						<TableCell data-cy="d0052-cell-submitted">Submitted</TableCell>
						<TableCell data-cy="d0052-cell-last-updated">
							Last Updated
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{props.results.map((result) => (
						<TableRow key={result.id} data-cy={`flow-row-${result.id}`}>
							<TableCell>{result.id}</TableCell>
							<TableCell>{result.user_id}</TableCell>
							<TableCell>
								<ExpandableComponent
									dataCy={`expandable-component-${result.id}`}
									smallTemplate={truncate(
										formatJSON(intoHumanReadable(result.data)),
										50
									)}
									expandedTemplate={objToTemplate(
										intoHumanReadable(result.data)
									)}
								/>
							</TableCell>
							<TableCell>{result.mpxn}</TableCell>
							<TableCell>
								{result.cause
									? `${result.status}: ${result.cause}`
									: result.status}
							</TableCell>
							<TableCell>{result.flow_type}</TableCell>
							<TableCell>
								{formatJavaStyleDateString(result.created_at)}
							</TableCell>
							<TableCell>
								{formatJavaStyleDateString(result.updated_at)}
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
};
