import { DateField, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Field, FormikProps } from "formik";

interface IDateFieldProps<T> {
	value: string;
	name: string;
	label: string;
	helperText?: string;
	error?: boolean;
	form: FormikProps<T>;
}

export const ThemisDateField = <T extends object>(
	props: IDateFieldProps<T>
): JSX.Element => {
	return (
		<LocalizationProvider dateAdapter={AdapterDateFns}>
			<Field
				name={props.name}
				label={props.label}
				as={DateField}
				form={props.form}
				value={props.value ? new Date(props.value) : undefined}
				onChange={(newValue: any) =>
					props.form.setFieldValue(props.name, newValue)
				}
				error={props.error}
				helperText={props.helperText}
				format="dd/MM/yyyy"
			/>
		</LocalizationProvider>
	);
};
