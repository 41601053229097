import React from "react";

import { format } from "date-fns";

import { addUT003FlowsRequest } from "../../api/talos";
import {
	UT003Request,
	UT003FlowFormValues,
} from "../../models/talos/ut003-flows";
import { AddUT003FlowForm } from "../../components/ut003";
import { AdditionPage } from "../addition-page";

const initialValues: UT003FlowFormValues = {
	read: 0,
	read_date: "",
	read_source: "",
	read_reason: "",
	msn: "",
	rtc: "",
	y_flag: "",
};

const mapFormToValues = (values: UT003FlowFormValues): UT003Request => {
	return {
		...values,
		read_date: values.read_date
			? format(new Date(values.read_date), "yyyy-MM-dd")
			: "",
		rtc: +values.rtc,
		y_flag: values.y_flag == "Y",
	};
};

export const AddUT003Flow = () => (
	<AdditionPage<UT003Request, UT003FlowFormValues>
		additionFormKey={"add-ut0003-flow"}
		additionInstanceName="Gas Read Replacement"
		underlyingFlowName="UT003"
		mapFormToValues={mapFormToValues}
		addInstanceRequest={addUT003FlowsRequest}
		AddInstanceForm={AddUT003FlowForm}
		initialValues={initialValues}
		listPage={{
			link: "/ut003-flows",
			subject: "replacement requests",
			title: "Gas Read Replacement Requests",
		}}
	/>
);
