import * as Yup from "yup";

import { usefulRegex } from "../../utilities";

export const formValidationSchema = Yup.object().shape({
	mpan: Yup.string()
		.matches(usefulRegex.MPAN, "MPAN must be a 13 digit integer")
		.required("MPAN is required"),

	energisation_status: Yup.string().required(),

	profile_class_id: Yup.number()
		.min(1, "Profile class ID must be between 1 and 8")
		.max(8, "Profile class ID must be between 1 and 8")
		.required("Profile class ID is required"),

	ssc: Yup.string()
		.matches(usefulRegex.SSC, "SSC must be a 4 digit integer")
		.required("SSC is required"),

	meter_type: Yup.string().required(),
	update_ssc: Yup.string().required(),
});
