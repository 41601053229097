import { FC, Fragment } from "react";

import { FieldArray, FormikProps } from "formik";
import { Button, Divider, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import {
	IMOP04v10,
	initialInstalledCorrectingInstalledMeters24FormValues,
	initialRemovedCorrectingRemovedMetersM27FormValues,
} from "../../../models/outbound";
import { InstalledCorrectingInstalledMetersForm } from "./installed-correcting-installed-meters-form";
import { RemovedCorrectingRemovedMetersForm } from "./removed-correcting-removed-meters-form";
import { talosColours } from "../../../styles/talos-colors";

interface IExchangeFormProps {
	form: FormikProps<IMOP04v10>;
}

export const InstallForm: FC<IExchangeFormProps> = ({ form }) => (
	<FieldArray
		name="installedCorrectingInstalledMeters"
		render={(arrayHelpers) => (
			<Fragment>
				{form.values.installedCorrectingInstalledMeters?.map(
					(_installedCorrectingInstalledMeter, index) => (
						<Fragment key={`installedCorrectingInstalledMeters-${index}`}>
							<Divider variant="middle" sx={{ height: "0.5rem" }} />
							<Typography variant="h3" color={talosColours.green.main}>
								{`Installed Correcting Installed Meters ${index + 1}`}
							</Typography>
							<InstalledCorrectingInstalledMetersForm
								form={form}
								m24Index={index}
								removeM24Group={() => arrayHelpers.remove(index)}
							/>
						</Fragment>
					)
				)}
				<Button
					type="button"
					data-cy="add-installed-correcting-installed-meters-group"
					variant="outlined"
					startIcon={<AddIcon />}
					onClick={() =>
						arrayHelpers.push(
							initialInstalledCorrectingInstalledMeters24FormValues
						)
					}
				>
					Add Installed/Correcting Installed Meters Group
				</Button>
			</Fragment>
		)}
	/>
);

export const RemoveForm: FC<IExchangeFormProps> = ({ form }) => (
	<FieldArray
		name="removedCorrectingRemovedMeters"
		render={(arrayHelpers) => (
			<Fragment>
				{form.values.removedCorrectingRemovedMeters?.map(
					(_removedCorrectingRemovedMeters, index) => (
						<Fragment key={`removedCorrectingRemovedMeters-${index}`}>
							<Divider variant="middle" sx={{ margin: "1rem 0" }} />
							<Typography variant="h3" color={talosColours.red.light}>
								{`Removed Correcting Removed Meters ${index + 1}`}
							</Typography>
							<RemovedCorrectingRemovedMetersForm
								form={form}
								m27Index={index}
								removeM27Group={() => arrayHelpers.remove(index)}
							/>
						</Fragment>
					)
				)}
				<Button
					type="button"
					data-cy="add-removed-correcting-removed-meters-group"
					variant="outlined"
					startIcon={<AddIcon />}
					onClick={() =>
						arrayHelpers.push(
							initialRemovedCorrectingRemovedMetersM27FormValues
						)
					}
				>
					Add Removed/Correcting Removed Meters Group
				</Button>
			</Fragment>
		)}
	/>
);

export const ExchangeForm: FC<IExchangeFormProps> = ({ form }) => (
	<>
		<InstallForm form={form} />
		<RemoveForm form={form} />
	</>
);
