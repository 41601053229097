import React, { useState } from "react";

import { subDays } from "date-fns";
import { Box, Typography, useTheme } from "@mui/material";
import { Form, Formik } from "formik";

import { TalosDatePicker, TalosTextBox } from "../forms";
import { IDateRange } from "../../models/talos";
import { getDaysBetweenDates } from "../../utilities";
import { daysBetweenDatesValidationSchema } from "./days-between-dates-validation-schema";

const initialValues = {
	start: subDays(new Date(), 30),
	end: new Date(),
};

export const DaysBetweenDates: React.FC = () => {
	const [result, setResult] = useState(
		Math.round(getDaysBetweenDates(initialValues.start, initialValues.end))
	);

	const handleOnChange = (values: IDateRange) => {
		const res = Math.round(getDaysBetweenDates(values.start, values.end));

		setResult(res);
	};

	const theme = useTheme();

	return (
		<Box>
			<Formik
				onSubmit={() => {}}
				initialValues={initialValues}
				validate={handleOnChange}
				validationSchema={daysBetweenDatesValidationSchema}
			>
				{(form) => (
					<Form>
						<Box
							className="form-wrapper"
							sx={{
								flexDirection: { xs: "column", md: "row" },
								gap: theme.spacing(5),
								alignItems: "flex-start",
							}}
						>
							<Box className="form-column" sx={{ maxWidth: "300px" }}>
								<Typography variant="h6">Dates</Typography>
								<TalosDatePicker fieldName="start" label="Start" form={form} />
								<TalosDatePicker fieldName="end" label="End" form={form} />
							</Box>
							<Box className="form-column" sx={{ maxWidth: "300px" }}>
								<Typography variant="h6">Result</Typography>
								<TalosTextBox
									fieldName="result"
									label="Result"
									form={form}
									overrideValue={result.toString()}
									sx={theme.customSX.darkTextBox}
									disabled={true}
								/>
							</Box>
						</Box>
					</Form>
				)}
			</Formik>
		</Box>
	);
};
