import { Box, Grid, Pagination, Typography } from "@mui/material";

import { Page } from "./pageable";
import { theme } from "../../styles/theme";

export interface IProps {
	results: Page<any>;
	pageIndex: number;
	handlePageChange: (pageIndex: number) => void;
}

export const ListPagination: React.FC<IProps> = (props: IProps) => {
	function getFirstElementCount(): number {
		return props.results.page.pageIndex * props.results.page.pageSize + 1;
	}

	function getLastElementCount(): number {
		return (
			props.results.page.pageIndex * props.results.page.pageSize +
			props.results.items.length
		);
	}

	return (
		<>
			{props.results.items.length > 0 ? (
				<Grid container spacing={3}>
					<Grid item xs={4}></Grid>
					<Grid item xs={4}>
						<Box display="flex" justifyContent="center">
							<Pagination
								onChange={(_e, pageIndex) => props.handlePageChange(pageIndex)}
								count={props.results.page.totalPages}
								page={props.pageIndex}
								showFirstButton
								showLastButton
								sx={{
									padding: `${theme.spacing(1)} ${theme.spacing(0)}`,
									"& ul": { flexWrap: "nowrap" },
								}}
							/>
						</Box>
					</Grid>
					<Grid item xs={4}>
						<Box display="flex" justifyContent="end">
							<Typography
								sx={{
									fontSize: "medium",
									backgroundColor: theme.palette.talos.colour.grey.lightest,
									border: `1px solid ${theme.palette.action.disabled}`,
									borderRadius: theme.spacing(0.5),
									padding: `${theme.spacing(1)}`,
								}}
							>
								{getFirstElementCount()}
								{" - "}
								{getLastElementCount()}
								{" of "}
								{props.results.page.totalItems}
							</Typography>
						</Box>
					</Grid>
				</Grid>
			) : null}
		</>
	);
};
