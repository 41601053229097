import * as Yup from "yup";

import {
	CustomerPriorityServices,
	RegistrationType,
} from "../../models/themis";

const psrCodesWithRequiredExpiryDate = [
	CustomerPriorityServices.FAMILIES_WITH_YOUNG_CHILDREN_5_OR_UNDER.valueOf(),
	CustomerPriorityServices.TEMPORARY_LIFE_CHANGES.valueOf(),
	CustomerPriorityServices.TEMPORARY_POST_HOSPITAL_RECOVERY.valueOf(),
	CustomerPriorityServices.TEMPORARY_YOUNG_ADULT_HOUSEHOLDER.valueOf(),
];

const sscRegex = RegExp("^\\d+$");

export const formValidationSchema = Yup.object().shape({
	customerDetail: Yup.object({
		name: Yup.string().required("Customer name is required."),
		priorityServices: Yup.array().of(
			Yup.object({
				category: Yup.string().required("PSR Category is required."),
				expiryDate: Yup.string().when("category", {
					is: (value: string) => psrCodesWithRequiredExpiryDate.includes(value),
					then: (schema) =>
						schema.required("This PSR Code requires expiry date."),
					otherwise: (schema) => schema.notRequired(),
				}),
			})
		),
	}),
	registrationDetail: Yup.object({
		isDomesticPremises: Yup.boolean().required(
			"Domestic Premises is required."
		),
		meterSerialNumber: Yup.string().required(
			"Meter Serial Number is required."
		),
		exportMpan: Yup.string().required("Export MPAN is required."),
		contractStart: Yup.string().when("readingType", {
			is: (value: string) => value === "HH",
			then: (schema) =>
				schema.required("Contract Start Date is required for HH gains."),
			otherwise: (schema) => schema.notRequired(),
		}),
		standardSettlementConfigId: Yup.string()
			.required("Standard Settlement Config Id is required.")
			.length(4, "Standard Settlement Config Id must be 4 digits.")
			.matches(sscRegex, "Standard Settlement Config Id must be only digits."),

		meterTimeSwitchCode: Yup.string().required(
			"Meter Timeswitch Code is required."
		),
		electiveSettlementHHIndicator: Yup.string().required(
			"Elective indicator is required."
		),
		retrievalMethod: Yup.string().required("Retrieval Method is required."),
		contractType: Yup.string().required("Contract type is required."),
		ecoesRaised: Yup.string().when("registrationType", {
			is: (value: string) => value !== RegistrationType.NEW,
			then: (schema) => schema.notRequired(),
			otherwise: (schema) =>
				schema.required(
					"Date raised to ECOES is required for this submission type."
				),
		}),
		mpanCreatedDate: Yup.string().when("registrationType", {
			is: (value: string) => value !== RegistrationType.NEW,
			then: (schema) => schema.notRequired(),
			otherwise: (schema) =>
				schema.required(
					"MPAN creation date is required for this submission type."
				),
		}),
		meterOperatorId: Yup.string().required("MOP ID is required."),
		dataAggregatorId: Yup.string().required("Data Aggregator ID is required."),
		dataCollectorId: Yup.string().required("Data Collector ID is required."),
	}),
});
