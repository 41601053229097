import React, { Dispatch, SetStateAction, useEffect } from "react";

import { Alert, Snackbar } from "@mui/material";

import { useCountdown } from "../../utilities";

interface IProps {
	openAlert: boolean;
	setOpenAlert: Dispatch<SetStateAction<boolean>>;
	setSubmitDisabled: Dispatch<SetStateAction<boolean>>;
	interval: number;
}

export const TalosTimerSnackbar: React.FC<IProps> = ({
	openAlert,
	setOpenAlert,
	setSubmitDisabled,
	interval,
}) => {
	const { secondsLeft, startCountdown } = useCountdown(interval);

	useEffect(() => {
		if (secondsLeft <= 0) {
			setOpenAlert(false);
			setSubmitDisabled(false);
			return;
		}
		async () => {
			startCountdown();
		};
	}, [setOpenAlert, setSubmitDisabled, interval, startCountdown, secondsLeft]);

	return (
		<Snackbar
			open={openAlert}
			anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
		>
			<Alert
				data-cy="talos-timer-snackbar"
				severity="error"
				variant="filled"
				sx={{
					alignItems: "center",
					fontSize: "19px",
					height: "100%",
					margin: "20px 0",
					width: "100%",
				}}
			>
				UK Industry Adapter service is experiencing some issues. Please try to
				resubmit the form again after: {secondsLeft} seconds!
			</Alert>
		</Snackbar>
	);
};
