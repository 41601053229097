import { PaletteOptions } from "@mui/material";

export interface DefaultSemanticColors {
	alt: string;
	base: string;
	muted: string;
	translucent: string;
}

export interface FigmaSemanticColors {
	primary: DefaultSemanticColors;
	error: DefaultSemanticColors;
	warning: DefaultSemanticColors;
	info: DefaultSemanticColors;
	focus: {
		text: string;
		base: string;
		highlight: string;
		translucent: string;
	};
	mission: DefaultSemanticColors;
	message: {
		black: string;
		dark: string;
		light: string;
		white: string;
	};
	border: {
		light: string;
		dark: string;
	};
	background: {
		canvas: string;
		white: string;
		translucent: string;
	};
}

export interface FigmaColorOptions {
	darkest: string;
	darker: string;
	dark: string;
	main: string;
	light: string;
	lighter: string;
	lightest: string;
	translucent: string;
	contrastText: string;
}

export interface TalosColors {
	brand: FigmaColorOptions;
	grey: FigmaColorOptions;
	green: FigmaColorOptions;
	red: FigmaColorOptions;
	orange: FigmaColorOptions;
	yellow: FigmaColorOptions;
	teal: FigmaColorOptions;
	blue: FigmaColorOptions;
	purple: FigmaColorOptions;
	pink: FigmaColorOptions;
	white: { base: string };
}

export const talosColours: TalosColors = {
	brand: {
		darkest: "#005538",
		darker: "#006945",
		dark: "#0D8426",
		main: "#0A9828",
		light: "#6CBD27",
		lighter: "#9DC431",
		lightest: "#B6D727",
		translucent: "rgba(13, 132, 38, 0.2)",
		contrastText: "#darkest",
	},
	grey: {
		darkest: "#282E3B",
		darker: "#596277",
		dark: "#8891A5",
		main: "#AFB8CC",
		light: "#D2D8E4",
		lighter: "#E2E5EC",
		lightest: "#F0F3F8",
		translucent: "rgba(40, 46, 59, 0.5)",
		contrastText: "#darkest",
	},
	white: {
		base: "#FFF",
	},
	green: {
		darkest: "#02290A",
		darker: "#055B17",
		dark: "#0D8426",
		main: "#27B445",
		light: "#55D570",
		lighter: "#99EBAA",
		lightest: "#EAFAED",
		translucent: "rgba(39, 180, 69, 0.2)",
		contrastText: "#darkest",
	},
	red: {
		darkest: "#4D0D1C",
		darker: "#8D112E",
		dark: "#C7153E",
		main: "#E5355D",
		light: "#F66485",
		lighter: "#FEA1B7",
		lightest: "#FFE4EB",
		translucent: "rgba(229, 53, 93, 0.2)",
		contrastText: "#darkest",
	},
	orange: {
		darkest: "#541800",
		darker: "#8A2B05",
		dark: "#B84516",
		main: "#D9602F",
		light: "#ED845A",
		lighter: "#F9B397",
		lightest: "#FFE7DE",
		translucent: "rgba(217, 96, 47, 0.2)",
		contrastText: "#darkest",
	},
	yellow: {
		darkest: "#5F5312",
		darker: "#9F8805",
		dark: "#DABA02",
		main: "#FAD710",
		light: "#FFE44A",
		lighter: "#FDEE98",
		lightest: "#FCF5CA",
		translucent: "rgba(250, 215, 16, 0.2)",
		contrastText: "#darkest",
	},
	teal: {
		darkest: "#022529",
		darker: "#07545D",
		dark: "#147D88",
		main: "#2EABB8",
		light: "#5ACEDA",
		lighter: "#9BE8F1",
		lightest: "#E8FDFF",
		translucent: "rgba(46, 171, 184, 0.2)",
		contrastText: "#darkest",
	},
	blue: {
		darkest: "#02364A",
		darker: "#00658D",
		dark: "#028FC8",
		main: "#20ADE6",
		light: "#50C7F7",
		lighter: "#92DFFE",
		lightest: "#DBF5FF",
		translucent: "rgba(2, 143, 200, 0.2)",
		contrastText: "#darkest",
	},
	purple: {
		darkest: "#420075",
		darker: "#5C02A1",
		dark: "#720DBF",
		main: "#9226E4",
		light: "#AD54F1",
		lighter: "#CB93F6",
		lightest: "#E8D8F5",
		translucent: "rgba(146, 38, 228, 0.2)",
		contrastText: "#420075",
	},
	pink: {
		darkest: "#4A0C30",
		darker: "#851154",
		dark: "#B81574",
		main: "#DE1D8D",
		light: "#EF48A9",
		lighter: "#F67FC4",
		lightest: "#F7BADE",
		translucent: "rgba(222, 29, 141, 0.2)",
		contrastText: "#4A0C30",
	},
};

const { brand, green, red, orange, teal, blue, pink, grey, white } =
	talosColours;

export const semanticColours: FigmaSemanticColors = {
	primary: {
		alt: green.darker,
		base: brand.dark,
		muted: green.lightest,
		translucent: green.translucent,
	},
	error: {
		alt: red.darker,
		base: red.dark,
		muted: red.lightest,
		translucent: red.translucent,
	},
	warning: {
		alt: orange.darker,
		base: orange.dark,
		muted: orange.lightest,
		translucent: orange.translucent,
	},
	info: {
		alt: teal.darker,
		base: teal.dark,
		muted: teal.lightest,
		translucent: teal.translucent,
	},
	focus: {
		text: blue.darker,
		base: blue.dark,
		highlight: blue.lightest,
		translucent: blue.translucent,
	},
	mission: {
		alt: pink.dark,
		base: pink.main,
		muted: pink.lightest,
		translucent: pink.translucent,
	},
	message: {
		black: grey.darkest,
		dark: grey.darker,
		light: grey.light,
		white: white.base,
	},
	border: {
		light: grey.lighter,
		dark: grey.light,
	},
	background: {
		canvas: grey.lightest,
		white: white.base,
		translucent: grey.translucent,
	},
};

export const TalosPallette: PaletteOptions = {
	background: {
		default: talosColours.grey.lightest,
	},
	talos: {
		colour: talosColours,
		semantic: semanticColours,
	},
	common: {
		black: talosColours.grey.darkest,
		white: talosColours.white.base,
	},
	primary: {
		light: talosColours.green.lightest,
		main: talosColours.brand.dark,
		dark: talosColours.green.darker,
		contrastText: talosColours.white.base,
	},
	secondary: {
		light: talosColours.grey.main,
		main: talosColours.teal.main,
		dark: semanticColours.error.base,
	},
	success: {
		light: talosColours.green.lightest,
		main: talosColours.green.light,
		dark: talosColours.green.main,
	},
	error: {
		light: semanticColours.error.muted,
		main: talosColours.red.dark,
		dark: semanticColours.error.alt,
	},
	warning: {
		light: semanticColours.warning.muted,
		main: semanticColours.warning.base,
		dark: semanticColours.warning.alt,
	},
	info: {
		light: semanticColours.info.muted,
		main: semanticColours.info.base,
		dark: semanticColours.info.alt,
	},
};
