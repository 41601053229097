import React, { useEffect, useState } from "react";

import { format } from "date-fns";
import { Box, Typography, useTheme } from "@mui/material";
import { Form, Formik } from "formik";

import { IRfCalculationResult, IRfCalculationValues } from "../../models/talos";
import { rfCalculationValidationSchema } from "./final-reconciliation-calculator.validation-schema";
import { getRfCalculation } from "../../utilities";
import { TalosDatePicker, TalosTextBox } from "../forms";

export const FinalReconciliationCalculator: React.FC = () => {
	const [result, setResult] = useState<IRfCalculationResult>();
	const [todaysResult, setTodaysResult] = useState<IRfCalculationResult>();

	const theme = useTheme();

	const handleOnChange = (values: IRfCalculationValues) => {
		const res = getRfCalculation(values);
		setResult(res);
	};

	useEffect(() => {
		const todaysRf = getRfCalculation({ date: new Date() });
		setTodaysResult(todaysRf);
	}, []);

	const formatDate = (d: Date | undefined) => {
		return d ? format(d, "dd / MM / yyyy") : undefined;
	};

	return (
		<Box>
			<Formik
				onSubmit={() => {}}
				initialValues={{
					date: new Date(),
				}}
				validate={handleOnChange}
				validationSchema={rfCalculationValidationSchema}
			>
				{(form) => (
					<Form>
						<Box
							className="form-wrapper"
							sx={{
								flexDirection: { xs: "column", md: "row" },
								gap: theme.spacing(5),
								alignItems: "flex-start",
							}}
						>
							<Box className="form-column" sx={{ maxWidth: "300px" }}>
								<Typography variant="h6">Today&apos;s RF Date</Typography>
								<TalosTextBox
									fieldName="result"
									label="Today"
									form={form}
									overrideValue={formatDate(new Date())}
									sx={theme.customSX.darkTextBox}
									disabled={true}
								/>
								<TalosTextBox
									fieldName="result"
									label="RF DATE"
									form={form}
									overrideValue={
										todaysResult ? formatDate(todaysResult.rfDate) : undefined
									}
									sx={theme.customSX.darkTextBox}
									disabled={true}
								/>
								<TalosTextBox
									fieldName="result"
									label="RF FIX DATE"
									form={form}
									overrideValue={
										todaysResult
											? formatDate(todaysResult.rfFixDate)
											: undefined
									}
									sx={theme.customSX.darkTextBox}
									disabled={true}
								/>
							</Box>
							<Box className="form-column" sx={{ maxWidth: "300px" }}>
								<Typography variant="h6">RF Date</Typography>
								<TalosDatePicker fieldName="date" label="Start" form={form} />
								<TalosTextBox
									fieldName="result"
									label="RF DATE"
									form={form}
									overrideValue={result ? formatDate(result.rfDate) : undefined}
									sx={theme.customSX.darkTextBox}
									disabled={true}
								/>
								<TalosTextBox
									fieldName="result"
									label="RF FIX DATE"
									form={form}
									overrideValue={
										result ? formatDate(result.rfFixDate) : undefined
									}
									sx={theme.customSX.darkTextBox}
									disabled={true}
								/>
							</Box>
						</Box>
					</Form>
				)}
			</Formik>
		</Box>
	);
};
