import { FC } from "react";

import { Field, FormikProps, getIn } from "formik";
import { FormControl, FormHelperText, Stack, TextField } from "@mui/material";

import { IMOP04v10 } from "../../../models/outbound";

interface IRemovedMeterRegistersM28FormProps {
	form: FormikProps<IMOP04v10>;
	m27Index: number;
	m28Index: number;
	removeM28Group: () => void;
}

export const RemovedMeterRegistersM28Form: FC<
	IRemovedMeterRegistersM28FormProps
> = ({ form, m27Index, m28Index }) => {
	return (
		<>
			<Stack flexDirection={"row"} gap={2}>
				<Stack gap={2} flexDirection={"column"} flex={1}>
					<FormControl>
						<Field
							name={`removedCorrectingRemovedMeters[${m27Index}].removedMeterRegisters[${m28Index}].meterRegisterId`}
							label={`Meter Register Id ${m27Index + 1}-${m28Index + 1}*`}
							as={TextField}
							error={
								!!getIn(
									form.errors.removedCorrectingRemovedMeters?.[m27Index],
									`removedMeterRegisters[${m28Index}].meterRegisterId`
								)
							}
							form={form}
						/>
						<FormHelperText
							error={
								!!getIn(
									form.errors.removedCorrectingRemovedMeters?.[m27Index],
									`removedMeterRegisters[${m28Index}].meterRegisterId`
								)
							}
						>
							{getIn(
								form.errors.removedCorrectingRemovedMeters?.[m27Index],
								`removedMeterRegisters[${m28Index}].meterRegisterId`
							)}
						</FormHelperText>
					</FormControl>
					<FormControl>
						<Field
							name={`removedCorrectingRemovedMeters[${m27Index}].removedMeterRegisters[${m28Index}].registerReading`}
							label={`Register Reading ${m27Index + 1}-${m28Index + 1}`}
							as={TextField}
							error={
								!!getIn(
									form.errors.removedCorrectingRemovedMeters?.[m27Index],
									`removedMeterRegisters[${m28Index}].registerReading`
								)
							}
							form={form}
						/>
						<FormHelperText
							error={
								!!getIn(
									form.errors.removedCorrectingRemovedMeters?.[m27Index],
									`removedMeterRegisters[${m28Index}].registerReading`
								)
							}
						>
							{getIn(
								form.errors.removedCorrectingRemovedMeters?.[m27Index],
								`removedMeterRegisters[${m28Index}].registerReading`
							)}
						</FormHelperText>
					</FormControl>
					<FormControl>
						<Field
							name={`removedCorrectingRemovedMeters[${m27Index}].removedMeterRegisters[${m28Index}].registerDescription`}
							label={`Register Description ${m27Index + 1}-${m28Index + 1}`}
							as={TextField}
							multiline
							minRows={4}
							maxRows={4}
							error={
								!!getIn(
									form.errors.removedCorrectingRemovedMeters?.[m27Index],
									`removedMeterRegisters[${m28Index}].registerDescription`
								)
							}
							form={form}
						/>
						<FormHelperText
							error={
								!!getIn(
									form.errors.removedCorrectingRemovedMeters?.[m27Index],
									`removedMeterRegisters[${m28Index}].registerDescription`
								)
							}
						>
							{getIn(
								form.errors.removedCorrectingRemovedMeters?.[m27Index],
								`removedMeterRegisters[${m28Index}].registerDescription`
							)}
						</FormHelperText>
					</FormControl>
				</Stack>
				<Stack gap={2} flexDirection={"column"} flex={1}>
					<FormControl>
						<Field
							name={`removedCorrectingRemovedMeters[${m27Index}].removedMeterRegisters[${m28Index}].prepaymentUnitRate`}
							label={`Prepayment Unit Rate ${m27Index + 1}-${m28Index + 1}`}
							as={TextField}
							error={
								!!getIn(
									form.errors.removedCorrectingRemovedMeters?.[m27Index],
									`removedMeterRegisters[${m28Index}].prepaymentUnitRate`
								)
							}
							form={form}
						/>
						<FormHelperText
							error={
								!!getIn(
									form.errors.removedCorrectingRemovedMeters?.[m27Index],
									`removedMeterRegisters[${m28Index}].prepaymentUnitRate`
								)
							}
						>
							{getIn(
								form.errors.removedCorrectingRemovedMeters?.[m27Index],
								`removedMeterRegisters[${m28Index}].prepaymentUnitRate`
							)}
						</FormHelperText>
					</FormControl>
					<FormControl>
						<Field
							name={`removedCorrectingRemovedMeters[${m27Index}].removedMeterRegisters[${m28Index}].natureOfMaintenance`}
							label={`Nature Of Maintenance ${m27Index + 1}-${m28Index + 1}`}
							as={TextField}
							error={
								!!getIn(
									form.errors.removedCorrectingRemovedMeters?.[m27Index],
									`removedMeterRegisters[${m28Index}].natureOfMaintenance`
								)
							}
							form={form}
						/>
						<FormHelperText
							error={
								!!getIn(
									form.errors.removedCorrectingRemovedMeters?.[m27Index],
									`removedMeterRegisters[${m28Index}].natureOfMaintenance`
								)
							}
						>
							{getIn(
								form.errors.removedCorrectingRemovedMeters?.[m27Index],
								`removedMeterRegisters[${m28Index}].natureOfMaintenance`
							)}
						</FormHelperText>
					</FormControl>
				</Stack>
			</Stack>
		</>
	);
};
