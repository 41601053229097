import { useContext } from "react";

import { useQuery } from "react-query";
import { Box, Divider, Typography, useTheme } from "@mui/material";

import { AuthContext } from "../../auth";
import { getRegistrations } from "../../api/themis";
import { AlertContext, handleError } from "../../utilities";
import { RegistrationsTable } from "../../components";

export const Registrations: React.FC = () => {
	const { setTalosAlert } = useContext(AlertContext);
	const authContext = useContext(AuthContext);

	const theme = useTheme();

	const { data, isLoading } = useQuery(
		["registrations", authContext],
		() => {
			return getRegistrations(authContext);
		},
		{
			onError: (e: Error) => {
				const errorId = handleError(e);
				setTalosAlert({
					message: `Something went wrong fetching registrations, please try again later or contact IOPS Support. Ticket ID: ${errorId}`,
					severity: "error",
				});
			},
			refetchOnWindowFocus: false,
		}
	);

	return (
		<Box>
			<Typography variant="h1" sx={{ marginBottom: theme.spacing(2) }}>
				Registrations
			</Typography>
			<Typography variant="body1">
				This page lists all export registration journeys
			</Typography>
			<Divider sx={{ margin: theme.spacing(2, 0) }} />
			<RegistrationsTable isLoading={isLoading} results={data?.items || []} />
		</Box>
	);
};
