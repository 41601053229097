import { IAuthContext } from "../../auth";
import {
	RegistrationDetailsResponse,
	RegistrationsResponse,
	EcoesRegistrationImportPlan,
	EcoesRegistration,
} from "../../models/themis";
import { baseRequest } from "../common/base-request";
import { talosConfig } from "../../utilities";

function getUrl(endpoint: string) {
	return (talosConfig.isDevelopment ? "" : "export-gain") + endpoint;
}

export const getRegistrations = async (
	authContext: IAuthContext
): Promise<RegistrationsResponse> => {
	const res = await baseRequest(
		authContext,
		"GET",
		getUrl("/registration"),
		undefined,
		undefined
	);

	if (res.status !== 200) {
		throw new Error(`Error calling API ${res}`);
	}

	return res.data;
};

export const getRegistrationById = async (
	authContext: IAuthContext,
	id: string
): Promise<RegistrationDetailsResponse> => {
	const res = await baseRequest(
		authContext,
		"GET",
		getUrl(`/registration/${id}`),
		undefined,
		undefined
	);

	if (res.status !== 200) {
		throw new Error(`Error calling API ${res}`);
	}

	return res.data;
};

export const updateRegistrationDetails = async (
	authContext: IAuthContext,
	registrationDetails: RegistrationDetailsResponse
): Promise<boolean> => {
	const res = await baseRequest(
		authContext,
		"PUT",
		getUrl(`/registration/${registrationDetails.id}`),
		registrationDetails,
		undefined
	);

	if (res.status !== 200) {
		throw new Error(`Error calling API ${res}`);
	}

	return true;
};

export const deleteRegistration = async (
	authContext: IAuthContext,
	registrationId: string
): Promise<boolean> => {
	const res = await baseRequest(
		authContext,
		"DELETE",
		getUrl(`/registration/${registrationId}`),
		registrationId,
		undefined
	);

	if (res.status !== 200) {
		throw new Error(`Error calling API ${res}`);
	}

	return true;
};

export const uploadMigrationRegistrations = async (
	authContext: IAuthContext,
	csvFile: File,
	params: {
		sourceType: string;
	}
): Promise<boolean> => {
	const res = await baseRequest(
		authContext,
		"POST",
		getUrl("/registration/upload"),
		csvFile,
		{
			sourceType: params.sourceType,
		},
		"text/csv"
	);

	if (res.status === 400) {
		throw new Error(`Bad request calling ${res}`);
	}

	if (res.status !== 202) {
		throw new Error(`Error calling API ${res}`);
	}

	return true;
};

export const generateEcoesPlan = async (
	authContext: IAuthContext,
	csvFile: File
): Promise<EcoesRegistrationImportPlan> => {
	const response = await baseRequest(
		authContext,
		"POST",
		getUrl("/registration/ecoes"),
		csvFile,
		undefined,
		"text/csv"
	);

	if (response.status === 400) {
		throw new Error(`Bad request calling ${response}`);
	}

	if (response.status !== 200) {
		throw new Error(`Error calling API ${response.status}`);
	}

	return response.data;
};

export const submitAregi = async (
	authContext: IAuthContext,
	registrationId: string
): Promise<boolean> => {
	const res = await baseRequest(
		authContext,
		"POST",
		getUrl(`/registration/${registrationId}/submit`)
	);

	if (res.status === 400) throw new Error(`Bad request calling ${res}`);
	if (res.status !== 202) throw new Error(`Error calling API calling ${res}`);

	return true;
};

export const addRegistrationsToList = async (
	authContext: IAuthContext,
	registrations: EcoesRegistration[]
): Promise<number> => {
	const res = await baseRequest(
		authContext,
		"POST",
		getUrl("/registration/ecoes/start"),
		registrations
	);

	if (res.status === 400) throw new Error(`Bad request calling ${res}`);
	if (res.status !== 202) throw new Error(`Error calling API calling ${res}`);

	return res.data;
};
