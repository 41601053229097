import { useContext } from "react";

import {
	Box,
	CircularProgress,
	Divider,
	Link,
	Typography,
	useTheme,
} from "@mui/material";
import { useQuery } from "react-query";

import { AlertContext, ROLES, handleError } from "../../utilities";
import { AuthContext } from "../../auth";
import { getUT003Flows } from "../../api/talos";
import { UT003FlowsTable } from "../../components/ut003";

export const UT003Flows: React.FC = () => {
	const theme = useTheme();
	const authContext = useContext(AuthContext);

	const { setTalosAlert } = useContext(AlertContext);

	const { data, isLoading } = useQuery(
		["ut003-flows", authContext],
		() => getUT003Flows(authContext),
		{
			onError: (e: Error) => {
				const errorId = handleError(e);
				setTalosAlert({
					message: `Something went wrong while fetching UT003 Flows, please try again later or contact IOPS Support. Ticket ID: ${errorId}`,
					severity: "error",
				});
			},
		}
	);

	return (
		<Box>
			<Typography variant="h1">Gas Read Replacement Requests</Typography>
			<br />
			{authContext.hasRole(ROLES.FLOWS_ADMIN) && (
				<Typography variant="body1">
					To add another Gas Read Replacement Request{" "}
					<Link href="/add-ut003-flow">click here</Link>
				</Typography>
			)}
			<Divider sx={{ margin: theme.spacing(3, 0) }} />

			{isLoading || !data ? (
				<CircularProgress />
			) : (
				<>
					<UT003FlowsTable results={data} />
				</>
			)}
		</Box>
	);
};
