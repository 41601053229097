import { AxiosResponse } from "axios";

import { updateIHDStatus } from "../../../api/metering";
import {
	initialUpdateIHDStatusFormValues,
	IUpdateIHDStatus,
} from "../../../models/metering";
import { AdditionPage } from "../../addition-page";
import { UpdateIHDStatusForm } from "../../../components";

export const IHDStatusUpdate = () => (
	<AdditionPage<IUpdateIHDStatus, IUpdateIHDStatus, AxiosResponse>
		additionFormKey={"update-ihd-status"}
		header="Update IHD Status"
		summary=""
		mapFormToValues={(values) => values}
		addInstanceRequest={updateIHDStatus}
		AddInstanceForm={UpdateIHDStatusForm}
		initialValues={initialUpdateIHDStatusFormValues}
		successMessage="Successfully submitted Registration Data Change for IHD Status"
		errorMessage={(errorId) =>
			`Something went wrong submitting Registration Data Change for IHD Status. Please contact IOPS Support, Ticket ID: ${errorId}`
		}
	/>
);
