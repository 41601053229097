import * as Yup from "yup";

import { POSITIVE_INTEGERS_ONLY_MSG, usefulRegex } from "../../utilities";

export const readsCalculatorValidationSchema = Yup.object().shape({
	readOneDate: Yup.string().required(),
	readOneRegOne: Yup.string().matches(
		usefulRegex.POSITIVE_INTEGERS_ONLY,
		POSITIVE_INTEGERS_ONLY_MSG
	),
	readOneRegTwo: Yup.string().matches(
		usefulRegex.POSITIVE_INTEGERS_ONLY,
		POSITIVE_INTEGERS_ONLY_MSG
	),
	readTwoDate: Yup.string().required(),
	readTwoRegOne: Yup.string().matches(
		usefulRegex.POSITIVE_INTEGERS_ONLY,
		POSITIVE_INTEGERS_ONLY_MSG
	),
	readTwoRegTwo: Yup.string().matches(
		usefulRegex.POSITIVE_INTEGERS_ONLY,
		POSITIVE_INTEGERS_ONLY_MSG
	),
	ssd: Yup.string().required(),
});
