import * as Yup from "yup";

import {
	dateIsNotValid,
	timePatternRegimeCodes,
	usefulRegex,
} from "../../utilities";

export const formValidationSchema = Yup.object().shape(
	{
		mpan: Yup.string()
			.matches(usefulRegex.MPAN, "MPAN must be a 13 digit integer")
			.required("MPAN is required"),

		flowAndDestinationTypes: Yup.string().required(
			"Flow & Destination Types is required"
		),

		meterIdSerialNumber: Yup.string()
			.matches(
				usefulRegex.METER_ID,
				"Meter Id Serial Number must be between 3 and 10 characters"
			)
			.required("Meter Id Serial Number is required"),

		readingType: Yup.string()
			.matches(
				usefulRegex.READING_TYPE,
				"The reading type is a single character such as A, Z, C, D etc."
			)
			.required("Reading type is required"),

		siteVisitCheckCode: Yup.string()
			.matches(
				usefulRegex.SITE_VISIT_CHECK_CODE,
				"Site visit check code must be a double digit integer between 01 and 89 with some exceptions"
			)
			.required("Site visit check code is required"),

		additionalInformation: Yup.string()
			.nullable()
			.transform((curr, orig) => (orig === "" ? null : curr))
			.max(200),

		// Meter Register One
		meterRegisterIdOne: Yup.string()
			.required("Meter Register Id is required")
			.min(2, "Register Id length can only be 2 characters")
			.max(2, "Register Id length can only be 2 characters"),

		timePatternRegimeOne: Yup.string()
			.oneOf(
				timePatternRegimeCodes,
				"Time pattern regime must be a 5 digit number in the format of the following: 00001, 00135, 13001, 14151, 99911"
			)
			.required("Time pattern regime is required"),

		readingDateTimeOne: Yup.string()
			.required("Reading Date Time is required")
			.test(
				"reading-dto-is-not-valid",
				"Reading Date Time should be a valid datetime",
				dateIsNotValid
			),

		registerReadingOne: Yup.number()
			.required("Register Reading is required")
			.test(
				"rr-1-dp",
				"Register Reading can contain up to 7 numbers and up to one decimal place only",
				(value: number | null) =>
					value ? usefulRegex.REGISTER_READING.test(value.toString()) : true
			),

		readingMethodOne: Yup.string()
			.nullable()
			.transform((curr, orig) => (orig === "" ? null : curr))
			.when("readingMethodOne", {
				is: (value: string) => value && value !== "",
				then: (schema) =>
					schema
						.required("Reading Method is required")
						.test(
							"len",
							"Reading Method must be less then or equal to 1 characters",
							(value: string) => value.length <= 1
						),
			}),

		// Meter Register Two
		meterRegisterIdTwo: Yup.string().when("doesHaveASecondRegister", {
			is: (value: string) => value === "Y",
			then: (schema) =>
				schema
					.required("Meter Register Id is required")
					.min(2, "Register Id length can only be 2 characters")
					.max(2, "Register Id length can only be 2 characters"),
		}),

		timePatternRegimeTwo: Yup.string().when("doesHaveASecondRegister", {
			is: (value: string) => value === "Y",
			then: () =>
				Yup.string()
					.oneOf(
						timePatternRegimeCodes,
						"Time pattern regime must be a 5 digit number in the format of the following: 00001, 00135, 13001, 14151, 99911"
					)
					.required("Time pattern regime is required"),
		}),

		readingDateTimeTwo: Yup.string().when("doesHaveASecondRegister", {
			is: (value: string) => value === "Y",
			then: (schema) =>
				schema
					.required("Reading Date Time is required")
					.test(
						"reading-dtt-is-not-valid",
						"Reading Date Time should be a valid datetime",
						dateIsNotValid
					),
		}),

		registerReadingTwo: Yup.number().when("doesHaveASecondRegister", {
			is: (value: string) => value === "Y",
			then: (schema) =>
				schema
					.required("Register Reading is required")
					.test(
						"rr-2-dp",
						"Register Reading can contain up to 7 numbers and up to one decimal place only",
						(value: number | null) =>
							value ? usefulRegex.REGISTER_READING.test(value.toString()) : true
					),
		}),

		readingMethodTwo: Yup.string().when("doesHaveASecondRegister", {
			is: (value: string) => value === "Y",
			then: () =>
				Yup.string()
					.nullable()
					.transform((curr, orig) => (orig === "" ? null : curr))
					.when("readingMethodTwo", {
						is: (value: string) => value && value !== "",
						then: (schema) =>
							schema
								.required("Reading Method is required")
								.test(
									"len",
									"Reading Method must be less then 1 characters",
									(value: string) => value.length <= 1
								),
					}),
		}),
	},
	[
		["additionalInformation", "additionalInformation"],
		["readingMethodOne", "readingMethodOne"],
		["timePatternRegimeOne", "timePatternRegimeOne"],
		["readingMethodTwo", "readingMethodTwo"],
		["timePatternRegimeTwo", "timePatternRegimeTwo"],
	]
);
