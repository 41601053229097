import React from "react";

import { addD0205FlowRequest } from "../../api/talos";
import {
	D0205FlowRequest,
	IFlowFormValues,
} from "../../models/talos/d0205-flows";
import { AddD0205FlowForm } from "../../components/d0205";
import { AdditionPage } from "../addition-page";

const initialValues: IFlowFormValues = {
	mpan: "",
	energisation_status: "",
	profile_class_id: "",
	ssc: "",
	meter_type: "",
	update_ssc: "N",
};

const mapFormToValues = (values: IFlowFormValues): D0205FlowRequest => {
	return {
		...values,
		update_ssc: values.update_ssc === "Y",
	};
};

export const AddD0205Flow = () => (
	<AdditionPage<D0205FlowRequest, IFlowFormValues>
		additionFormKey="add-d0205-flow"
		summary="Fill in this form to send a new D0205 Flow."
		header="New D0205 Flow"
		additionInstanceName="D0205 Flow"
		mapFormToValues={mapFormToValues}
		addInstanceRequest={addD0205FlowRequest}
		AddInstanceForm={AddD0205FlowForm}
		initialValues={initialValues}
		listPage={{
			link: "/d0205-flows",
			subject: "D0205 flows",
			title: "D0205 Flows",
		}}
	/>
);
