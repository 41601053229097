import * as Yup from "yup";

import { dateIsNotFuture, dateIsNotValid, usefulRegex } from "../../utilities";

export const formValidationSchema = Yup.object().shape(
	{
		mpan: Yup.string()
			.matches(usefulRegex.MPAN, "MPAN must be a 13 digit integer")
			.required("MPAN is required"),
		mpan_efd: Yup.string()
			.required("MPAN EFD is required")
			.test(
				"mpan-efd-is-not-future",
				"MPAN EFD shouldn't be in the future",
				dateIsNotFuture
			)
			.test(
				"mpan-efd-is-not-valid",
				"MPAN EFD should be a valid date",
				dateIsNotValid
			),
		profile_class: Yup.number().required("Profile Class is required"),
		profile_class_efd: Yup.string()
			.required("Profile Class EFD is required")
			.test(
				"pc-efd-is-not-future",
				"Profile Class EFD shouldn't be in the future",
				dateIsNotFuture
			)
			.test(
				"pc-efd-is-not-valid",
				"Profile Class EFD should be a valid date",
				dateIsNotValid
			),
		ssc: Yup.string().required("SSC is required"),
		scon_date: Yup.string()
			.required("SCON Date is required")
			.test(
				"scon-date-is-not-future",
				"SCON Date shouldn't be in the future",
				dateIsNotFuture
			)
			.test(
				"scon-date-is-not-valid",
				"SCON Date should be a valid date",
				dateIsNotValid
			),
		gsp: Yup.string().required("GSP is required"),
		day_tpr: Yup.string()
			.max(5, "Anytime/Day TPR can be a maximum of 5 characters")
			.required("Anytime/Day TPR is required"),
		day_eac: Yup.number()
			.positive("Anytime/Day EAC must be a positive number")
			.required("Anytime/Day EAC is required"),
		day_eac_efd: Yup.string()
			.required("Anytime/Day EAC EFD is required")
			.test(
				"day-eac-efd-is-not-future",
				"Anytime/Day EAC EFD shouldn't be in the future",
				dateIsNotFuture
			)
			.test(
				"day-eac-efd-is-not-valid",
				"Anytime/Day EAC EFD should be a valid date",
				dateIsNotValid
			),
		night_tpr: Yup.string().max(
			5,
			"Night TPR can be a maximum of 5 characters"
		),
		night_eac: Yup.number().positive("Night EAC must be a positive number"),
		night_eac_efd: Yup.string().when("night_eac_efd", (val, schema) => {
			if (val?.length > 1) {
				return schema
					.required()
					.test(
						"night-eac-efd-is-not-future",
						"Night EAC EFD shouldn't be in the future",
						dateIsNotFuture
					)
					.test(
						"night-eac-efd-is-not-valid",
						"Night EAC EFD should be a valid date",
						dateIsNotValid
					);
			} else {
				return schema.optional().nullable();
			}
		}),
	},
	[["night_eac_efd", "night_eac_efd"]]
);
