import React from "react";

import {
	Table,
	TableBody,
	TableCell,
	TableRow,
	Typography,
} from "@mui/material";

import { BookingRequestResponse } from "../../models/talos";
import { formatJavaStyleDateString } from "../../utilities";

interface IProps {
	selected: BookingRequestResponse | undefined;
}

export const BookingRequestsFullVerticalTable: React.FC<IProps> = (
	props: IProps
) => {
	const tableRow = (
		value: string | JSX.Element | JSX.Element[] | undefined,
		label: string
	) => {
		return (
			<TableRow>
				<TableCell variant="head">{label}</TableCell>
				<TableCell>{value}</TableCell>
			</TableRow>
		);
	};

	return props.selected ? (
		<>
			<Typography variant="h6">Meta Data</Typography>
			<Table className="vertical-table">
				<TableBody>
					{tableRow(props.selected?.id, "ID")}
					{tableRow(props.selected?.bookingRequest.jobCode, "Job Code")}
					{tableRow(props.selected?.bookingRequest.agentName, "Agent Name")}
					{tableRow(props.selected?.bookingRequest.agentEmail, "Agent Email")}
					{tableRow(
						formatJavaStyleDateString(
							props.selected?.dateRequestReceived,
							true
						),
						"Date Request Received"
					)}
					{tableRow(
						formatJavaStyleDateString(
							props.selected?.dateRequestGenerated,
							true
						),
						"Date File Generated"
					)}
					{tableRow(
						props.selected?.errors?.map((err, idx) => (
							<React.Fragment key={`err_${idx}`}>
								{`- ${err}`}
								<br />
							</React.Fragment>
						)),
						"Errors"
					)}
				</TableBody>
			</Table>
			<Typography variant="h6">Customer Details</Typography>
			<Table className="vertical-table">
				<TableBody>
					{tableRow(
						props.selected?.bookingRequest.customerName,
						"Customer Name"
					)}
					{tableRow(
						props.selected?.bookingRequest.houseNameNumber,
						"House Name or Number"
					)}
					{tableRow(props.selected?.bookingRequest.address, "Address")}
					{tableRow(props.selected?.bookingRequest.postOutcode, "Post Outcode")}
					{tableRow(props.selected?.bookingRequest.postIncode, "Post Incode")}
				</TableBody>
			</Table>
			<Typography variant="h6">Appointment Details</Typography>
			<Table className="vertical-table">
				<TableBody>
					{props.selected?.bookingRequest.appointmentDate &&
						tableRow(
							formatJavaStyleDateString(
								props.selected?.bookingRequest.appointmentDate
							),
							"Appointment Date"
						)}
					{props.selected?.bookingRequest.appointmentTime &&
						tableRow(
							props.selected?.bookingRequest.appointmentTime,
							"Appointment Time"
						)}
					{props.selected?.bookingRequest.earliestReadDate &&
						tableRow(
							formatJavaStyleDateString(
								props.selected?.bookingRequest.earliestReadDate
							),
							"Earliest Read Date"
						)}
					{props.selected?.bookingRequest.latestReadDate &&
						tableRow(
							formatJavaStyleDateString(
								props.selected?.bookingRequest.latestReadDate
							),
							"Latest Read Date"
						)}
					{tableRow(
						props.selected?.bookingRequest.specialInstructions,
						"Special Instructions"
					)}
					{tableRow(
						props.selected?.bookingRequest.accessInstructions,
						"Access Instructions"
					)}
					{tableRow(
						props.selected?.bookingRequest.customerCareDetails,
						"Customer Care Details"
					)}
					{tableRow(
						props.selected?.bookingRequest.customerPassword,
						"Customer Password"
					)}
				</TableBody>
			</Table>
			{props.selected?.bookingRequest.electricityMeterData && (
				<>
					<Typography variant="h6">Electric Meter</Typography>
					<Table className="vertical-table">
						<TableBody>
							{tableRow(
								props.selected?.bookingRequest.electricityMeterData.mpan,
								"MPAN"
							)}
							{tableRow(
								props.selected?.bookingRequest.electricityMeterData.msn,
								"MSN"
							)}
							{tableRow(
								props.selected?.bookingRequest.electricityMeterData
									.meterLocation,
								"Meter Location"
							)}
							{tableRow(
								props.selected?.bookingRequest.electricityMeterData.registerId,
								"Register ID"
							)}
							{tableRow(
								props.selected?.bookingRequest.electricityMeterData
									.registerName,
								"Register Name"
							)}
							{tableRow(
								props.selected?.bookingRequest.electricityMeterData
									.secondRegisterId,
								"Second Register ID"
							)}
							{tableRow(
								props.selected?.bookingRequest.electricityMeterData
									.secondRegisterName,
								"Second Register name"
							)}
						</TableBody>
					</Table>
				</>
			)}
			{props.selected?.bookingRequest.gasMeterData && (
				<>
					<Typography variant="h6">Gas Meter</Typography>
					<Table className="vertical-table">
						<TableBody>
							{tableRow(
								props.selected?.bookingRequest.gasMeterData.mprn,
								"MPRN"
							)}
							{tableRow(props.selected?.bookingRequest.gasMeterData.msn, "MSN")}
							{tableRow(
								props.selected?.bookingRequest.gasMeterData.meterLocation,
								"Meter Location"
							)}
							{tableRow(
								props.selected?.bookingRequest.gasMeterData.registerId,
								"Register ID"
							)}
							{tableRow(
								props.selected?.bookingRequest.gasMeterData.registerName,
								"Register Name"
							)}
						</TableBody>
					</Table>
				</>
			)}
		</>
	) : null;
};
