import { Dispatch, Fragment } from "react";

import { Field, FieldArray, Form, Formik, getIn } from "formik";
import {
	Box,
	Button,
	Divider,
	FormControl,
	FormControlLabel,
	FormHelperText,
	InputLabel,
	MenuItem,
	Radio,
	RadioGroup,
	Select,
	Stack,
	TextField,
	Typography,
} from "@mui/material";
import { UseMutationResult } from "react-query";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";

import {
	ContractType,
	CustomerPriorityServices,
	ElectiveSettlementHHIndicator,
	ReadingType,
	RegistrationDetailsResponse,
	RegistrationType,
	Supplier,
} from "../../models/themis";
import { formValidationSchema } from "./update-registration-details-form.validation-schema";
import { ThemisDateField } from "../forms/themis-date-field";

interface IProps {
	formData: RegistrationDetailsResponse;
	setShowEditForm: Dispatch<React.SetStateAction<boolean>>;
	updateRegistrationDetailsMutation: UseMutationResult<
		boolean,
		Error,
		RegistrationDetailsResponse
	>;
}

export const UpdateRegistrationDetailsForm: React.FC<IProps> = (props) => {
	const onSubmit = (values: RegistrationDetailsResponse) => {
		props.updateRegistrationDetailsMutation.mutate(values);
	};

	return (
		<Box sx={{ width: "800px" }}>
			<Formik
				onSubmit={onSubmit}
				initialValues={props.formData}
				validationSchema={formValidationSchema}
				validateOnChange
				enableReinitialize
			>
				{(form) => (
					<Form>
						<Box>
							<Typography variant="h1">Update AREGI Details</Typography>
						</Box>
						<Stack gap={2}>
							<Typography variant="h3">
								What contract type does the customer have?
							</Typography>
							<Field
								name="registrationDetail.contractType"
								label="registrationDetail.contractType"
								as={RadioGroup}
								form={form}
							>
								{Object.entries(ContractType).map((type, index) => (
									<FormControlLabel
										key={`contract_type_${index}`}
										value={type[1]}
										control={<Radio />}
										label={type[0]}
									/>
								))}
								<FormHelperText
									error={!!form.errors.registrationDetail?.contractType}
								>
									{form.errors.registrationDetail?.contractType}
								</FormHelperText>
							</Field>
							<Typography variant="h3">
								What Type Of Submission Is This?
							</Typography>
							<Field
								name="registrationDetail.registrationType"
								label="registrationDetail.registrationType"
								as={RadioGroup}
							>
								<FormControlLabel
									value={RegistrationType.NEW.toString()}
									control={<Radio />}
									label="New"
								/>
								<FormControlLabel
									value={RegistrationType.GAIN.toString()}
									control={<Radio />}
									label="Gain"
								/>
								<FormControlLabel
									value={RegistrationType.COS.toString()}
									control={<Radio />}
									label="COS"
								/>
							</Field>
							<Typography variant="h1">Customer Details</Typography>
							<Field
								name="customerDetail.name"
								label="Customer Name"
								as={TextField}
								form={form}
								error={!!form.errors.customerDetail?.name}
								helperText={form.errors.customerDetail?.name}
							/>
							<Field
								name="customerDetail.address"
								label="First Line of Address"
								as={TextField}
								form={form}
								error={!!form.errors.customerDetail?.address}
								helperText={form.errors.customerDetail?.address}
							/>
							<Field
								name="customerDetail.postcode"
								label="Customer Postcode"
								as={TextField}
								form={form}
								error={!!form.errors.customerDetail?.postcode}
								helperText={form.errors.customerDetail?.postcode}
							/>
							<FieldArray
								name="customerDetail.priorityServices"
								render={(arrayHelpers) => (
									<Fragment>
										{form.values.customerDetail.priorityServices?.map(
											(_psr, index) => {
												return (
													<Fragment key={`psr_${index}`}>
														<FormControl>
															<InputLabel
																id={`psr_${index}_label`}
																error={
																	!!form.errors.customerDetail
																		?.priorityServices?.[index]
																}
															>
																{`PSR Code ${index + 1}`}
															</InputLabel>
															<Field
																name={`customerDetail.priorityServices[${index}].category`}
																label={`PSR Code ${index + 1}`}
																labelId={`psr_${index}_label`}
																as={Select}
																form={form}
																error={
																	!!form.errors.customerDetail
																		?.priorityServices?.[index]
																}
															>
																{Object.entries(CustomerPriorityServices).map(
																	(psrEnum, index) => {
																		return (
																			<MenuItem
																				key={`psr_${index}`}
																				value={psrEnum[1]}
																			>
																				{`${psrEnum[1]} - ${psrEnum[0]}`}
																			</MenuItem>
																		);
																	}
																)}
															</Field>
															<FormHelperText
																error={
																	!!form.errors.customerDetail
																		?.priorityServices?.[index]
																}
															>
																{getIn(
																	form.errors.customerDetail
																		?.priorityServices?.[index],
																	"category"
																)}
															</FormHelperText>
														</FormControl>
														<FormControl>
															<ThemisDateField
																value={
																	form.values.customerDetail.priorityServices[
																		index
																	].expiryDate
																}
																name={`customerDetail.priorityServices[${index}].expiryDate`}
																label={`PSR Expiry Date ${index + 1}`}
																error={
																	!!form.errors.customerDetail
																		?.priorityServices?.[index]
																}
																form={form}
															/>
															<FormHelperText
																error={
																	!!form.errors.customerDetail
																		?.priorityServices?.[index]
																}
															>
																{getIn(
																	form.errors.customerDetail
																		?.priorityServices?.[index],
																	"expiryDate"
																)}
															</FormHelperText>
														</FormControl>
														<Button
															type="button"
															variant="outlined"
															color="warning"
															sx={{
																width: "max-content",
																justifyContent: "flex-start",
															}}
															startIcon={<DeleteIcon />}
															onClick={() => arrayHelpers.remove(index)}
														>
															Remove PSR
														</Button>
														<Divider
															variant="middle"
															sx={{ height: "0.5rem" }}
														/>
													</Fragment>
												);
											}
										)}
										<Button
											type="button"
											variant="outlined"
											startIcon={<AddIcon />}
											onClick={() =>
												arrayHelpers.push({
													category: "",
													expiryDate: "",
												})
											}
										>
											Add PSR
										</Button>
									</Fragment>
								)}
							/>
							<Typography variant="h1">Site Details</Typography>
							<Typography variant="h3">Is this a domestic property?</Typography>
							<Field
								name="registrationDetail.isDomesticPremises"
								label="Is this a domestic property?"
								as={RadioGroup}
								form={form}
							>
								<FormControlLabel
									value={true}
									control={<Radio />}
									label="Yes"
								/>
								<FormControlLabel
									value={false}
									control={<Radio />}
									label="No"
								/>
								<FormHelperText
									error={!!form.errors.registrationDetail?.isDomesticPremises}
								>
									{form.errors.registrationDetail?.isDomesticPremises}
								</FormHelperText>
							</Field>

							<Typography variant="h3">Supplier</Typography>
							<Field
								name="registrationDetail.importSupplier"
								label="registrationDetail.importSupplier"
								as={RadioGroup}
							>
								{Object.entries(Supplier).map((val, index) => {
									return (
										<FormControlLabel
											key={index}
											value={val[1]}
											control={<Radio />}
											label={val[0]}
										/>
									);
								})}
							</Field>
							<Field
								name="registrationDetail.importMpan"
								label="Import MPAN"
								as={TextField}
								form={form}
							/>
							<Field
								name="registrationDetail.meterSerialNumber"
								label="Electricity MSN"
								as={TextField}
								form={form}
								error={!!form.errors.registrationDetail?.meterSerialNumber}
								helperText={form.errors.registrationDetail?.meterSerialNumber}
							/>
							<ThemisDateField
								value={form.values.registrationDetail.ecoesRaised}
								name="registrationDetail.ecoesRaised"
								label="Date Raised to Ecoes"
								helperText={form.errors.registrationDetail?.ecoesRaised}
								error={!!form.errors.registrationDetail?.ecoesRaised}
								form={form}
							/>
							<ThemisDateField
								value={form.values.registrationDetail.contractStart}
								name="registrationDetail.contractStart"
								label="Contract Start Date"
								helperText={form.errors.registrationDetail?.contractStart}
								error={!!form.errors.registrationDetail?.contractStart}
								form={form}
							/>
							<Field
								name="registrationDetail.exportMpan"
								label="Export MPAN"
								as={TextField}
								form={form}
								error={!!form.errors.registrationDetail?.exportMpan}
								helperText={form.errors.registrationDetail?.exportMpan}
							/>
							<ThemisDateField
								value={form.values.registrationDetail.mpanCreatedDate}
								name="registrationDetail.mpanCreatedDate"
								label="MPAN Creation Date"
								helperText={form.errors.registrationDetail?.mpanCreatedDate}
								error={!!form.errors.registrationDetail?.mpanCreatedDate}
								form={form}
							/>
							<Typography variant="h3">
								What Is The Settlements Type?
							</Typography>
							<Field
								name="registrationDetail.readingType"
								label="registrationDetail.readingType"
								as={RadioGroup}
							>
								<FormControlLabel
									value={ReadingType.HH.toString()}
									control={<Radio />}
									label="Half-hourly"
								/>
								<FormControlLabel
									value={ReadingType.NHH.toString()}
									control={<Radio />}
									label="Non half-hourly"
								/>
							</Field>
							<Field
								name="registrationDetail.meterTimeSwitchCode"
								label="Meter timeswitch code"
								as={TextField}
								form={form}
								error={!!form.errors.registrationDetail?.meterTimeSwitchCode}
								helperText={form.errors.registrationDetail?.meterTimeSwitchCode}
							/>
							<Field
								name="registrationDetail.standardSettlementConfigId"
								label="Standard Settlement Config ID"
								as={TextField}
								form={form}
								error={
									!!form.errors.registrationDetail?.standardSettlementConfigId
								}
								helperText={
									form.errors.registrationDetail?.standardSettlementConfigId
								}
							/>
							<FormControl>
								<InputLabel
									id={"elective_settlement_hh_indicator_label"}
									error={
										!!form.errors.registrationDetail
											?.electiveSettlementHHIndicator
									}
								>
									{"Elective Settlement HH Indicator"}
								</InputLabel>
								<Field
									name="registrationDetail.electiveSettlementHHIndicator"
									label="Elective Settlement HH Indicator"
									labelId={"elective_settlement_hh_indicator_label"}
									as={Select}
									form={form}
									error={
										!!form.errors.registrationDetail
											?.electiveSettlementHHIndicator
									}
								>
									{Object.entries(ElectiveSettlementHHIndicator).map(
										(val, index) => {
											return (
												<MenuItem key={`indicator_${index}`} value={val[1]}>
													{`${val[0]}`}
												</MenuItem>
											);
										}
									)}
								</Field>
								<FormHelperText
									error={
										!!form.errors.registrationDetail
											?.electiveSettlementHHIndicator
									}
								>
									{
										form.errors.registrationDetail
											?.electiveSettlementHHIndicator
									}
								</FormHelperText>
							</FormControl>
							<Field
								name="registrationDetail.retrievalMethod"
								label="Retrieval Method"
								as={TextField}
								form={form}
								error={!!form.errors.registrationDetail?.retrievalMethod}
								helperText={form.errors.registrationDetail?.retrievalMethod}
							/>
							<Field
								name="registrationDetail.meterOperatorId"
								label="MOP ID"
								as={TextField}
								form={form}
								error={!!form.errors.registrationDetail?.meterOperatorId}
								helperText={form.errors.registrationDetail?.meterOperatorId}
							/>
							<Field
								name="registrationDetail.dataAggregatorId"
								label="Data Aggregator ID"
								as={TextField}
								form={form}
								error={!!form.errors.registrationDetail?.dataAggregatorId}
								helperText={form.errors.registrationDetail?.dataAggregatorId}
							/>
							<Field
								name="registrationDetail.dataCollectorId"
								label="Data Collector ID"
								as={TextField}
								form={form}
								error={!!form.errors.registrationDetail?.dataCollectorId}
								helperText={form.errors.registrationDetail?.dataCollectorId}
							/>
							<ThemisDateField
								value={form.values.registrationDetail.d0217Received}
								name="registrationDetail.d0217Received"
								label="D0217 Date"
								helperText={form.errors.registrationDetail?.d0217Received}
								error={!!form.errors.registrationDetail?.d0217Received}
								form={form}
							/>
							<ThemisDateField
								value={form.values.registrationDetail.mtdsReceived}
								name="registrationDetail.mtdsReceived"
								label="MTDS Date"
								helperText={form.errors.registrationDetail?.mtdsReceived}
								error={!!form.errors.registrationDetail?.mtdsReceived}
								form={form}
							/>
							<ThemisDateField
								value={form.values.registrationDetail.d0010iReceived}
								name="registrationDetail.d0010iReceived"
								label="D0010i Date"
								helperText={form.errors.registrationDetail?.d0010iReceived}
								error={!!form.errors.registrationDetail?.d0010iReceived}
								form={form}
							/>
							<ThemisDateField
								value={form.values.registrationDetail.d0019Received}
								name="registrationDetail.d0019Received"
								label="D0019 Date"
								helperText={form.errors.registrationDetail?.d0019Received}
								error={!!form.errors.registrationDetail?.d0019Received}
								form={form}
							/>
							<Field
								name="registrationDetail.additionalNotes"
								label="Additional Notes"
								as={TextField}
								multiline
								minRows={3}
								form={form}
								error={!!form.errors.registrationDetail?.additionalNotes}
								helperText={form.errors.registrationDetail?.additionalNotes}
							/>
						</Stack>
						<Stack direction={"row"} gap={2} marginTop={2}>
							<Button
								variant="contained"
								onClick={() => props.setShowEditForm(false)}
								disabled={props.updateRegistrationDetailsMutation.isLoading}
							>
								Cancel
							</Button>
							<Button
								variant="contained"
								type="submit"
								disabled={
									!form.isValid ||
									props.updateRegistrationDetailsMutation.isLoading
								}
							>
								Update
							</Button>
						</Stack>
					</Form>
				)}
			</Formik>
		</Box>
	);
};
