import axios from "axios";

import { IAuthContext } from "../../auth";
import { talosConfig } from "../../utilities";

export const baseRequest = async (
	authContext: IAuthContext,
	method: string,
	url: string,
	data: any = undefined,
	params: any = undefined,
	contentType: string = "application/json",
	baseUrl:
		| typeof talosConfig.API.baseURLOperations
		| typeof talosConfig.API.baseURL = talosConfig.API.baseURL
) => {
	await authContext.provider!.updateToken(talosConfig.keycloak.minValidity);
	const httpBroker = axios.create({
		baseURL: baseUrl,
		headers: {
			Accept: "application/json",
			"Content-Type": contentType,
		},
	});

	return await httpBroker.request({
		method: method,
		url: url,
		data: data,
		headers: {
			Authorization: `Bearer ${authContext.provider!.token}`,
			Accept: "application/json",
			"Content-Type": contentType,
		},
		params: params,
	});
};

export const baseRequestOperations = async (
	authContext: IAuthContext,
	method: string,
	url: string,
	data: any = undefined,
	params: any = undefined,
	contentType: string = "application/json"
) =>
	await baseRequest(
		authContext,
		method,
		url,
		data,
		params,
		contentType,
		talosConfig.API.baseURLOperations
	);
