import { addDays, format, subBusinessDays } from "date-fns";

import {
	EAC,
	IEACFormValues,
	IEACResult,
	IReadsCalculatorOutput,
	IReadSplitterFormValues,
	IReadSplitterResult,
	IRfCalculationResult,
	IRfCalculationValues,
	MeterReading,
} from "../models/talos";

/**
 * Calculates the difference in days between two dates.
 * This toolwas originally built using formulas in an Google Sheet.
 * As such, we need to replicate the same behavior for date calculations.
 * This means that the calculation is not inclusive. For example
 * 1st to the 5th is +4 days.
 * @param range the start and end dates
 * @returns the number of days between two dates
 */
export const getDaysBetweenDates = (start: Date, end: Date) => {
	const msInDay = 24 * 60 * 60 * 1000;
	const msDiff = end.getTime() - start.getTime();

	return msDiff / msInDay;
};

/**
 * This returns a formatted block of text that is commonly used by the agents.
 * @param firstReading
 * @param secondReading
 * @param estimation
 * @returns
 */
export const getP1 = (
	firstReading: MeterReading,
	secondReading: MeterReading,
	estimation: MeterReading
) => {
	return `Proposing ${estimation.register_one} backed by ${format(
		firstReading.date,
		"dd/MM/yyyy"
	)} ${firstReading.register_one} and ${format(
		secondReading.date,
		"dd/MM/yyyy"
	)} ${secondReading.register_one}`;
};

/**
 * This returns a formatted block of text that is commonly used by the agents.
 * @param firstReading
 * @param secondReading
 * @param estimation
 * @returns
 */
export const getP2 = (
	firstReading: MeterReading,
	secondReading: MeterReading,
	estimation: MeterReading
) => {
	return `Proposing ${estimation.register_one}/${
		estimation.register_two
	} backed by ${format(firstReading.date, "dd/MM/yyyy")} ${
		firstReading.register_one
	}/${firstReading.register_two ? firstReading.register_two : 0} and ${format(
		secondReading.date,
		"dd/MM/yyyy"
	)} ${secondReading.register_one}/${
		secondReading.register_two ? secondReading.register_two : 0
	}`;
};

/**
 * From Spreadhseet:
 * r2 + (((r2 - r1)/(d2 - d2)) * (estimation_date - d2))
 */
const estimateValue = (
	r1: number,
	r2: number,
	d1: Date,
	d2: Date,
	estimationDate: Date
) => {
	return (
		r2 +
		((r2 - r1) / getDaysBetweenDates(d2, d1)) *
			getDaysBetweenDates(estimationDate, d2)
	);
};

const estimateRead = (
	read_one: MeterReading,
	read_two: MeterReading,
	estimationDate: Date
): MeterReading => {
	const reg1Estimate = Math.round(
		estimateValue(
			read_one.register_one,
			read_two.register_one,
			read_one.date,
			read_two.date,
			estimationDate
		)
	);

	let reg2Estimate = undefined;
	if (read_one.register_two && read_two.register_two) {
		reg2Estimate = Math.round(
			estimateValue(
				read_one.register_two,
				read_two.register_two,
				read_one.date,
				read_two.date,
				estimationDate
			)
		);
	}

	return {
		date: estimationDate,
		register_one: reg1Estimate,
		register_two: reg2Estimate,
	};
};

const calculateEAC = (r1: number, r2: number, d1: Date, d2: Date) => {
	return ((r2 - r1) / getDaysBetweenDates(d2, d1)) * 365;
};

export const getNewEAC = (
	read_one: MeterReading,
	read_two: MeterReading
): EAC => {
	const reg_one =
		Math.round(
			calculateEAC(
				read_two.register_one,
				read_one.register_one,
				read_one.date,
				read_two.date
			) * 10
		) / 10;
	let reg_two = undefined;
	if (read_one.register_two && read_two.register_two) {
		reg_two =
			Math.round(
				calculateEAC(
					read_two.register_two,
					read_one.register_two,
					read_one.date,
					read_two.date
				) * 10
			) / 10;
	}

	return {
		register_one: reg_one,
		register_two: reg_two,
	};
};

export const getReadsCalculatorOutput = (
	read_one: MeterReading,
	read_two: MeterReading,
	estimationDate: Date
): IReadsCalculatorOutput => {
	const estimate = estimateRead(read_one, read_two, estimationDate);
	return {
		P1: getP1(read_one, read_two, estimate),
		P2: getP2(read_one, read_two, estimate),
		estimate: estimate,
		eac: getNewEAC(read_one, read_two),
	};
};

export const getEacOrAABasedCalculation = (
	values: IEACFormValues
): IEACResult => {
	return {
		dayRead: Math.round(
			(Number(values.eacaaDayRead) / 365) *
				getDaysBetweenDates(
					values.lastValidatedDate,
					values.estimationBasedOn
				) +
				Number(values.lastValidatedDayRead)
		),
		nightRead: Math.round(
			(Number(values.eacaaNightRead) / 365) *
				getDaysBetweenDates(
					values.lastValidatedDate,
					values.estimationBasedOn
				) +
				Number(values.lastValidatedNightRead)
		),
	};
};

/**
 * Splits the readings. I have no idea why we do this.
 * @param values
 * @returns
 */
export const getReadSplitResult = (
	values: IReadSplitterFormValues
): IReadSplitterResult => {
	const day = Number(values.dayEac);
	const night = Number(values.nightEac);
	const total = Number(values.totalReading);

	const dayReading = Math.round((day / (night + day)) * total);
	const nightReading = Math.round((night / (night + day)) * total);

	return {
		dayReading,
		nightReading,
		totalCheck: dayReading + nightReading,
	};
};

/**
 * Returns the RF calculation.
 */
export const getRfCalculation = (
	input: IRfCalculationValues
): IRfCalculationResult => {
	const d = input.date;

	const rfDate = subBusinessDays(d, 292);
	const rfFixDate = addDays(rfDate, 20);

	return {
		rfDate,
		rfFixDate,
	};
};
