import React, { useContext } from "react";

import { format } from "date-fns";
import { AxiosError } from "axios";

import { addD0052FlowRequest } from "../../api/talos";
import { AlertContext, dateFormat, handleError } from "../../utilities";
import {
	D0052FlowRequest,
	ID0052FlowFormValues,
} from "../../models/talos/d0052-flows";
import { AddD0052FlowForm } from "../../components/d0052";
import { TalosTimerSnackbar } from "../../components";
import { AdditionPage } from "../addition-page";

const initialValues: ID0052FlowFormValues = {
	mpan: "",
	mpan_efd: "",
	profile_class: "",
	profile_class_efd: "",
	ssc: "",
	scon_date: "",
	gsp: "",
	day_tpr: "",
	day_eac: "",
	day_eac_efd: "",
	night_tpr: "",
	night_eac: "",
	night_eac_efd: "",
};
interface ErrorResponse {
	errors?: string[] | null | undefined;
	count?: number | null | undefined;
}

interface ErrorDetail {
	detail?: string | null | undefined;
}

const mapFormToValues = (values: ID0052FlowFormValues): D0052FlowRequest => {
	const dates = new Set([
		"mpan_efd",
		"profile_class_efd",
		"measurement_class_efd",
		"scon_date",
		"gsp_efd",
		"day_eac_efd",
		"night_eac_efd",
	]);
	const optionalFields = ["night_tpr", "night_eac", "night_eac_efd"];

	return Object.entries(values).reduce((acc, [key, value]) => {
		if (value !== null && (!optionalFields.includes(key) || value)) {
			acc[key as keyof D0052FlowRequest] = dates.has(key)
				? format(new Date(value), dateFormat)
				: value;
		}
		return acc;
	}, {} as D0052FlowRequest);
};

export const AddD0052Flow = () => {
	const { setTalosAlert } = useContext(AlertContext);
	const [openAlert, setOpenAlert] = React.useState(false);
	const [submitDisabled, setSubmitDisabled] = React.useState(false);

	return (
		<>
			<AdditionPage<D0052FlowRequest, ID0052FlowFormValues>
				additionFormKey="add-d0052-flow"
				additionInstanceName="D0052 Flow"
				header="New D0052 Flow"
				summary="Fill in this form to send a new D0052 Flow."
				mapFormToValues={mapFormToValues}
				addInstanceRequest={addD0052FlowRequest}
				AddInstanceForm={AddD0052FlowForm}
				extraFormProps={{ submitDisabled }}
				initialValues={initialValues}
				listPage={{
					link: "/d0052-flows",
					subject: "D0052 flows",
					title: "D0052 Flows",
				}}
				onError={(e: AxiosError) => {
					if (e.response?.status === 408) {
						setOpenAlert(true);
						setSubmitDisabled(true);
					} else if (e.response?.status === 422) {
						const errorResponse: ErrorResponse = e.response?.data ?? {};
						const errorCount = errorResponse.count ?? 0;
						const errorDetails = errorResponse.errors ?? [];

						let alerts = errorDetails.join(", ");

						if (alerts.length === 0) {
							alerts =
								"There was an issue with the payload, please check the provided values";
						} else if (errorCount === 1) {
							alerts = `There was 1 error: ${alerts}`;
						} else {
							alerts = `There were ${errorCount} errors: ${alerts}`;
						}

						setTalosAlert({
							message: alerts,
							severity: "error",
						});
						scrollTo(0, 0);
					} else if (e.response?.status === 400) {
						const error: ErrorDetail = e.response?.data ?? {};

						setTalosAlert({
							severity: "error",
							message:
								error.detail ??
								"Something went wrong submitting D0052 Flow form. Please contact IOPS Support",
						});
					} else {
						const errorId = handleError(e);

						setTalosAlert({
							message: `Something went wrong submitting D0052 Flow form. Please contact IOPS Support, Ticket ID: ${errorId}`,
							severity: "error",
						});

						// Scroll to the top of the page, so the user can see error message
						scrollTo(0, 0);
					}
				}}
			/>
			{openAlert ? (
				<TalosTimerSnackbar
					openAlert={openAlert}
					setOpenAlert={setOpenAlert}
					setSubmitDisabled={setSubmitDisabled}
					interval={120}
				></TalosTimerSnackbar>
			) : null}
		</>
	);
};
