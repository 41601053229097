import { updateEnergisationStatus } from "../../../api/metering";
import {
	EnergisationStatusFlowAndDestinationTypes,
	IUpdateEnergisationStatus,
} from "../../../models/metering/update-energisation-status";
import {
	EnergisationStatus,
	MeterLocation,
} from "../../../models/metering/common";
import {
	generateFormPage,
	selectOptionsFromEnum,
} from "../../../form-generator";

export const UpdateEnergisationStatus = generateFormPage<
	IUpdateEnergisationStatus,
	IUpdateEnergisationStatus
>(
	{
		newRequestText: "",
		successMessage: "Successfully requested change of energisation status.",
		formKey: "update-engergisation-status",
		header: "Update Energisation Status",
		fields: [
			{
				componentType: "mpan",
				fieldName: "mpan",
				label: "MPAN",
				required: true,
			},
			{
				componentType: "select",
				fieldName: "flowAndDestinationTypes",
				label: "Flow And Destination",
				options: selectOptionsFromEnum(
					EnergisationStatusFlowAndDestinationTypes,
					"key - value",
					"key"
				),
				required: true,
			},
			{
				componentType: "select",
				fieldName: "energisationStatus",
				label: "Energisation Status",
				options: selectOptionsFromEnum(
					EnergisationStatus,
					"value - key",
					"value"
				),
				required: true,
			},
			{
				componentType: "select",
				fieldName: "requestedEnergisationStatus",
				label: "Requested Energisation Status",
				options: selectOptionsFromEnum(
					EnergisationStatus,
					"value - key",
					"value"
				),
				required: true,
				customValidation: (schema) => {
					return schema.test(
						"not-equal",
						"Requested energisation status must be different from energisation status.",
						function (value) {
							const { energisationStatus } = this.parent;
							return value !== energisationStatus;
						}
					);
				},
			},
			{
				componentType: "date",
				fieldName: "appointmentDate",
				label: "Appointment Date",
			},
			{
				componentType: "string",
				fieldName: "contactName",
				label: "Contact Name",
				minRows: 2,
				maxRows: 2,
				maxLength: 50,
				required: true,
			},
			{
				componentType: "string",
				fieldName: "meterId",
				label: "Meter id",
				maxLength: 10,
				required: true,
			},
			{
				componentType: "select",
				fieldName: "meterLocation",
				label: "Meter Location",
				options: selectOptionsFromEnum(MeterLocation, "value", "value"),
			},
		],
	},
	updateEnergisationStatus
);
