import React, { ChangeEvent } from "react";

import { Box, TextField } from "@mui/material";
import { Field, FormikProps, getIn } from "formik";

interface DropDownProps<T> {
	fieldName: string;
	label: string;
	form: FormikProps<T>;
	menuItems: JSX.Element[];
	disabled?: boolean;
	postOnChange?: (event: React.ChangeEvent<any>, value: string) => void;
}

export const TalosDropDown = <T extends object>(
	props: DropDownProps<T>
): JSX.Element => {
	const fieldValue = getIn(props.form.values, props.fieldName);
	const ID = `select-${props.fieldName}`;
	const error = getIn(props.form.errors, props.fieldName);

	return (
		<Box className="form-row">
			<Field
				name={props.fieldName}
				as={TextField}
				select
				className="form-field"
				data-cy={ID}
				label={props.label}
				id={props.fieldName}
				value={fieldValue}
				error={!!error}
				helperText={error}
				onChange={(e: ChangeEvent<any>) => {
					props.form.setFieldValue(props.fieldName, e.target.value);

					if (props.postOnChange) {
						props.postOnChange(e, e.target.value);
					}
				}}
				disabled={props.disabled}
			>
				{props.menuItems}
			</Field>
		</Box>
	);
};
