import { TypographyOptions } from "@mui/material/styles/createTypography";

export const talosTypographyStyleDefaults: TypographyOptions = {
	h1: {
		fontFamily: '"Montserrat", "Helvetica", "Arial", sans-serif',
		fontSize: 30,
		fontWeight: 700,
		lineHeight: "36px",
		textTransform: "capitalize",
	},
	h2: {
		fontFamily: '"Montserrat", "Open Sans", "Helvetica", "Arial", sans-serif',
		fontSize: 22,
		fontWeight: 700,
		lineHeight: "28px",
		textTransform: "capitalize",
	},
	h3: {
		fontSize: 18,
		fontWeight: 600,
		lineHeight: "24px",
		textTransform: "capitalize",
	},
	body1: {
		fontSize: 16,
		fontWeight: 400,
		lineHeight: "24px",
	},
	body2: {
		fontSize: 12,
		fontWeight: 400,
		lineHeight: "16px",
		letterSpacing: "0.03em",
	},
	fontSize: 16,
	htmlFontSize: 16,
	fontFamily: '"Open Sans", "Helvetica", "Arial", sans-serif',
};
