import * as Yup from "yup";

import { dateIsNotValidNullable } from "../../../utilities";

export const updateIHDStatusFormValidationSchema = Yup.object().shape({
	mpan: Yup.number()
		.required("MPAN is required.")
		.min(1000000000000, "MPAN must be exactly 13 digits.")
		.max(9999999999999, "MPAN must be exactly 13 digits."),
	ihdStatus: Yup.string().required("IHD Status is required."),
	ihdStatusEffectiveFromDate: Yup.string()
		.notRequired()
		.test(
			"invalid-date-action",
			"IHD Status Effective From Date should be a valid date",
			dateIsNotValidNullable
		),
});
