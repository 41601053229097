import { requestSupplyDeactivation } from "../../../api/metering";
import { generateFormPage } from "../../../form-generator";
import { ISupplyDeactivation } from "../../../models/metering";
import { COMMON_FIELDS } from "../../../form-generator/form-component.generator";

export const RequestSupplyDeactivation = generateFormPage<
	ISupplyDeactivation,
	ISupplyDeactivation
>(
	{
		header: "Request Supply Deactivation",
		newRequestText: "",
		fields: [COMMON_FIELDS.MPAN],
		formKey: "request-supply-deactivation",
		successMessage: "Successfully requested supply deactivation.",
		errorMessage: (errorId) =>
			`Something went wrong while requesting supply deactivation. Please contact IOPS Support, Ticket ID: ${errorId}`,
	},
	requestSupplyDeactivation
);
