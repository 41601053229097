export interface IBreadCrumb {
	path: string;
	displayText: string;
}

export const getBreadCrumbsFromPath = (path: string): IBreadCrumb[] => {
	const arr = path.split("/");

	return arr.reduce((acc, curr, idx) => {
		const friendly = curr.replaceAll("-", " ");
		const newPath = `${acc[idx - 2]?.path ?? ""}/${curr}`;

		return friendly ? [...acc, { displayText: friendly, path: newPath }] : acc;
	}, [] as IBreadCrumb[]);
};
