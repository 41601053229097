import { AxiosResponse } from "axios";

import { IMOP04v10, initialMOP04FormValues } from "../../../models/outbound";
import { submitMOP04 } from "../../../api/outbound";
import { mapMop04FormValuesToRequest } from "../../../utilities";
import { AdditionPage } from "../../addition-page";
import { AddMop04Form } from "../../../components/outbound/mop04/add-mop04-form";

export const SubmitMOP04 = () => (
	<AdditionPage<IMOP04v10, IMOP04v10, AxiosResponse>
		header="Update Meter Job Outcome"
		additionInstanceName="Meter Job Outcome Update"
		underlyingFlowName="MOP04"
		addInstanceRequest={submitMOP04}
		AddInstanceForm={AddMop04Form}
		mapFormToValues={mapMop04FormValuesToRequest}
		additionFormKey={"MOP04"}
		initialValues={initialMOP04FormValues}
		successMessage="Successfully submitted Update Meter Job Outcome request (MOP04)"
	/>
);
